import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Chip,
	CircularProgress,
} from "@mui/material";
import Pagination from "../adminComponents/Pagination/Pagination";
import TextWithCharacterLimit from "../adminComponents/Truncate/TextWithCharacterLimit";

// this code for limited charactres
export default function ReportList() {
	const [accountReports, setAccountReport] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(8);
	const [totalPages, setTotalPages] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const nav = useNavigate();
	const [cookies] = useCookies(["adminToken"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	useEffect(() => {
		getData();
	}, [currentPage]);

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/account/reportList?page=${currentPage}&size=${itemsPerPage}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${adminToken}`,
					},
				}
			);
			if (response.ok) {
				const apiData = await response.json();
				setAccountReport(apiData.accountReports);
				setTotalPages(apiData.totalPages);
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const pageNumbers = [];
	for (let i = 1; i <= totalPages; i++) {
		pageNumbers.push(i);
	}

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const handleDetails = (email) => {
		nav(`/admin/ACCOUNT_DEPARTMENT/report/list/${email}`);
	};

	const handleEdit = (id) => {
		nav(`/admin/ACCOUNT_DEPARTMENT/report/edit/${id}`);
	};
	const handleReportDetails = (id) => {
		nav(`/admin/ACCOUNT_DEPARTMENT/report/details/${id}`);
	};

	return (
		<Box>
			<Card variant="outlined">
				<CardContent>
					<Typography variant="h3">Report Lists</Typography>
					<Box
						sx={{
							overflow: {
								xs: "auto",
								// sm: "unset",
							},
						}}
					>
						<Table
							aria-label="simple table"
							sx={{
								mt: 3,
							}}
						>
							<TableHead>
								<TableRow>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Full Name
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Email
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Course Title
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Course Fee
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Paid Amount
										</Typography>
									</TableCell>
									<TableCell align="left">
										<Typography color="textSecondary" variant="h5">
											Details
										</Typography>
									</TableCell>
									<TableCell align="left">
										<Typography color="textSecondary" variant="h5" align="left">
											Edit
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{accountReports.map((account) => (
									<TableRow key={account.id}>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{account.fullName}
											</Typography>
										</TableCell>
										<TableCell>
											<Chip
												sx={{
													pl: "0",
													textDecoration: "underline",
													// color: "#fff",
													backgroundColor: "#fff",
													fontSize: "1rem",
												}}
												size="small"
												label={account.email}
												onClick={() => handleDetails(account.email)}
											/>
										</TableCell>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{account.courseTitle}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{account.courseFee}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{account.depositedAmount}
											</Typography>
										</TableCell>
										<TableCell align="center">
											<Chip
												sx={{
													pl: "4px",
													pr: "4px",
													backgroundColor: "success.main",
													color: "#fff",
												}}
												size="small"
												label="Details"
												onClick={() => handleReportDetails(account.id)}
											/>
										</TableCell>
										<TableCell align="center">
											<Chip
												sx={{
													pl: "4px",
													pr: "4px",
													backgroundColor: "primary.main",
													color: "#fff",
												}}
												size="small"
												label="Edit"
												onClick={() => handleEdit(account.id)}
											/>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						{loading ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								<CircularProgress />
							</Box>
						) : null}
						{error ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								{error}
							</Box>
						) : null}
						<Pagination
							currentPage={currentPage}
							totalPages={totalPages}
							onPageChange={handlePageChange}
						/>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
}
