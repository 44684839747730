import React, { useState, useEffect, useRef } from "react";
import {
	Select,
	MenuItem,
	CircularProgress,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Checkbox,
	ListItemText,
	FormControl,
	InputLabel,
	OutlinedInput,
	Box,
	Typography,
} from "@mui/material";
import BASE_URL from "../../../subcomponents/Config";
import { useCookies } from "react-cookie";
import Pagination from "../Pagination/Pagination";

const QuestionSelect = ({
	selectedBankId,
	selectedQuestions,
	onSelectBank,
}) => {
	const [questions, setQuestions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState(selectedQuestions || []);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const itemsPerPage = 3;

	const [cookies] = useCookies(["adminToken"]);
	const [adminToken] = useState(cookies.adminToken || "");

	// Fetch question bank function
	useEffect(() => {
		fetchQuestionBank();
	}, [selectedBankId, currentPage]);

	const fetchQuestionBank = async () => {
		setLoading(true);
		if (selectedBankId) {
			try {
				const response = await fetch(
					`${BASE_URL}/api/v1/questionBank/${selectedBankId}/questions?page=${currentPage}&size=${itemsPerPage}`,
					{
						method: "GET",
						headers: {
							Authorization: `Bearer ${adminToken}`,
						},
					}
				);

				if (response.ok) {
					const apiData = await response.json();
					setQuestions(apiData.questions);
					setTotalPages(apiData.totalPages);
				} else {
					const error = await response.json();
					console.error("Error fetching questions:", error.message);
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading(false);
			}
		}
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		onSelectBank(selected);
	};

	const handleToggle = (questionId) => {
		setSelected((prevSelected) =>
			prevSelected.includes(questionId)
				? prevSelected.filter((id) => id !== questionId)
				: [...prevSelected, questionId]
		);
		onSelectBank(selected);
	};
	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const pageNumbers = [];
	for (let i = 1; i <= totalPages; i++) {
		pageNumbers.push(i);
	}

	return (
		<div>
			<FormControl variant="outlined" fullWidth>
				{selected.length === 0 ? (
					<Button onClick={handleOpen} color="primary" variant="contained">
						Select Questions
					</Button>
				) : (
					<Box>
						<Box>
							<Select
								labelId="question-select-label"
								value={selected}
								multiple
								displayEmpty
								name="questionIds"
								input={<OutlinedInput label="Questions" />}
								renderValue={(selected) =>
									selected.length === 0 ? (
										<em>--Select Questions--</em>
									) : (
										selected.join(", ")
									)
								}
							/>
						</Box>
						<Button onClick={handleOpen}>Select Questions</Button>
					</Box>
				)}
			</FormControl>
			<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
				<DialogTitle>Select Questions</DialogTitle>
				<DialogContent>
					{loading ? (
						<CircularProgress />
					) : (
						questions.map((question, index) => (
							<MenuItem key={question.questionId} value={question.questionId}>
								<Checkbox
									checked={selected.includes(question.questionId)}
									onChange={() => handleToggle(question.questionId)}
								/>
								<ListItemText
									primary={`${index + 1 + (currentPage - 1) * itemsPerPage}. ${
										question.question
									}`}
									secondary={
										<>
											<Box sx={{ display: "flex" }}>
												<Typography variant="body2" padding={0} marginRight={2}>
													Option 1: {question.option1}
												</Typography>
												<Typography variant="body2" padding={0}>
													Option 2: {question.option2}
												</Typography>
											</Box>
											<Box sx={{ display: "flex" }}>
												<Typography variant="body2" padding={0} marginRight={2}>
													Option 3: {question.option3}
												</Typography>
												<Typography variant="body2" padding={0}>
													Option 4: {question.option4}
												</Typography>
											</Box>
										</>
									}
									onClick={() => handleToggle(question.questionId)}
								/>
							</MenuItem>
						))
					)}
				</DialogContent>
				<DialogActions
					sx={{
						display: "flex",
						justifyContent: "spaceBetween",
					}}
				>
					<Pagination
						currentPage={currentPage}
						totalPages={totalPages}
						onPageChange={handlePageChange}
					/>
					<Box sx={{ marginTop: "0.8rem", paddingLeft: "10rem" }}>
						<Button onClick={handleClose} color="primary" variant="contained">
							Done
						</Button>
					</Box>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default QuestionSelect;
