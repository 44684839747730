import React, { useState, useEffect } from "react";
import { Modal, Box, IconButton, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useLoading from "../../Hooks/useLoading";
import { popup } from "leaflet";

const PopUpImage = () => {
	const [open, setOpen] = useState(false); // Set open to true by default
	const { showLoading, hideLoading } = useLoading();
	const [error, setError] = useState("");
	const [popUpImages, setpopUpImages] = useState([]);
	const [cookies] = useCookies(["userToken"]);
	const [token, setToken] = useState(cookies.userToken || "");

	useEffect(() => {
		setToken(cookies.userToken || "");
	}, [cookies]);

	useEffect(() => {
		GetBannerimg();
	}, []);

	const GetBannerimg = async () => {
		showLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/popUpImage/images`, {
				method: "GET",
				headers: {
					mode: "cors",
				},
			});
			if (response.ok) {
				const getApi = await response.json();
				setpopUpImages(getApi.popUpImages);
				setOpen(true);
				hideLoading();
			} else {
				const error = await response.json();
				setError(error.message);
				setOpen(false);
				hideLoading();
			}
		} catch (error) {
			showLoading();
		}
	};

	// Create chunks of images to display multiple per slide
	const chunkSize = 1; // Number of images per slide
	const chunks = [];
	for (let i = 0; i < popUpImages.length; i += chunkSize) {
		chunks.push(popUpImages.slice(i, i + chunkSize));
	}

	const handleClose = () => setOpen(false);

	return (
		<div>
			{popUpImages && (
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="modal-title"
					aria-describedby="modal-description"
				>
					<Box
						sx={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							width: { xs: "90vw", md: "80vh" }, // Responsive width
							height: { xs: "70vh", md: "71vh" }, // Responsive height
							bgcolor: "background.paper",
							boxShadow: 24,
							p: 4,
							borderRadius: "8px",
							outline: "none",
							position: "relative",
						}}
					>
						<IconButton
							sx={{
								position: "absolute",
								top: "10px",
								right: "10px",
							}}
							onClick={handleClose}
						>
							<CloseIcon />
						</IconButton>

						<Box>
							{popUpImages.length > 0 ? (
								<Carousel
									autoPlay
									interval={3000}
									infiniteLoop
									stopOnHover={false}
									showThumbs={false}
									showArrows={false}
								>
									{chunks.map((chunk, index) => (
										<div key={index}>
											{chunk.map((image, id) => (
												<Box
													key={id}
													sx={{
														width: "100%",
														height: "100%",
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
													}}
												>
													<img
														src={image.imageUrl}
														alt={image.imageName}
														style={{
															width: "100%", // Responsive width
															// height: 'auto', // Maintain aspect ratio
															height: "60vh",
															maxHeight: "60vh",
															marginTop: "20px",
															objectFit: "cover",
														}}
													/>
												</Box>
											))}
										</div>
									))}
								</Carousel>
							) : (
								<CircularProgress />
							)}
						</Box>
					</Box>
				</Modal>
			)}
		</div>
	);
};

export default PopUpImage;
