const typography = {
	fontFamily: "'DM Sans', sans-serif",
	body1: {
		fontWeight: 400, // or 'bold'
	},
	h1: {
		fontWeight: 500,
		fontSize: 30,
		letterSpacing: "-0.24px",
	},
	h2: {
		fontWeight: 500,
		fontSize: 24,
		letterSpacing: "-0.24px",
	},
	h3: {
		fontWeight: 500,
		fontSize: 21,
		letterSpacing: "-0.06px",
	},
	h4: {
		fontWeight: 500,
		fontSize: 18,
		letterSpacing: "-0.06px",
	},
	h5: {
		fontWeight: 500,
		fontSize: 16,
		letterSpacing: "-0.05px",
	},
	h6: {
		fontWeight: 500,
		fontSize: 14,
		letterSpacing: "-0.05px",
	},
	buttons: {
		textTransform: "none",
	},
};

export default typography;
