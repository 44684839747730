import React, { useState } from "react";
import { Typography, Link } from "@mui/material";

const TextTruncate = ({ text, maxLength }) => {
	const [isTruncated, setIsTruncated] = useState(true);

	const truncatedText =
		text.length > maxLength ? `${text.substring(0, maxLength)} ...` : text;

	return (
		<Typography sx={{ paddingLeft: "0", fontSize: "1.2rem"  }}>
			{isTruncated ? truncatedText : text}
		</Typography>
	);
};

export default TextTruncate;
