import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Chip,
	CircularProgress,
} from "@mui/material";
import Pagination from "../adminComponents/Pagination/Pagination";

export default function ExamList() {
	const [exams, setExams] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(8);
	const [totalPages, setTotalPages] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const nav = useNavigate();
	const [cookies] = useCookies(["adminToken"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	useEffect(() => {
		getData();
	}, [currentPage]);

	const handleDetails = (id) => {
		nav(`/admin/EXAM_DEPARTMENT/exam/details/${id}`);
	};

	const handleEdit = async (id) => {
		nav(`/admin/EXAM_DEPARTMENT/exam/edit/${id}`);
	};

	const handleUserList = async (id) => {
		nav(`/admin/EXAM_DEPARTMENT/exam/${id}/examinees/list`);
	};

	const getData = async () => {
		setLoading(true);
		try {
			let url = `${BASE_URL}/api/v1/exam/freeExams?page=${currentPage}&size=${itemsPerPage}`;

			const response = await fetch(url, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${adminToken}`,
				},
			});
			if (response.ok) {
				const apiData = await response.json();
				setExams(apiData.exams);
				setError("");
				setTotalPages(apiData.totalPages);
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setExams([]);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			setError("Failed to load exams.");
			setLoading(false);
		}
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};


	return (
		<Box>
			<Card variant="outlined">
				<CardContent>
					<Typography variant="h3">Free Exam List</Typography>
					<Box
						sx={{
							overflow: {
								xs: "auto",
								sm: "unset",
							},
							mt: 3,
						}}
					>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Exam Name
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Course Title
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Exam Type
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography color="textSecondary" variant="h5">
											Edit
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography color="textSecondary" variant="h5">
											Details
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography color="textSecondary" variant="h5">
											User List
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{exams.map((exam) => (
									<TableRow key={exam.examId}>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{exam.examName}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{exam.courseTitle}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{exam.examType}
											</Typography>
										</TableCell>
										<TableCell align="center">
											<Chip
												sx={{
													pl: "4px",
													pr: "4px",
													backgroundColor: "primary.main",
													color: "#fff",
												}}
												size="small"
												label="Edit"
												onClick={() => handleEdit(exam.examId)}
											/>
										</TableCell>
										<TableCell align="center">
											<Chip
												sx={{
													pl: "4px",
													pr: "4px",
													backgroundColor: "success.main",
													color: "#fff",
												}}
												size="small"
												label="Details"
												onClick={() => handleDetails(exam.examId)}
											/>
										</TableCell>
										<TableCell align="center">
											<Chip
												sx={{
													pl: "4px",
													pr: "4px",
													backgroundColor: "secondary.main",
													color: "#fff",
												}}
												size="small"
												label="Examinees"
												onClick={() => handleUserList(exam.examId)}
											/>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						{loading ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								<CircularProgress />
							</Box>
						) : null}
						{error ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								{error}
							</Box>
						) : null}
						<Pagination
							currentPage={currentPage}
							totalPages={totalPages}
							onPageChange={handlePageChange}
						/>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
}

