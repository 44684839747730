function TimeAgo(dateString) {
	const givenDate = new Date(dateString);
	const now = new Date();
	const diffInMs = now - givenDate;

	const minutes = Math.floor(diffInMs / (1000 * 60));
	const hours = Math.floor(diffInMs / (1000 * 60 * 60));
	const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

	if (days > 0) {
		return `${days} day${days > 1 ? "s" : ""} ago`;
	} else if (hours > 0) {
		return `${hours} hour${hours > 1 ? "s" : ""} ago`;
	} else {
		return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
	}
}
export default TimeAgo;
