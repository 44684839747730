import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Chip,
	CircularProgress,
} from "@mui/material";
import Pagination from "../adminComponents/Pagination/Pagination";

export default function QuestionBankLis() {
	const [questionBank, setQuestionBank] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(8);
	const [totalPages, setTotalPages] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const nav = useNavigate();
	const [cookies] = useCookies(["adminToken"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	useEffect(() => {
		getData();
	}, [currentPage]);

	function handleDetails(id) {
		nav(`/admin/EXAM_DEPARTMENT/questionBank/detail/${id}`);
	}

	const handleEdit = async (id) => {
		nav(`/admin/EXAM_DEPARTMENT/questionBank/edit/${id}`);
	};

	// const handleUserList = async (id) => {
	// 	nav(`/admin/EXAM_DEPARTMENT/exam/${id}/examinees/list`);
	// };

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/questionBank/questionBanks?page=${currentPage}&size=${itemsPerPage}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${adminToken}`,
					},
				}
			);
			if (response.ok) {
				const apiData = await response.json();
				setQuestionBank(apiData.questionBank);
				setTotalPages(apiData.totalPages);
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const pageNumbers = [];
	for (let i = 1; i <= totalPages; i++) {
		pageNumbers.push(i);
	}

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	return (
		<Box>
			<Card variant="outlined">
				<CardContent>
					<Typography variant="h3">Question Bank List</Typography>
					<Box
						sx={{
							overflow: {
								xs: "auto",
								sm: "unset",
							},
						}}
					>
						<Table
							aria-label="simple table"
							sx={{
								mt: 3,
							}}
						>
							<TableHead>
								<TableRow>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Name
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Course Title
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
										Total Questions
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Created Date
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography color="textSecondary" variant="h5">
											Details
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography color="textSecondary" variant="h5">
											Edit
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{questionBank.map((questionBank) => (
									<TableRow key={questionBank.id}>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{questionBank.name}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{questionBank.courseTitle}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{questionBank.totalQuestions}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{questionBank.createdDate}
											</Typography>
										</TableCell>
										<TableCell align="center">
											<Chip
												sx={{
													pl: "4px",
													pr: "4px",
													backgroundColor: "success.main",
													color: "#fff",
												}}
												size="small"
												label="Details"
												onClick={() => handleDetails(questionBank.id)}
											/>
										</TableCell>
										<TableCell align="center">
											<Chip
												sx={{
													pl: "4px",
													pr: "4px",
													backgroundColor: "primary.main",
													color: "#fff",
												}}
												size="small"
												label="Edit"
												onClick={() => handleEdit(questionBank.id)}
											/>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						{loading ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								<CircularProgress />
							</Box>
						) : null}
						{error ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								{error}
							</Box>
						) : null}
						<Pagination
							currentPage={currentPage}
							totalPages={totalPages}
							onPageChange={handlePageChange}
						/>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
}

