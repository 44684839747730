import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useCookies } from "react-cookie";
import { Container, Box, Typography, Button, CircularProgress } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import BASE_URL from "../../subcomponents/Config";
// import "react-toastify/dist/ReactToastify.css";

const AdminLoginForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: "1px solid #ccc",
  padding: "20px",
  borderRadius: "5px",
});

const initialFormState = {
  popUpImageFiles: [],
};

const AddPopUpImage = () => {
  const [formData, setFormData] = useState(initialFormState);
  const [loading, setLoading] = useState(false);
  const [imageLimitError, setImageLimitError] = useState("");
  const [imageRequiredError, setImageRequiredError] = useState(false);
  const [cookies] = useCookies(["adminToken", "adminRoles"]);
  const [adminToken, setAdminToken] = useState(cookies.adminToken || "");

  useEffect(() => {
    setAdminToken(cookies.adminToken || "");
  }, [cookies]);

  const handleFilesChange = (e) => {
    const files = Array.from(e.target.files);

    if (files.length > 4) {
      setImageLimitError("You can only upload up to 4 images.");
      return;
    } else {
      setImageLimitError("");
      setImageRequiredError(false); // Clear the required error when files are selected
      setFormData({ ...formData, popUpImageFiles: files });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if no images are selected
    if (formData.popUpImageFiles.length === 0) {
      setImageRequiredError(true);
    //   toast.error("Please select at least one image.");
      return;
    }

    if (formData.popUpImageFiles.length > 4) {
      toast.error("You can only upload up to 4 images.");
      return;
    }

    const data = new FormData();
    formData.popUpImageFiles.forEach((file) => {
      data.append("popUpImageFiles", file);
    });
    setLoading(true);

    try {
      const response = await fetch(`${BASE_URL}/api/v1/popUpImage/addImages`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
        body: data,
      });

      if (response.ok) {
        const result = await response.json();
        toast.success(result.message);
        setFormData(initialFormState);
      } else {
        const error = await response.json();
        toast.error(error.message || "Failed to upload images");
        console.error("Response error:", error);
      }
    } catch (err) {
      console.error("Network error:", err);
      toast.error("An error occurred during the upload.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={2000} />
      <Container maxWidth="xs">
        <AdminLoginForm onSubmit={handleSubmit}>
          <Typography variant="h2" gutterBottom>
            Add New PopUp Image
          </Typography>

          <Box width="100%" marginBottom="20px">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <Typography variant="body1" sx={{ flexGrow: 1, color: "gray" }}>
                Select PopUp Images
              </Typography>
              <input
                id="file-upload"
                type="file"
                accept="image/*"
                multiple
                onChange={handleFilesChange}
                style={{ display: "none" }}
                name="popUpImageFiles"
              />
              <label htmlFor="file-upload">
                <Button variant="outlined" component="span">
                  Select
                </Button>
              </label>
            </Box>

            {formData.popUpImageFiles.length > 0 && (
              <Box mt={2}>
                {formData.popUpImageFiles.map((image, index) => (
                  <Typography key={index} variant="body2" sx={{ marginTop: "10px", color: "gray" }}>
                    {image.name}
                  </Typography>
                ))}
              </Box>
            )}

            {imageRequiredError && <div style={{ color: "red" }}>Please select at least one image.</div>}
          </Box>

          <Button type="submit" variant="contained" color="primary">
            {loading ? <CircularProgress size={24} /> : "Add PopUp Image"}
          </Button>
        </AdminLoginForm>
      </Container>
    </>
  );
};

export default AddPopUpImage;
