import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BASE_URL from "../../subcomponents/Config";

const modalStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "auto",
	bgcolor: "background.paper",
	border: "1.5px solid #fd6a02",
	boxShadow: 24,
	p: 2,
	borderRadius: "20px",
};
const iconButtonStyle = {
	position: "absolute",
	top: "10px",
	right: "10px",
};

const WeeklyExamModal = ({ open, handleClose }) => {
	const [examData, setExamData] = useState([]);
	const [loading, setLoading] = useState("");
	const GetallData = async () => {
		setLoading(true);
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/course/courseList/exam/weeklyExamDate`,
				{
					method: "GET",
				}
			);
			if (response.ok) {
				const allData = await response.json();
				setExamData(allData.courses);
				setLoading(false);
			} else {
				const error = await response.json();
				console.log(error);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data.", error);
		}
	};
	useEffect(() => {
		GetallData();
	}, []);
	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="course-list-modal"
		>
			<Box sx={modalStyle}>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={iconButtonStyle}
				>
					<CloseIcon />
				</IconButton>
				<Typography
					id="course-list-modal"
					variant="h6"
					component="h2"
					align="center"
				>
					Weekly Exam List
				</Typography>
				{examData &&
					examData.map((exam) => (
						<Typography>
							<span style={{ color: "#fd6a02" }}>{exam.title}:</span>{" "}
							{exam.weeklyExamDate}
						</Typography>
					))}
			</Box>
		</Modal>
	);
};

export default WeeklyExamModal;
