import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import "../adminComponents/admin.scss";
import useFormValidation from "../../Hooks/useFormValidation";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableRow,
	CircularProgress,
	Button,
	Select,
	MenuItem,
	Chip,
	TextField,
} from "@mui/material";
import BASE_URL from "../../subcomponents/Config.jsx";
import { toast, ToastContainer } from "react-toastify";

const initialFormState = {
	duration: "",

	// depositedAmount: "",
	// remarks: "",
};

const tablevalue = [
	{ title: "User Name", value: "userName" },
	{ title: "Course Title", value: "courseTitle" },
	{ title: "Course Fee", value: "courseFee" },
	{ title: "Created Date", value: "createdDate" },
	{ title: "Deposited Amount", value: "depositedAmount" },
	{ title: "Remarks", value: "remarks" },
];

const PaymentForm = styled("form")({
	width: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "5px",
	borderRadius: "5px",
});

export default function ReportDetails() {
	const { id } = useParams();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [cookies] = useCookies(["adminToken"]);
	const nav = useNavigate();
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	function Back() {
		nav(-1);
	}

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/account/report/${id}`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${adminToken}`,
				},
			});
			if (response.ok) {
				const apiData = await response.json();
				setData(apiData.report);

				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const handleSubscribeChange = async () => {
		setLoading(true);
		try {
			const newSubscription =
				data.userRole === "UNSUBSCRIBED" ? "SUBSCRIBED" : "UNSUBSCRIBED";

			const response = await fetch(
				`${BASE_URL}/api/v1/updateRole/${data.userId}?newRoleName=${newSubscription}`,
				{
					method: "POST",
					headers: {
						Authorization: `Bearer ${adminToken}`,
					},
				}
			);

			if (response.ok) {
				const { message } = await response.json();
				toast.success(message);
				getData();
			} else {
				const error = await response.json();
				toast.error(error.message);
			}
		} catch (error) {
			console.log("An error occurred:", error.message);
			setError("An unexpected error occurred. Please try again.");
		} finally {
			setLoading(false);
		}
	};
	return (
		<Box>
			<ToastContainer position="top-center" autoClose="2000" />
			<Button
				onClick={() => Back()}
				variant="outlined"
				color="secondary"
				style={{ marginBottom: "0.2rem" }}
			>
				Back
			</Button>
			<Card variant="outlined">
				<CardContent>
					<Typography variant="h3">Report Details</Typography>

					<Box
						sx={{
							overflow: {
								xs: "auto",
								sm: "unset",
							},
						}}
					>
						<Table
							aria-label="simple table"
							sx={{
								mt: 3,
							}}
						>
							{!loading && data ? (
								<>
									<TableBody>
										{tablevalue &&
											tablevalue.map((table, index) => (
												<TableRow key={index}>
													<TableCell>
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "500",
															}}
														>
															{table.title}
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "500",
															}}
														>
															{data[table.value]}
														</Typography>
													</TableCell>
												</TableRow>
											))}

										<TableRow>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
													}}
												>
													User Subscription
												</Typography>
											</TableCell>
											<TableCell>
												<Chip
													sx={{
														pl: "4px",
														pr: "4px",
														backgroundColor:
															data.userRole === "UNSUBSCRIBED"
																? "primary.main"
																: "error.main",
														color: "#fff",
													}}
													size="small"
													label={
														data.userRole === "UNSUBSCRIBED"
															? "Subscribe"
															: "Unsubscribe"
													}
													onClick={() => handleSubscribeChange()}
												/>
											</TableCell>
										</TableRow>
									</TableBody>
								</>
							) : (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "200px",
									}}
								>
									<CircularProgress />
								</Box>
							)}
							{error && (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "200px",
									}}
								>
									{error}
								</Box>
							)}
						</Table>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
}
