import React, { useEffect, useState } from "react";
import { TextField, Button, Typography, Box, Container, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import BASE_URL from "../subcomponents/Config.jsx";
import useFormValidation from "../Hooks/useFormValidation.js";

const AdminLoginForm = styled("form")({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	marginTop: "10px",
	border: "1px solid #ccc",
	padding: "20px",
	borderRadius: "5px",
});

const AdminChangePassword = () => {
	const nav = useNavigate();
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	const initialFormState = {
		oldPassword: "",
		newPassword: "",
		confirmPassword: "",
	};

	const {
		formData,
		setErrors,
		errors,
		handleChange,
		validateForm,
		setFormData,
	} = useFormValidation(initialFormState);


	const [cookies, setCookie, removeCookie] = useCookies(["userToken","adminToken",]);
    const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	const [token, setToken] = useState(cookies.userToken || "");


    const handlelogout = () => {
		removeCookie("adminToken", { path: "/admin" });
		removeCookie("adminRole", { path: "/admin" });
		removeCookie("adminId", { path: "/admin" });
		nav("/admin");
	};

	useEffect(() => {
		// setToken(cookies.userToken || "");
        setToken(cookies.adminToken || "");
	}, [cookies]);

	const handleLogin = async (e) => {
		e.preventDefault();

		const validationErrors = validateForm(formData);
		console.log(errors);

		if (Object.keys(validationErrors).length === 0) {
			setLoading(true);
			console.log(formData);
			try {
				const response = await fetch(`${BASE_URL}/api/v1/admin/changePassword`, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${adminToken}`,
						"Content-Type": "application/json",
					},
					body: JSON.stringify(formData),
				});
				if (response.ok) {
					const data = await response.json();
					toast.success("Password Changed Successfully!!");
					setFormData(initialFormState);
                    handlelogout();
					setLoading(false);
				} else {
					const error = await response.json();
					setError(error.message);
					setLoading(false);
				}
			} catch (error) {
				console.log("Fetch error:", error.message);
				setLoading(false);
			}
		} else {
			setErrors(validationErrors);
		}
	};

    function Back() {
        nav(-1);
      }

    

    return (
        <>
            <ToastContainer position="top-center" autoClose={3000} />
            <Container maxWidth="xs">
            <Button
                onClick={() => Back()}
                variant="outlined"
                color="secondary"
                // style={{ marginBottom: "0" }}
                // pb="0"
                // sx={{pb:"0", mb: "0" }}
            >
                Back
            </Button>
                <AdminLoginForm onSubmit={handleLogin}>
                    <Typography variant="h2" gutterBottom marginBottom="1rem">
                        Change Password
                    </Typography>
                    <Box width="100%" marginBottom="20px">
                        <TextField
                            label="Old Password"
                            type="oldPassword"
                            name="oldPassword"
                            variant="outlined"
                            fullWidth
                            value={formData.oldPassword}
                            onChange={handleChange}
                        />
                        {errors.oldPassword && (
                            <span style={{ color: "red" }}>{errors.oldPassword}</span>
                        )}
                    </Box>
                    <Box width="100%" marginBottom="20px">
                        <TextField
                            label="New Password"
                            type="newPassword"
                            name="newPassword"
                            variant="outlined"
                            fullWidth
                            value={formData.newPassword}
                            onChange={handleChange}
                        />
                        {errors.newPassword && (
                            <span style={{ color: "red" }}>{errors.newPassword}</span>
                        )}
                    </Box>
                    <Box width="100%" marginBottom="20px">
                        <TextField
                            label="Confirm Password"
                            type="confirmPassword"
                            name="confirmPassword"
                            variant="outlined"
                            fullWidth
                            value={formData.confirmPassword}
                            onChange={handleChange}
                        />
                        {errors.confirmPassword && (
                            <span style={{ color: "red" }}>{errors.confirmPassword}</span>
                        )}
                    </Box>

                    <Button type="submit" variant="contained" color="secondary">
                        {loading ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "10px",
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Box >Change Password</Box>
                        )}
                    </Button>
                </AdminLoginForm>
            </Container>
        </>
    )
}

export default AdminChangePassword
