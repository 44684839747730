import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useCookies } from "react-cookie";
import BASE_URL from "../../subcomponents/Config";
import {
  TextField,
  Button,
  Typography,
  Container,
  CircularProgress,
  Box,
  Stack,
} from "@mui/material";
import useFormValidation from "../../Hooks/useFormValidation";
import { toast, ToastContainer } from "react-toastify";

const AdminLoginForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: "1px solid #ccc",
  padding: "20px",
  borderRadius: "5px",
});

const initialFormState = {
  title: "",
  expirationDate: "",
  vacancyUrl: "",
  vacancyPicture: null,
  pdfFile: null,
};

export default function CreateVacancy() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [cookies] = useCookies(["adminToken"]);
  const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
  const {
    formData,
    setFormData,
    handleFileChange,
    setErrors,
    errors,
    handleChange,
    validateForm,
	handleFileChangePDF,
  } = useFormValidation(initialFormState);

  useEffect(() => {
    setAdminToken(cookies.adminToken || "");
  }, [cookies]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm(formData);

    if (Object.keys(validationErrors).length === 0) {
      const data = new FormData();
      data.append("title", formData.title);
      data.append("expirationDate", formData.expirationDate);
      data.append("vacancyUrl", formData.vacancyUrl);
      data.append("vacancyPicture", formData.vacancyPicture);
      data.append("pdfFile", formData.pdfFile);

      setLoading(true);
      try {
        const response = await fetch(
          `${BASE_URL}/api/v1/vacancy/createVacancy`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${adminToken}`,
            },
            body: data,
          }
        );

        if (response.ok) {
          const userData = await response.json();
          toast.success(userData.message);
          setLoading(false);
          setFormData(initialFormState);
        } else {
          const error = await response.json();
          setError(error.message);
          setLoading(false);
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <Container maxWidth="xs">
      <ToastContainer position="top-center" autoClose={1500} />
      <AdminLoginForm onSubmit={handleSubmit}>
        <Typography variant="h2" gutterBottom>
          Create New Vacancy
        </Typography>
        {error && (
          <Typography variant="subtitle2" color="error" gutterBottom>
            {error}!!
          </Typography>
        )}
        <Box width="100%" marginBottom="20px">
          <TextField
            label="Job Title"
            variant="outlined"
            name="title"
            fullWidth
            value={formData.title}
            onChange={handleChange}
          />
          {errors.title && <span style={{ color: "red" }}>{errors.title}</span>}
        </Box>
        <Box width="100%" marginBottom="20px">
          <TextField
            label="Vacancy Expiration Date"
            variant="outlined"
            name="expirationDate"
            fullWidth
            type="date"
            value={formData.expirationDate}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {errors.expirationDate && (
            <span style={{ color: "red" }}>{errors.expirationDate}</span>
          )}
        </Box>
        <Box width="100%" marginBottom="20px">
          <TextField
            label="Vacancy Url"
            variant="outlined"
            name="vacancyUrl"
            fullWidth
            value={formData.vacancyUrl}
            onChange={handleChange}
          />
        </Box>


        {/* this is for pdf file */}
        <Box width="100%" marginBottom="20px">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <Typography variant="body1" sx={{ flexGrow: 1, color: "gray" }}>
              Select PDF:
            </Typography>
            <input
              id="file-upload"
              type="file"
              onChange={handleFileChangePDF}
              accept="application/pdf"
              style={{ display: "none" }} // Hides the default file input UI
              name="pdfFile"
            />
            {formData.pdfFile && (
              <Typography
                variant="body2"
                sx={{ marginTop: "10px", color: "gray", margin: "2px" }}
              >
                {formData.pdfFile.name}
              </Typography>
            )}
            <label htmlFor="file-upload">
              <Button variant="outlined" component="span">
                Select
              </Button>
            </label>
          </Box>

          {errors.pdfFile && (
            <span style={{ color: "red" }}>{errors.pdfFile}</span>
          )}
        </Box>


		    {/* this is for images */}
        <Box width="100%" marginBottom="20px">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <Typography variant="body1" sx={{ flexGrow: 1, color: "gray" }}>
              Select Vacancy Images:
            </Typography>
            <input
              id="image-upload"
              type="file"
              onChange={handleFileChange}
              accept="image/*" 
              style={{ display: "none" }}
              name="vacancyPicture"
            />
            {formData.vacancyPicture && (
              <Typography
                variant="body2"
                sx={{ marginTop: "10px", color: "gray", margin: "2px" }}
              >
                {formData.vacancyPicture.name}
              </Typography>
            )}
            <label htmlFor="image-upload">
              <Button variant="outlined" component="span">
                Select
              </Button>
            </label>
          </Box>

          {errors.vacancyPicture && (
            <span style={{ color: "red" }}>{errors.vacancyPicture}</span>
          )}
        </Box>


        <Button type="submit" variant="contained" color="secondary">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "10px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box>Create Vacancy</Box>
          )}
        </Button>
      </AdminLoginForm>
    </Container>
  );
}
