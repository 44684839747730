// AddReport.js

import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useCookies } from "react-cookie";
import BASE_URL from "../../subcomponents/Config";
import { TextField, Button, Typography, Container, Box } from "@mui/material";

import useFormValidation from "../../Hooks/useFormValidation";
import { toast, ToastContainer } from "react-toastify";
import CourseSelect from "../adminComponents/Api/CourseSelect";
import { red } from "@mui/material/colors";

const AdminLoginForm = styled("form")({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	border: "1px solid #ccc",
	padding: "20px",
	borderRadius: "5px",
});

const initialFormState = {
	email: "",
	courseId: "",
	depositedAmount: "",
	remarks: "",
};

const AddReport = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [cookies] = useCookies(["adminToken", "adminRoles"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");

	const {
		formData,
		setFormData,
		errors,
		setErrors,
		handleChange,
		validateForm,
	} = useFormValidation(initialFormState);

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	const handleSelectCourse = (courseId) => {
		setFormData({ ...formData, courseId });

		setErrors((prevErrors) => ({
			...prevErrors,
			courseId: "",
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const validationErrors = validateForm(formData);
		if (Object.keys(validationErrors).length === 0) {
			setLoading(true);
			try {
				const response = await fetch(`${BASE_URL}/api/v1/account/addReport`, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${adminToken}`,
						"Content-Type": "application/json",
					},
					body: JSON.stringify(formData),
				});

				if (response.ok) {
					const courseData = await response.json();
					toast.success(courseData.message);
					setFormData(initialFormState);
					setLoading(false);
				} else {
					const error = await response.json();
					setError(error.message);
					setLoading(false);
				}
			} catch (error) {
				console.log(error.message);
			}
		} else {
			setErrors(validationErrors);
		}
	};

	return (
		<>
			<ToastContainer position="top-center" autoClose="2000" />
			<Container maxWidth="xs">
				<AdminLoginForm onSubmit={handleSubmit}>
					<Typography variant="h2" gutterBottom>
						Add Account Report
					</Typography>
					{error && (
						<div style={{ color: "red", padding: "0.5rem" }}>{error}</div>
					)}
					<Box width="100%" marginBottom="20px">
						<TextField
							label="User Email"
							variant="outlined"
							name="email"
							fullWidth
							value={formData.email}
							onChange={handleChange}
						/>
						{errors.userName && (
							<span style={{ color: "red" }}>{errors.userName}</span>
						)}
					</Box>
					<Box width="100%" marginBottom="20px">
						<CourseSelect
							selectedCourseId={formData.courseId}
							onSelectCourse={handleSelectCourse}
							value={formData.courseId}
							name="courseId"
							onChange={handleChange}
						/>
						<Box>
							{errors.courseId && (
								<span style={{ color: "red" }}>{errors.courseId}</span>
							)}
						</Box>
					</Box>
					<Box width="100%" marginBottom="20px">
						<TextField
							label="Deposited Amount"
							variant="outlined"
							type="number"
							name="depositedAmount"
							fullWidth
							value={formData.depositedAmount}
							onChange={handleChange}
						/>
						{errors.depositedAmount && (
							<span style={{ color: "red" }}>{errors.depositedAmount}</span>
						)}
					</Box>
					<Box width="100%" marginBottom="20px">
						<TextField
							label="Remarks"
							variant="outlined"
							name="remarks"
							fullWidth
							value={formData.remarks}
							onChange={handleChange}
						/>
						{errors.remarks && (
							<span style={{ color: "red" }}>{errors.remarks}</span>
						)}
					</Box>
					<Button
						type="submit"
						variant="contained"
						color="secondary"
						disabled={loading}
					>
						Add Report
					</Button>
				</AdminLoginForm>
			</Container>
		</>
	);
};

export default AddReport;
