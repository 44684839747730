import React, { useEffect, useState } from "react";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { GoArrowRight } from "react-icons/go";
import { GoArrowLeft } from "react-icons/go";
import { NavLink } from "react-router-dom";
import "../../Styles/Home/articleForHomepage.scss";
import useLoading from "../../Hooks/useLoading";

const ArticlesForHomepage = () => {
	const nav = useNavigate();
	const { loading, showLoading, hideLoading } = useLoading();
	const [articles, setArticles] = useState([]);

	const [cookies] = useCookies(["userToken"]);
	const [token, setToken] = useState(cookies.userToken || "");
	useEffect(() => {
		setToken(cookies.userToken || "");
	}, [cookies]);

	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [itemsPerPage] = useState(1);

	useEffect(() => {
		Articles();
	}, [currentPage]);

	const Articles = async () => {
		showLoading();
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/news/articles?page=${currentPage}&size=${itemsPerPage}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
						mode: "cors",
					},
				}
			);
			if (response.ok) {
				const getApi = await response.json();
				setArticles(getApi.articles);
				setTotalPages(getApi.totalPages);
				hideLoading();
			} else {
				const error = await response.json();
				console.log(error);
				hideLoading();
			}
		} catch (error) {
			console.error("Error fetching data.", error);
		}
	};

	function handlePageClick(pageNumber) {
		setCurrentPage(pageNumber);
	}

	const pageNumbers = [];
	for (let i = 1; i <= totalPages; i++) {
		pageNumbers.push(i);
	}

	const ShowDetailsArticles = (id) => {
		nav(`/article/details/${id}`);
		window.location.reload();
	};

	const showArticles = () => {
		nav(`article`);
		window.scrollTo(0, 0);
	};

	{
		pageNumbers &&
			pageNumbers.map((pageNumber) => (
				<button
					disabled={currentPage === pageNumber}
					key={pageNumber}
					onClick={() => handlePageClick(pageNumber)}
				></button>
			));
	}

	return (
		<div className="article_main_container">
			<div className="heading_articles">
				<h3>Latest Articles</h3>
			</div>
			<div className="articlesForHomepage">
				{articles &&
					articles.map((data, index) => (
						<div key={index}>
							<div
								className="article_container"
								key={index}
								onClick={() => ShowDetailsArticles(data.id)}
							>
								<img src={data.imageUrls[0]} alt="img" />
								<h3>{data.title}</h3>
								<div className="article_description">{data.details}</div>
							</div>
							<div className="view_more">
								<button onClick={showArticles}>More articles</button>
							</div>
						</div>
					))}
			</div>
			<div className="make_gride">
				<div className="for_pagination">
					<div className="prev_next">
						<button
							onClick={() => setCurrentPage(currentPage - 1)}
							disabled={currentPage === 1}
						>
							<span>
								<GoArrowLeft />
							</span>
						</button>
					</div>
					<div className="next">
						<button
							onClick={() => setCurrentPage(currentPage + 1)}
							disabled={currentPage >= totalPages}
						>
							<span>
								<GoArrowRight />
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ArticlesForHomepage;
