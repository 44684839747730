// hooks/useTrending.js
import { useState } from "react";
import { toast } from "react-toastify";
import BASE_URL from "../subcomponents/Config";

const useTrending = (adminToken, data, getData) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const handleTrendingChange = async () => {
		setLoading(true);
		try {
			const newTrending = data.trending ? false : true;

			const response = await fetch(
				`${BASE_URL}/api/v1/course/setTrending/${data.id}?trending=${newTrending}`,
				{
					method: "PUT",
					headers: {
						Authorization: `Bearer ${adminToken}`,
					},
				}
			);

			if (response.ok) {
				const { message } = await response.json();
				toast.success(message);
				getData();
			} else {
				const error = await response.json();
				setError(error.message);
			}
		} catch (error) {
			console.log("An error occurred:", error.message);
		} finally {
			setLoading(false);
		}
	};

	return { handleTrendingChange, loading, error };
};

export default useTrending;
