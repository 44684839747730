import React, { useContext, useEffect, useState } from "react";
import BASE_URL from "../../subcomponents/Config";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../subcomponents/Context";
import { useCookies } from "react-cookie";
import useFormValidation from "../../Hooks/useFormValidation";
import { IoArrowBackSharp } from "react-icons/io5";
import { toast, ToastContainer } from "react-toastify";
import { Box, CircularProgress } from "@mui/material";
import { IoHomeOutline } from "react-icons/io5";
import "../../Styles/User/Login.scss";

const Login = () => {
	const nav = useNavigate();

	const location = useLocation();
	const { from } = location.state || {};
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const { message, setMessage } = useContext(Context);

	const initialFormState = {
		email: "",
		password: "",
	};

	const { formData, setErrors, errors, handleChange, validateForm } =
		useFormValidation(initialFormState);

	const [cookies, setCookie] = useCookies([
		"userToken",
		"userRole",
		"userId",
		"videoDownload",
		"mediaDownloaded",
		"firstName",
	]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		const validationErrors = validateForm(formData);

		if (Object.keys(validationErrors).length === 0) {
			setLoading(true);
			try {
				const response = await fetch(`${BASE_URL}/api/v1/auth/login`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(formData),
				});
				if (response.ok) {
					const data = await response.json();
					setCookie("userToken", `${data.token}`, {
						path: "/",
						maxAge: data.tokenExpiryTime,
					});
					setCookie("userRole", `${data.role}`, {
						path: "/",
						maxAge: data.tokenExpiryTime,
					});
					setCookie("userId", `${data.userId}`, {
						path: "/",
						maxAge: data.tokenExpiryTime,
					});
					setCookie("firstName", `${data.firstName}`, {
						path: "/",
						maxAge: data.tokenExpiryTime,
					});
					setCookie("videoDownload", `${data.canDownloadMediaPlayer}`, {
						path: "/",
						maxAge: data.tokenExpiryTime,
					});
					setCookie("mediaPlayerDownloaded", `${data.mediaPlayerDownloaded}`, {
						path: "/",
						maxAge: data.tokenExpiryTime,
					});
					if (from === "/register") {
						nav("/");
					} else if (from === "/exam") {
						nav("/exam");
					} else {
						nav(-1);
					}
					setLoading(false);
				} else {
					const error = await response.json();
					setError(error.message);
					setLoading(false);
				}
			} catch (error) {
				console.log("Fetch error:", error.message);
			}
		} else {
			setErrors(validationErrors);
		}
	};

	function onRegister() {
		nav("/register");
	}

	const handleBack = () => {
		if (from === "/exam") {
			nav("/");
		} else {
			nav(-1);
		}
	};

	const go_to_home = () => {
		nav("/");
	};

	useEffect(() => {
		if (message) {
			toast.success(message);
			setMessage("");
		}
	}, [message]);

	return (
		<>
			<ToastContainer position="top-center" autoClose={2000} />
			<div className="main_container">
				<div className="back_login">
					<button onClick={handleBack}>
						<IoArrowBackSharp />
					</button>
					<h3>Login</h3>
					<button onClick={go_to_home}>
						<IoHomeOutline />
					</button>
				</div>
				<div className="loging_container">
					<form action="" onSubmit={handleSubmit}>
						<div className="label_input">
							<label htmlFor="">Email</label>
							<br />
							<input
								type="text"
								className="logInput"
								placeholder="Enter your email"
								name="email"
								value={formData.email}
								onChange={handleChange}
							/>
							{errors.email && <span className="error">{errors.email}</span>}
						</div>
						<div>
							<label htmlFor="">Password</label>
							<br />
							<input
								type="password"
								className="logInput"
								placeholder="Enter your password"
								name="password"
								value={formData.password}
								onChange={handleChange}
							/>
							{errors.password && (
								<span className="error">{errors.password}</span>
							)}
						</div>
						{error && (
							<p style={{ color: "red", textAlign: "center" }}>{error}</p>
						)}
						<button>
							{loading ? (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "10px",
									}}
								>
									<CircularProgress />
								</Box>
							) : (
								<div>Login</div>
							)}
						</button>
					</form>
					<div className="text">
						<div className="forget_password">Forget Password ?</div>
						<div>
							Don't have an account ?
							<button onClick={onRegister}>Register</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Login;
