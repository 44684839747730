import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  CircularProgress,
  Menu,
  MenuItem,
} from "@mui/material";
import Pagination from "../adminComponents/Pagination/Pagination";

export default function CourseReport() {
  const [courseReports, setCourseReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const nav = useNavigate();
  const [cookies] = useCookies(["adminToken"]);
  const [adminToken, setAdminToken] = useState(cookies.adminToken || "");

  const [anchorEl, setAnchorEl] = useState(null); // State to track menu position
  const [selectedCourseId, setSelectedCourseId] = useState(null);

  useEffect(() => {
    setAdminToken(cookies.adminToken || "");
  }, [cookies]);

  useEffect(() => {
    getData();
  }, [currentPage]);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${BASE_URL}/api/v1/account/courseReport?page=${currentPage}&size=${itemsPerPage}`,
        {
          method: "GET",
        }
      );
      if (response.ok) {
        const apiData = await response.json();
        setCourseReports(apiData.courseReports);
        setTotalPages(apiData.totalPages);
        setLoading(false);
      } else {
        const error = await response.json();
        setError(error.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDetails = (courseId) => {
    nav(`/admin/ACCOUNT_DEPARTMENT/Course/details/${courseId}`);
  };

  const handleSelectDutation = (name) => {
    nav(`/admin/ACCOUNT_DEPARTMENT/selectDuration/${selectedCourseId}/${name}`);
    console.log(selectedCourseId);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Functions to handle the dropdown menu
  const handleMenuOpen = (event, courseId) => {
    setAnchorEl(event.currentTarget);
    setSelectedCourseId(courseId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h3">Course Report</Typography>
          <Box
            sx={{
              overflow: {
                xs: "auto",
                sm: "unset",
              },
            }}
          >
            <Table aria-label="simple table" sx={{ mt: 3, overflowX: "auto" }}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <Typography color="textSecondary" variant="h5">
                      Course Title
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography color="textSecondary" variant="h5">
                      No of Users enrolled
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography color="textSecondary" variant="h5">
                      Total amount paid
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography color="textSecondary" variant="h5">
                      Enrollment Report
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography color="textSecondary" variant="h5">
                      Details
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {courseReports &&
                  courseReports.map((course) => (
                    <TableRow key={course.id}>
                      <TableCell
                        align="left"
                        sx={{ fontSize: "15px", fontWeight: "500" }}
                      >
                        {course.courseTitle}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontSize: "15px", fontWeight: "500" }}
                      >
                        {course.enrolledUserCount}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontSize: "15px", fontWeight: "500" }}
                      >
                        {course.totalAmountPaid}
                      </TableCell>
                      <TableCell align="center">
                        <Chip
                          sx={{
                            pl: "4px",
                            pr: "4px",
                            backgroundColor: "success.main",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                          size="small"
                          label="Select Duration"
                          onMouseEnter={(event) =>
                            handleMenuOpen(event, course.courseId)
                          }
                        />
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          MenuListProps={{
                            onMouseLeave: handleMenuClose, // Close the menu on mouse leave
                          }}
                        >
                          <MenuItem
                            onClick={() => handleSelectDutation("weekly")}
                          >
                            Weekly
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleSelectDutation("monthly")
                            }
                          >
                            Monthly
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleSelectDutation("yearly")
                            }
                          >
                            Yearly
                          </MenuItem>
                        </Menu>
                      </TableCell>
                      <TableCell align="center">
                        <Chip
                          sx={{
                            pl: "4px",
                            pr: "4px",
                            backgroundColor: "success.main",
                            color: "#fff",
                          }}
                          size="small"
                          label="Details"
                          onClick={() => handleDetails(course.courseId)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>

            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : null}
            {error ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                {error}
              </Box>
            ) : null}
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
