import React from "react";
import { Box, Button } from "@mui/material";
import "../admin.scss";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
	const pageNumbers = [];
	for (let i = 1; i <= totalPages; i++) {
		pageNumbers.push(i);
	}

	return (
		<Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
			<div className="pagination">
				<div className="pagination-prev ">
					<button
						onClick={() => onPageChange(currentPage - 1)}
						disabled={currentPage === 1}
						className="previous"
					>
						Previous
					</button>
				</div>
				{pageNumbers &&
					pageNumbers.map((pageNumber) => (
						<button
							disabled={currentPage === pageNumber}
							key={pageNumber}
							onClick={() => onPageChange(pageNumber)}
						>
							{pageNumber}
						</button>
					))}
				<div className="pagination-prev">
					<button
						onClick={() => onPageChange(currentPage + 1)}
						disabled={currentPage >= totalPages}
						className="next"
					>
						Next
					</button>
				</div>
			</div>
		</Box>
	);
};

export default Pagination;
