import copy from "copy-to-clipboard";
import WEBSITE_URL from "../../subcomponents/Website";
import { FaCopy } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Ensure this is imported

export default function CopyClipboard() {
	const handleCopy = () => {
		copy(`${WEBSITE_URL}/articlesDetails/${localStorage.getItem("detailsid")}`);
		toast.success("Copied to Clipboard");
	};

	return (
		<>
			<ToastContainer position="top-center" autoClose={500} />
			<span onClick={handleCopy}>
				<FaCopy
					style={{
						color: "white",
						padding: "0.5rem",
						fontSize: "2.2rem",
						backgroundColor: "#fd6a02",
						cursor: "pointer",
					}}
				/>
			</span>
		</>
	);
}
