import React, { useEffect, useState } from "react";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { IoIosNotificationsOutline } from "react-icons/io";
import BASE_URL from '../../../subcomponents/Config';
import userimg from "../../assets/images/users/user.jpg";
import { useCookies } from "react-cookie";
import { NavLink, useNavigate } from "react-router-dom";
import { fetchRoles } from "../Api/Roles";
import {
	AppBar,
	Box,
	IconButton,
	Toolbar,
	Menu,
	MenuItem,
	Button,
	Avatar,
	ListItemIcon,
	Badge,
} from "@mui/material";

const Header = (props) => {
	const nav = useNavigate();
	const [cookies, setCookie, removeCookie] = useCookies([
		"adminToken",
		"adminRole",
	]);
	const [adminRoles, setAdminRoles] = useState([]);
	const [anchorEl4, setAnchorEl4] = useState(null);
	const [loading, setLoading] = useState(false);
	const [messageCount, setMessageCount] = useState(0);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || '');

	const getMessageCount = async () => {
		try {
			const response = await fetch(`${BASE_URL}/api/v1/contact/unseenCount`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${adminToken}`,
				},
			});
			if (response.ok) {
				const apiData = await response.json();
				setMessageCount(apiData.count);
			} else {
				const error = await response.json();
				console.log(error.message);
			}
			} catch (error) {
				console.error('Error fetching message count:', error);
		}
	};

	  useEffect(() => {
		setAdminToken(cookies.adminToken || '');
		getMessageCount(); 
	
		const interval = setInterval(() => {
		  getMessageCount();
		}, 10000); 
	
		return () => clearInterval(interval);
	}, [cookies]);

	useEffect(() => {
		if (cookies.adminRole) {
			setAdminRoles(cookies.adminRole);
		}
	}, [cookies]);

	const handleClick4 = (event) => {
		setAnchorEl4(event.currentTarget);
	};

	const handleClose4 = () => {
		setAnchorEl4(null);
	};

	// 5
	const [anchorEl5, setAnchorEl5] = React.useState(null);

	const handleClick5 = (event) => {
		setAnchorEl5(event.currentTarget);
	};

	const handleClose5 = () => {
		setAnchorEl5(null);
	};
	const handlelogout = () => {
		removeCookie("adminToken", { path: "/admin" });
		removeCookie("adminRole", { path: "/admin" });
		removeCookie("adminId", { path: "/admin" });
		nav("/admin");
		handleClose4();
	};

	const handleUserProfile = () => {
		nav("/admin/adminProfile");
		handleClose4();
	};

	const handleMessage = () =>{
		nav("/admin/SUPER_ADMIN/viewMessages");
	}

	const handleClick = () => {
		handleMessage(); 
	};

	return (
		<AppBar sx={props.sx} elevation={0} className={props.customClass}>
			<Toolbar>
				<IconButton
					color="inherit"
					aria-label="menu"
					onClick={props.toggleMobileSidebar}
					sx={{
						display: {
							lg: "none",
							xs: "inline",
						},
					}}
				>
					<MenuOutlinedIcon width="20" height="20" />
				</IconButton>
				<IconButton
					aria-label="menu"
					color="inherit"
					aria-controls="dd-menu"
					aria-haspopup="true"
					onClick={handleClick5}
				>
					<AddToPhotosOutlinedIcon />{" "}
					<Box
						sx={{
							ml: 0.5,
							fontSize: "1rem",
						}}
					>
						Departments
					</Box>
				</IconButton>
				<Menu
					id="dd-menu"
					anchorEl={anchorEl5}
					keepMounted
					open={Boolean(anchorEl5)}
					onClose={handleClose5}
					anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
					transformOrigin={{ horizontal: "left", vertical: "top" }}
					sx={{
						"& .MuiMenu-paper": {
							width: "250px",
							right: 0,
							top: "70px !important",
						},
					}}
				>
					{adminRoles &&
						adminRoles.map((role, index) => (
							<NavLink
								to={`/admin/${role}/home`}
								style={{ textDecoration: "none" }}
								key={index}
							>
								<MenuItem onClick={handleClose5}>
									<Box
										sx={{
											ml: 2,
											color: "black",
										}}
									>
										{role
											.toLowerCase()
											.replace(/_/g, " ")
											.replace(/\b\w/g, (char) => char.toUpperCase())}
									</Box>
								</MenuItem>
							</NavLink>
						))}
				</Menu>
				<Box flexGrow={1} />

				{/* ------------------------------------------- */}
				{/* Profile Dropdown */}
				{/* ------------------------------------------- */}

				<Box 
				sx={{ 
					display: 'flex',
					alignItems: 'center',
					cursor: 'pointer',
					paddingTop: '5px'
					}} 
				onClick={handleClick}
				>
					<Badge
						badgeContent={messageCount}
						color="primary"
						overlap="circular"
						sx={{ width: '25px', height: '25px' }}
					>
						<IoIosNotificationsOutline style={{ width: '100%', height: '100%' }} />
					</Badge>
				</Box>

				<Box
					sx={{
						width: "1px",
						backgroundColor: "rgba(0,0,0,0.1)",
						height: "25px",
						ml: 1,
					}}
				></Box>
				<Button
					aria-label="menu"
					color="inherit"
					aria-controls="profile-menu"
					aria-haspopup="true"
					onClick={handleClick4}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
						}}
					>
						<Avatar
							src={userimg}
							alt={userimg}
							sx={{
								width: "30px",
								height: "30px",
							}}
						/>
					</Box>
				</Button>
				<Menu
					id="profile-menu"
					anchorEl={anchorEl4}
					keepMounted
					open={Boolean(anchorEl4)}
					onClose={handleClose4}
					anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
					transformOrigin={{ horizontal: "right", vertical: "top" }}
					sx={{
						"& .MuiMenu-paper": {
							width: "250px",
							right: 0,
							top: "70px !important",
						},
					}}
				>
					<MenuItem onClick={() => handleUserProfile()}>
						<ListItemIcon>
							<AccountBoxIcon fontSize="small" />
						</ListItemIcon>
						View Profile
					</MenuItem>
					<MenuItem onClick={() => handlelogout()}>
						<ListItemIcon>
							<LogoutOutlinedIcon fontSize="small" />
						</ListItemIcon>
						Logout
					</MenuItem>
				</Menu>
			</Toolbar>
		</AppBar>
	);
};

export default Header;
