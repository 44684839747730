import React, { useEffect, useState } from "react";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { FaYoutube } from "react-icons/fa6";
import { VscFilePdf } from "react-icons/vsc";
import { MdArrowRight } from "react-icons/md";
import { GrDocumentPdf } from "react-icons/gr";
import "../../Styles/Courses/MyCoursesDetails.scss";
import VideoModal from "../components/Videos";
import DownloadIcon from "@mui/icons-material/Download";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import Loading from "../components/Loading";
import useLoading from "../../Hooks/useLoading";

const MyCoursesDetails = () => {
	const { id } = useParams();
	const { loading, showLoading, hideLoading } = useLoading();
	const [course, setCourse] = useState({});
	const [videos, setVideos] = useState([]);
	const [notes, setNotes] = useState([]);
	const [open, setOpen] = useState(false);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const nav = useNavigate();
	const [cookies, setCookie] = useCookies([
		"userToken",
		"userId",
		"videoDownload",
		"mediaPlayerDownloaded",
	]);
	const [token, setToken] = useState(cookies.userToken || "");
	const [userId, setUserId] = useState(cookies.userId || "");
	const [videoDownload, setVideoDownload] = useState(
		cookies.videoDownload || ""
	);
	const [mediaPlayerDownloaded, setMediaPlayerDownloaded] = useState(
		cookies.mediaPlayerDownloaded || ""
	);

	const [downloadVideo, setDownloadVideo] = useState("");
	const [downloadMediaPlayer, setDownloadMediaPlayer] = useState(false);

	useEffect(() => {
		setToken(cookies.userToken || "");
		setUserId(cookies.userId || "");
		setMediaPlayerDownloaded(cookies.mediaPlayerDownloaded || "");
		setVideoDownload(cookies.videoDownload || "");
	}, [cookies]);

	useEffect(() => {
		window.scrollTo(0, 0);
		MyCourseDetails();
	}, []);

	const MyCourseDetails = async () => {
		showLoading();
		try {
			const response = await fetch(`${BASE_URL}/api/v1/course/enrolled/${id}`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
					mode: "cors",
				},
			});
			if (response.ok) {
				const getApi = await response.json();
				setCourse(getApi.course);
				setCookie(
					"mediaPlayerDownloaded",
					`${getApi.course.mediaPlayerDownloaded}`,
					{
						path: "/",
					}
				);
				setCookie("videoDownload", `${getApi.course.mediaPlayerAccessStatus}`, {
					path: "/",
				});
				setVideos(getApi.course.videos);
				setNotes(getApi.course.notes);
				hideLoading();
			} else {
				const error = await response.json();
				console.log(error);
				hideLoading();
			}
		} catch (error) {
			console.error("Error fetching data.", error);
		}
	};
	const handleOpen = (videoUrl) => {
		setSelectedVideo(videoUrl);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setSelectedVideo(null);
	};

	const handleMediaPlayerDownload = async () => {
		window.open(
			`${BASE_URL}/api/v1/video/downloadMediaPlayer?userId=${userId}`
		);
		setCookie("mediaPlayerDownloaded", true, {
			path: "/",
		});
		setDownloadMediaPlayer(false);
	};

	const handleDownloadClick = async (name) => {
		window.open(
			`${BASE_URL}/api/v1/video/download?userId=${userId}&fileName=${name}`
		);
	};
	const handleMediaModal = async (name) => {
		setDownloadVideo(name);
		setDownloadMediaPlayer(true);
	};

	return (
		<>
			<Navbar />
			{loading ? <Loading /> : null}
			{!loading && (
				<main>
					<div className="myCourseDetails">
						{course && (
							<div>
								<div className="image">
									<img src={course.imageUrl} alt="" />
								</div>
								<div className="body_videos">
									<div className="title_para">
										<div className="title">{course.title}</div>
										<div className="para">{course.details}</div>
									</div>
									<div className="videos">
										<div className="videos-text">
											<h4>Available Videos </h4>
											<span className="title_icon">
												<FaYoutube />
											</span>
										</div>
										{videos &&
											videos.map((vdata, index) => (
												<div key={index} className="vieos_container">
													<div
														className="inner_grid"
														onClick={() => handleOpen(vdata.noteUrl)}
													>
														<span className="body_icon">
															<FaYoutube />
														</span>
														<div className="videos_title">
															{vdata.originalFileName}
														</div>
														<span className="play_icon">
															<MdArrowRight />
														</span>
													</div>
													{videoDownload && (
														<span
															className="download-button"
															onClick={() =>
																mediaPlayerDownloaded
																	? handleDownloadClick(vdata.uploadedFileName)
																	: handleMediaModal(vdata.uploadedFileName)
															}
														>
															<DownloadIcon />
														</span>
													)}
												</div>
											))}
									</div>
								</div>

								<h4>Available Notes</h4>
								<div className="for_note">
									{notes &&
										notes.map((ndata, index) => (
											<NavLink to={ndata.noteUrl} target="_blank" key={index}>
												<div className="note_grid">
													<div className="pdf_icon">
														<VscFilePdf />
													</div>
													<div className="pdf_title">
														<div>{ndata.originalFileName}</div>
													</div>
												</div>
											</NavLink>
										))}
								</div>
							</div>
						)}
					</div>

					<VideoModal
						open={open}
						handleClose={handleClose}
						videoUrl={selectedVideo}
					/>
					<Dialog
						open={downloadMediaPlayer}
						onClose={() => setDownloadMediaPlayer(false)}
						aria-labelledby="exam-confirmation-dialog"
					>
						<DialogTitle style={{ textAlign: "center", fontWeight: "600" }}>
							Download Media Player?
						</DialogTitle>
						<DialogContent>
							<div
								style={{
									fontSize: "1.1rem",
									padding: "0.5rem 1.8rem",
									textAlign: "justify",
								}}
							>
								To download the video you need to download the media player.
								Would you like to download the media player?
							</div>
							<div
								style={{
									fontSize: "1rem",
									padding: "0.5rem 1.8rem",
									textAlign: "justify",
									color: "red",
								}}
							>
								Note: In case you delete the media player, you must contact the
								admin to be abled to re-download.
							</div>
						</DialogContent>
						<DialogActions
							style={{ marginRight: "2rem", marginBottom: "1rem" }}
						>
							<Button
								onClick={() => setDownloadMediaPlayer(false)}
								sx={{
									marginRight: "2rem",
								}}
							>
								Cancel
							</Button>
							<Button
								onClick={handleMediaPlayerDownload}
								color="primary"
								sx={{
									color: "white",
									backgroundColor: "#fd6a02",
									"&:hover": { backgroundColor: "darkorange" },
									padding: "0.4rem 1.5rem",
									borderRadius: "10px",
								}}
							>
								Download
							</Button>
						</DialogActions>
					</Dialog>
				</main>
			)}
			<Footer />
		</>
	);
};

export default MyCoursesDetails;
