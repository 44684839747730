import React, { useState } from "react";
import BASE_URL from "../../subcomponents/Config";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../subcomponents/Context";
import useFormValidation from "../../Hooks/useFormValidation";
import {
	TextField,
	Button,
	CircularProgress,
	Box,
	Grid,
	Typography,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import CourseSelect from "../adminComponents/Api/CourseSelect";

const initialFormState = {
	firstName: "",
	middleName: "",
	lastName: "",
	fatherName: "",
	grandFatherName: "",
	address: "",
	interestedCourse: "",
	courseEnrollmentRequest: "",
	mobileNumber: "",
	email: "",
	password: "",
	confirmPassword: "",
};

const UserRegister = () => {
	const { setMessage } = useContext(Context);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const nav = useNavigate();

	const {
		formData,
		setErrors,
		errors,
		handleChange,
		validateForm,
		setFormData,
	} = useFormValidation(initialFormState);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const validationErrors = validateForm(formData);
		console.log(errors);
		if (Object.keys(validationErrors).length === 0) {
			const data = new FormData();
			data.append("firstName", formData.firstName);
			data.append("middleName", formData.middleName);
			data.append("lastName", formData.lastName);
			data.append("fatherName", formData.fatherName);
			data.append("grandFatherName", formData.grandFatherName);
			data.append("address", formData.address);
			data.append("interestedCourse", formData.interestedCourse);
			data.append("courseEnrollmentRequest", formData.courseEnrollmentRequest);
			data.append("mobileNumber", formData.mobileNumber);
			data.append("email", formData.email);
			data.append("password", formData.password);
			data.append("confirmPassword", formData.confirmPassword);
			setLoading(true);
			try {
				const response = await fetch(`${BASE_URL}/api/v1/auth/register`, {
					method: "POST",
					body: data,
				});

				if (response.ok) {
					const userData = await response.json();
					toast.success(userData.message);
					setFormData(initialFormState);
					setLoading(false);
				} else {
					const error = await response.json();
					setError(error.message);
					setLoading(false);
				}
			} catch (error) {
				console.log(error.message);
			}
		} else {
			setErrors(validationErrors);
		}
	};

	function handleLogin() {
		nav("/login", { state: { from: "/register" } });
	}

	const handleSelectCourse = (courseId) => {
		setFormData((prevState) => ({
		  ...prevState,
		  interestedCourse: courseId, // Use 'interestedCourse' or 'courseId' based on your API
		}));
		setErrors((prevErrors) => ({
		  ...prevErrors,
		  interestedCourse: "", // Clear error on successful selection
		}));
	  };

	return (
		<Box
			className="form"
			sx={{ maxWidth: 600, mx: "auto", p: 3, boxShadow: 3, borderRadius: 2 }}
		>
			<ToastContainer position="top-center" autoClose={1500} />
			<Typography
				variant="h3"
				component="h3"
				textAlign="center"
				marginBottom={2}
			>
				Register New User
			</Typography>
			<form onSubmit={handleSubmit}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={4}>
						<TextField
							fullWidth
							label="First Name"
							name="firstName"
							value={formData.firstName}
							onChange={handleChange}
							error={!!errors.firstName}
							helperText={errors.firstName}
							variant="outlined"
							placeholder="Enter your first name"
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							fullWidth
							label="Middle Name"
							name="middleName"
							value={formData.middleName}
							onChange={handleChange}
							error={!!errors.middleName}
							helperText={errors.middleName}
							variant="outlined"
							placeholder="Enter your middle name"
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							fullWidth
							label="Last Name"
							name="lastName"
							value={formData.lastName}
							onChange={handleChange}
							error={!!errors.lastName}
							helperText={errors.lastName}
							variant="outlined"
							placeholder="Enter your last name"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							label="Father Name"
							name="fatherName"
							value={formData.fatherName}
							onChange={handleChange}
							error={!!errors.fatherName}
							helperText={errors.fatherName}
							variant="outlined"
							placeholder="Enter your father name"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							label="Grand Father Name"
							name="grandFatherName"
							value={formData.grandFatherName}
							onChange={handleChange}
							error={!!errors.grandFatherName}
							helperText={errors.grandFatherName}
							variant="outlined"
							placeholder="Enter your grand father name"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							label="Address"
							name="address"
							value={formData.address}
							onChange={handleChange}
							error={!!errors.address}
							helperText={errors.address}
							variant="outlined"
							placeholder="Enter your father name"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CourseSelect
							selectedCourseId={formData.interestedCourse}
							onSelectCourse={handleSelectCourse}
							sx={{ width: '100%' }}
						/>
						{errors.interestedCourse && (
						<Typography sx={{color:"red", fontSize:"12px", paddingTop:"0.8rem"}}>{errors.interestedCourse}</Typography>
						)}
					</Grid>

					<Grid item xs={12}>
						<TextField
							label="Course Enrollment Request"
							variant="outlined"
							name="courseEnrollmentRequest"
							fullWidth
							type="date"
							onChange={handleChange}
							InputLabelProps={{
								shrink: true,
							}}
						/>
						{/* {errors.courseEnrollmentRequest && (
						<Typography sx={{color:"red", fontSize:"12px", paddingTop:"0.8rem"}}>{errors.courseEnrollmentRequest}</Typography>
						)} */}
					</Grid>


					<Grid item xs={12}>
						<TextField
							fullWidth
							label="Email"
							name="email"
							value={formData.email}
							onChange={handleChange}
							error={!!errors.email}
							helperText={errors.email}
							variant="outlined"
							placeholder="Enter your email address"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							label="Phone/Mobile"
							name="mobileNumber"
							value={formData.mobileNumber}
							onChange={handleChange}
							error={!!errors.mobileNumber}
							helperText={errors.mobileNumber}
							variant="outlined"
							placeholder="Enter your phone number"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							type="password"
							label="Password"
							name="password"
							value={formData.password}
							onChange={handleChange}
							error={!!errors.password}
							helperText={errors.password}
							variant="outlined"
							placeholder="Enter your password"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							type="password"
							label="Confirm Password"
							name="confirmPassword"
							value={formData.confirmPassword}
							onChange={handleChange}
							error={!!errors.confirmPassword}
							helperText={errors.confirmPassword}
							variant="outlined"
							placeholder="Confirm your password"
						/>
					</Grid>
					{error && (
						<Grid item xs={12}>
							<Typography color="error" textAlign="center">
								{error}
							</Typography>
						</Grid>
					)}
					<Grid item xs={12}>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="secondary"
							sx={{ mt: 2 }}
							disabled={loading}
						>
							{loading ? (
								<Box sx={{ display: "flex", justifyContent: "center" }}>
									<CircularProgress size={24} />
								</Box>
							) : (
								"Submit"
							)}
						</Button>
					</Grid>
				</Grid>
			</form>
		</Box>
	);
};

export default UserRegister;
