import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Chip,
	CircularProgress,
	Button,
} from "@mui/material";
import Pagination from "../adminComponents/Pagination/Pagination";
import TruncateText from "../adminComponents/Truncate/TruncateText";

export default function UserCourseList() {
	const { userId } = useParams();
	const [course, setCourse] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(8);
	const [totalPages, setTotalPages] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const nav = useNavigate();
	const [cookies] = useCookies(["adminToken"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	useEffect(() => {
		getData();
	}, [currentPage]);

	function handleDetails(id) {
		nav(`/admin/ADMISSION_DEPARTMENT/course/details/${id}`);
	}

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/course/enrolledCourses/${userId}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${adminToken}`,
					},
				}
			);
			if (response.ok) {
				const apiData = await response.json();
				setCourse(apiData.courses);
				setTotalPages(Math.ceil(apiData.courses.length / itemsPerPage));
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentData = course.slice(indexOfFirstItem, indexOfLastItem);

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};
	function Back() {
		nav(-1);
	}

	return (
		<Box>
			<Button
				onClick={() => Back()}
				variant="outlined"
				color="secondary"
				style={{ marginBottom: "0.2rem" }}
			>
				Back
			</Button>
			<Card variant="outlined">
				<CardContent>
					<Typography variant="h3">Course List</Typography>
					<Box
						sx={{
							overflow: {
								xs: "auto",
								sm: "unset",
							},
						}}
					>
						<Table
							aria-label="simple table"
							sx={{
								mt: 3,
							}}
						>
							<TableHead>
								<TableRow>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Course Title
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Course Details
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography color="textSecondary" variant="h5">
											Details
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{currentData &&
									currentData.map((course) => (
										<TableRow key={course.id}>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
														paddingLeft: "0",
													}}
												>
													{course.title}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
														paddingLeft: "0",
													}}
												>
													<TruncateText text={course.details} maxLength={70} />
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Chip
													sx={{
														pl: "4px",
														pr: "4px",
														backgroundColor: "success.main",
														color: "#fff",
													}}
													size="small"
													label="Details"
													onClick={() => handleDetails(course.id)}
												/>
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
						{loading ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								<CircularProgress />
							</Box>
						) : null}
						{error ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								{error}
							</Box>
						) : null}
						<Pagination
							currentPage={currentPage}
							totalPages={totalPages}
							onPageChange={handlePageChange}
						/>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
}
