import React, { useEffect, useState } from "react";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import { NavLink, useParams } from "react-router-dom";
import { VscFilePdf } from "react-icons/vsc";
import "../../Styles/Components/Overview.scss";
import TextTruncate from "../components/TextTruncate";

const Overview = () => {
	const [loading, setLoading] = useState(false);
	const { id } = useParams();
	const [data, setData] = useState({});

	const [cookies, setCookie] = useCookies(["userToken"]);
	const [token, setToken] = useState(cookies.userToken || "");

	const [maxLength, setMaxLength] = useState(25);

	useEffect(() => {
		const updateMaxLength = () => {
			if (window.innerWidth <= 768) {
				setMaxLength(25);
			} else {
				setMaxLength(65);
			}
		};
		updateMaxLength();
		// Update maxLength on window resize
		window.addEventListener("resize", updateMaxLength);
		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener("resize", updateMaxLength);
		};
	}, []);

	useEffect(() => {
		setToken(cookies.userToken || "");
	}, [cookies]);

	useEffect(() => {
		OverviewFunction();
	}, []);

	const OverviewFunction = async () => {
		setLoading(true);
		try {
			let url = "";
			let options = {
				method: "GET",
			};

			if (token === "") {
				url = `${BASE_URL}/api/v1/course/${id}`;
			} else {
				url = `${BASE_URL}/api/v1/course/${id}/loggedInUser`;

				options.headers = {
					Authorization: `Bearer ${token}`,
				};
			}
			const response = await fetch(url, options);
			if (response.ok) {
				const getApi = await response.json();
				setData(getApi.course);
				setLoading(false);
			} else {
				const error = await response.json();
				console.log(error);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data.", error);
		}
	};

	return (
		<div>
			{data && (
				<div className="overviewMain">
					<div className="syllaby">Course Syllabus</div>
					<div className="pdf_url">
						<NavLink to={data.syllabusUrl} target="_blank">
							<div className="icon_grid">
								<div className="pdf_icon">
									<VscFilePdf />
								</div>
								<div className="pdf_title">
									<TextTruncate
										text={data.syllabus || ""}
										maxLength={maxLength}
									/>
								</div>
							</div>
						</NavLink>
					</div>
					<div className="details">
						<h6>Course Curriculum</h6>
						<div className="curriculum_details">{data.details}</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Overview;
