import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import { toast, ToastContainer } from "react-toastify";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Chip,
	CircularProgress,
} from "@mui/material";
import TruncateText from "../adminComponents/Truncate/TruncateText";

export default function DisabledArticlesList() {
	const [articles, setArticles] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(8);
	const [totalPages, setTotalPages] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const nav = useNavigate();
	const [cookies] = useCookies(["adminToken"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	useEffect(() => {
		getData();
	}, []);

	function handleDetails(id) {
		nav(`/admin/MARKETING_DEPARTMENT/articles/details/${id}`);
	}

	const handleEnable = async (id) => {
		setLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/news/disable/${id}`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${adminToken}`,
				},
			});

			if (response.ok) {
				const userData = await response.json();
				// alert(userData.message);
				toast.success(userData.message);
				getData();
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.log(error.message);
		}
	};

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/news/disabledArticles`, {
				method: "GET",
			});
			if (response.ok) {
				const apiData = await response.json();
				setArticles(apiData.newsArticle);
				setTotalPages(Math.ceil(apiData.newsArticle.length / itemsPerPage));
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentData = articles.slice(indexOfFirstItem, indexOfLastItem);

	function handlePageClick(pageNumber) {
		setCurrentPage(pageNumber);
	}

	const pageNumbers = [];
	for (let i = 1; i <= totalPages; i++) {
		pageNumbers.push(i);
	}

	return (
		<>
			<ToastContainer position="top-center" autoClose={2000} />
			<Box>
				<Card variant="outlined">
					<CardContent>
						<Typography variant="h3">Disabled Articles List</Typography>
						<Box
							sx={{
								overflow: {
									xs: "auto",
									sm: "unset",
								},
							}}
						>
							<Table
								aria-label="simple table"
								sx={{
									mt: 3,
								}}
							>
								<TableHead>
									<TableRow>
										<TableCell>
											<Typography color="textSecondary" variant="h5">
												Article Title
											</Typography>
										</TableCell>
										<TableCell>
											<Typography color="textSecondary" variant="h5">
												Article Details
											</Typography>
										</TableCell>
										<TableCell align="center">
											<Typography color="textSecondary" variant="h5">
												Enable
											</Typography>
										</TableCell>
										<TableCell align="center">
											<Typography color="textSecondary" variant="h5">
												Details
											</Typography>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{currentData.map((article) => (
										<TableRow key={article.id}>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
														paddingLeft: "0",
													}}
												>
													{article.title}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
														paddingLeft: "0",
													}}
												>
													<TruncateText text={article.details} maxLength={70} />
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Chip
													sx={{
														pl: "4px",
														pr: "4px",
														backgroundColor: "error.main",
														color: "#fff",
													}}
													size="small"
													label="Enable"
													onClick={() => handleEnable(article.id)}
												/>
											</TableCell>
											<TableCell align="center">
												<Chip
													sx={{
														pl: "4px",
														pr: "4px",
														backgroundColor: "success.main",
														color: "#fff",
													}}
													size="small"
													label="Details"
													onClick={() => handleDetails(article.id)}
												/>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
							{loading ? (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "200px",
									}}
								>
									<CircularProgress />
								</Box>
							) : null}
							{error ? (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "200px",
									}}
								>
									{error}
								</Box>
							) : null}
							<Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
								<div className="pagination">
									<div className="pagination-prev">
										<button
											onClick={() => setCurrentPage(currentPage - 1)}
											disabled={currentPage === 1}
										>
											Previous
										</button>
									</div>
									{pageNumbers &&
										pageNumbers.map((pageNumber) => (
											<button
												disabled={currentPage === pageNumber}
												key={pageNumber}
												onClick={() => handlePageClick(pageNumber)}
											>
												{pageNumber}
											</button>
										))}
									<div className="pagination-prev">
										<button
											onClick={() => setCurrentPage(currentPage + 1)}
											disabled={indexOfLastItem >= articles.length}
										>
											Next
										</button>
									</div>
								</div>
							</Box>
						</Box>
					</CardContent>
				</Card>
			</Box>
		</>
	);
}
