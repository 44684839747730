import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import "../adminComponents/admin.scss";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableRow,
	CircularProgress,
	Button,
	Chip,
	TableHead,
} from "@mui/material";
import BASE_URL from "../../subcomponents/Config.jsx";

const tablevalue = [
	{
		title: "Total Marks",
		value: "totalMarks",
	},
	{
		title: "Total Questions",
		value: "totalQuestions",
	},
	{
		title: "Attempted Questions",
		value: "attemptedQuestions",
	},
];

export default function SubmittedAnswers() {
	const { examId, userId } = useParams();
	const [data, setData] = useState({});
	const [answers, setAnswers] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [cookies] = useCookies(["adminToken"]);
	const nav = useNavigate();
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);
	function Back() {
		nav(-1);
	}
	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/exam/${examId}/user/${userId}/studentAnswers`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${adminToken}`,
					},
				}
			);
			if (response.ok) {
				const apiData = await response.json();
				setData(apiData.data);
				setAnswers(apiData.data.answers);
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};
	return (
		<Box>
			<Button
				onClick={() => Back()}
				variant="outlined"
				color="secondary"
				style={{ marginBottom: "0.2rem" }}
			>
				Back
			</Button>
			<Card variant="outlined">
				<CardContent>
					<Box style={{ display: "flex", justifyContent: "space-between" }}>
						<Typography variant="h3">
							{data && <span>{data.examTitle} </span>}Examinee's Answers
						</Typography>
					</Box>
					<Box
						sx={{
							overflow: {
								xs: "auto",
								sm: "unset",
							},
						}}
					>
						<Table
							aria-label="simple table"
							sx={{
								mt: 3,
							}}
						>
							{!loading && data ? (
								<Box>
									<TableBody>
										{tablevalue &&
											tablevalue.map((table, index) => (
												<TableRow key={index}>
													<TableCell>
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "500",
															}}
														>
															{table.title}
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "500",
															}}
														>
															{data[table.value]}
														</Typography>
													</TableCell>
												</TableRow>
											))}
										{/* changes made by yusuf */}
										<TableRow>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
													}}
												>
													Started Time
												</Typography>
											</TableCell>
											<TableCell>
												<Typography>{data.startedTime}</Typography>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
													}}
												>
													Submitted Time
												</Typography>
											</TableCell>
											<TableCell>
												<Typography>{data.submittedTime}</Typography>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
													}}
												>
													Time Taken
												</Typography>
											</TableCell>
											<TableCell>
												<Typography>{data.timeTaken}</Typography>
											</TableCell>
										</TableRow>
										{/* end of changes */}
										<TableRow>
											<TableCell>
												<Typography color="textSecondary" variant="h5">
													Question Id
												</Typography>
											</TableCell>
											<TableCell>
												<Typography color="textSecondary" variant="h5">
													Question
												</Typography>
											</TableCell>
											<TableCell>
												<Typography color="textSecondary" variant="h5">
													Selected Option
												</Typography>
											</TableCell>
											<TableCell>
												<Typography color="textSecondary" variant="h5">
													Correct Answer
												</Typography>
											</TableCell>
										</TableRow>

										{answers &&
											answers.map((answer) => (
												<TableRow>
													<TableCell>
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "500",
																pl: "0",
																// color: answer.isCorrect
																//   ? "success.main"
																//   : "error.main",
															}}
														>
															{answer.questionId}
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "500",
																pl: "0",
																// color: answer.isCorrect
																//   ? "success.main"
																//   : "error.main",
															}}
														>
															{answer.question}
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "500",
																pl: "0",
																color: answer.isCorrect
																	? "success.main"
																	: "error.main",
															}}
														>
															{answer.selectedOptionValue}
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "500",
																pl: "0",
																color: "success.main",
															}}
														>
															{answer.correctAnswer}
														</Typography>
													</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Box>
							) : (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "200px",
									}}
								>
									<CircularProgress />
								</Box>
							)}
							{error ? (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "200px",
									}}
								>
									{error}
								</Box>
							) : null}
						</Table>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
}
