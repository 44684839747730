import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	CircularProgress,
	Button,
	Chip,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";

export default function AdminList() {
	const [adminList, setAdminList] = useState([]);
	const nav = useNavigate();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [cookies] = useCookies(["adminToken"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/admin/adminList`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${adminToken}`,
				},
			});
			if (response.ok) {
				const apiData = await response.json();
				setAdminList(apiData.adminList);
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const handleDelete = async (id) => {
		setLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/admin/delete/${id}`, {
				method: "DELETE",
				headers: {
					Authorization: `Bearer ${adminToken}`,
				},
			});
			if (response.ok) {
				const apiData = await response.json();
				toast.success(apiData.message);
				setLoading(false);
				getData();
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	function Back() {
		nav(-1);
	}

	return (
		<Box>
			<ToastContainer position="top-center" autoClose={1500} />
			<Button
				onClick={() => Back()}
				variant="outlined"
				color="secondary"
				style={{ marginBottom: "0.2rem", marginLeft: "1.15rem" }}
			>
				Back
			</Button>
			<Card variant="outlined">
				<CardContent>
					<Typography variant="h3">Admin Lists</Typography>
					<Box
						sx={{
							overflow: {
								xs: "auto",
								sm: "unset",
							},
						}}
					>
						<Table
							aria-label="simple table"
							sx={{
								mt: 3,
							}}
						>
							<TableHead>
								<TableRow>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Admin Name
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Admin email
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Admin Roles
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Delete
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{adminList.map((admin, index) => (
									<TableRow key={index}>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{admin.name}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{admin.email}
											</Typography>
										</TableCell>

										<TableCell>
											{admin.roles &&
												admin.roles.map((role) => (
													<Typography
														sx={{
															fontSize: "15px",
															fontWeight: "500",
															paddingLeft: "0",
															whiteSpace: "pre-line",
														}}
													>
														{role}
													</Typography>
												))}
										</TableCell>
										<TableCell align="center">
											<Chip
												sx={{
													pl: "4px",
													pr: "4px",
													backgroundColor: "error.main",
													color: "#fff",
												}}
												size="small"
												label="Delete"
												onClick={() => handleDelete(admin.id)}
											/>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						{loading ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								<CircularProgress />
							</Box>
						) : null}
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
}
