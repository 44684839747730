import React, { useEffect, useState } from "react";
import AdminLogin from "./AdminLogin";
import FullLayout from "./FullLayout";
import { ThemeProvider } from "@mui/material/styles";
import { baseTheme } from "./assets/global/Theme-variable";
import { useCookies } from "react-cookie";

export default function Admin() {
	const [cookies] = useCookies(["adminToken"]);
	const adminToken = cookies.adminToken;
	const theme = baseTheme;

	return (
		<ThemeProvider theme={theme}>
			{adminToken ? <FullLayout /> : <AdminLogin />}
		</ThemeProvider>
	);
}
