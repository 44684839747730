import React, { useState } from "react";
import BASE_URL from "../../subcomponents/Config";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../subcomponents/Context";
import useFormValidation from "../../Hooks/useFormValidation";
import { Box, CircularProgress } from "@mui/material";
import "../../Styles/User/register.scss";
import CourseSelect from "../../admin/adminComponents/Api/CourseSelect";

const initialFormState = {
  firstName: "",
  middleName: "",
  lastName: "",
  fatherName: "",
  grandFatherName: "",
  address: "",
  interestedCourse: "",
  mobileNumber: "",
  email: "",
  password: "",
  confirmPassword: "",
};

const Register = ({ borderColor, borderWidth, borderRadius, minHeight }) => {
  const { setMessage } = useContext(Context);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();

  const {
    formData,
    setFormData,
    setErrors,
    errors,
    handleChange,
    validateForm,
  } = useFormValidation(initialFormState);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    console.log(errors);
    console.log(formData);
    if (Object.keys(validationErrors).length === 0) {
      const data = new FormData();
      data.append("firstName", formData.firstName);
      data.append("middleName", formData.middleName);
      data.append("lastName", formData.lastName);
      data.append("fatherName", formData.fatherName);
      data.append("grandFatherName", formData.grandFatherName);
      data.append("address", formData.address);
      data.append("interestedCourse", formData.interestedCourse);
      data.append("mobileNumber", formData.mobileNumber);
      data.append("email", formData.email);
      data.append("password", formData.password);
      data.append("confirmPassword", formData.confirmPassword);

      setLoading(true);
      try {
        const response = await fetch(`${BASE_URL}/api/v1/auth/register`, {
          method: "POST",
          body: data,
        });
        if (response.ok) {
          const userData = await response.json();
          console.log(userData);
          setMessage(userData.message);

          nav("/login", { state: { from: "/register" } });
          setLoading(false);
        } else {
          const error = await response.json();
          setError(error.message);
          setLoading(false);
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  // const handleBack = () => {
  //   nav(-1);
  // };

  function handleLogin() {
    nav("/login", { state: { from: "/register" } });
  }

  // const handleSelectCourse = (courseId) => {
  // 	setFormData({ ...formData, courseId });

  // 	setErrors((prevErrors) => ({
  // 		...prevErrors,
  // 		courseId: "",
  // 	}));
  // };

  const handleSelectCourse = (courseId) => {
    setFormData((prevState) => ({
      ...prevState,
      interestedCourse: courseId, // Use 'interestedCourse' or 'courseId' based on your API
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      interestedCourse: "", // Clear error on successful selection
    }));
  };

  return (
    <div className="form">
      <h3>Register</h3>
      <div className="register_container">
        <div>
          <form action="" onSubmit={handleSubmit}>
            <div className="uper_part">
              <div>
                <label htmlFor="">First Name</label>
                <br />
                <input
                  type="text"
                  className="registerNeme"
                  placeholder="Enter your first name"
                  onChange={handleChange}
                  name="firstName"
                  value={formData.firstName}
                />
                {errors.firstName && (
                  <span className="error">{errors.firstName}</span>
                )}
              </div>
              <div>
                <label htmlFor="">Middle Name</label>
                <br />
                <input
                  type="text"
                  className="registerNeme"
                  placeholder="Enter your middle name"
                  onChange={handleChange}
                  name="middleName"
                  value={formData.middleName}
                />
                {errors.middleName && (
                  <span className="error">{errors.middleName}</span>
                )}
              </div>
              <div>
                <label htmlFor="">Last Name</label>
                <br />
                <input
                  type="text"
                  className="registerNeme"
                  placeholder="Enter your last name"
                  onChange={handleChange}
                  name="lastName"
                  value={formData.lastName}
                />
                {errors.lastName && (
                  <span className="error">{errors.lastName}</span>
                )}
              </div>
            </div>

            <div className="uper_part1">
              <div>
                <label htmlFor="">Father Name</label>
                <br />
                <input
                  type="text"
                  className="registerNeme"
                  placeholder="Enter your father name"
                  onChange={handleChange}
                  name="fatherName"
                  value={formData.fatherName}
                />
                {errors.fatherName && (
                  <span className="error">{errors.fatherName}</span>
                )}
              </div>
              <div>
                <label htmlFor="">Grand Father Name</label>
                <br />
                <input
                  type="text"
                  className="registerNeme"
                  placeholder="Enter your grand father name"
                  onChange={handleChange}
                  name="grandFatherName"
                  value={formData.grandFatherName}
                />
                {errors.grandFatherName && (
                  <span className="error">{errors.grandFatherName}</span>
                )}
              </div>
            </div>

            <div className="uper_part1">
              <div>
                <label htmlFor="">Address</label>
                <br />
                <input
                  type="text"
                  className="registerNeme"
                  placeholder="Enter your address name"
                  onChange={handleChange}
                  name="address"
                  value={formData.address}
                />
                {errors.address && (
                  <span className="error">{errors.address}</span>
                )}
              </div>

              <div>
                <label htmlFor="">Select Interested Course</label>
                <br />
                <CourseSelect
                  selectedCourseId={formData.interestedCourse}
                  onSelectCourse={handleSelectCourse}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#fd6a02", // Default border color
                        borderWidth: "1px", // Default border width
                        borderRadius: "5px", // Default border radius
                      },
                      "&:hover fieldset": {
                        borderColor: "#136F9A", // Hover border color
                        borderWidth: "2.5px", // Hover border width
                        borderRadius: "20px", // Hover border radius
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#4CAF50", // Focus border color
                        borderRadius: "20px", // Focus border radius
                      },
                    },
                    width: "100%",
                    height: "2.5rem",
                    margin: "1rem 0",
                    fontSize: "0.9rem",
                    border: '1px solid #fd6a02',
                    outline: "none",
                    color: "#000",
                  }}
                />
                {errors.interestedCourse && (
                  <span className="error">{errors.interestedCourse}</span>
                )}
              </div>
            </div>

            <div>
              <div>
                <label htmlFor="">Phone/Mobile</label>
                <br />
                <input
                  type="text"
                  className="envision"
                  placeholder="Enter your phone number"
                  onChange={handleChange}
                  name="mobileNumber"
                  value={formData.mobileNumber}
                />
                {errors.mobileNumber && (
                  <span className="error">{errors.mobileNumber}</span>
                )}
              </div>
              <div>
                <label htmlFor="">Email</label>
                <br />
                <input
                  type="text"
                  className="envision"
                  placeholder="Enter your email address"
                  onChange={handleChange}
                  name="email"
                  value={formData.email}
                />
                {errors.email && <span className="error">{errors.email}</span>}
              </div>

              <div>
                <label htmlFor="">Password</label>
                <br />
                <input
                  type="password"
                  className="envision"
                  placeholder="Enter your password"
                  onChange={handleChange}
                  name="password"
                  value={formData.password}
                />
                {errors.password && (
                  <span className="error">{errors.password}</span>
                )}
              </div>
              <div>
                <label htmlFor="">Confirm Password</label>
                <br />
                <input
                  type="password"
                  className="envision"
                  placeholder="Confirm your password"
                  onChange={handleChange}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                />
                {errors.confirmPassword && (
                  <span className="error">{errors.confirmPassword}</span>
                )}
              </div>
              {error && (
                <p style={{ color: "red", textAlign: "center" }}>{error}</p>
              )}
              <button className="envision" id="mybutton">
                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "10px",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <div>Submit</div>
                )}
              </button>
            </div>
          </form>
          <div className="have_accout">
            Already have an account?
            <button onClick={handleLogin}>Login</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
