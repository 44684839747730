import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../subcomponents/Config";
import {
	TextField,
	Button,
	Typography,
	Container,
	CircularProgress,
	MenuItem,
	InputLabel,
	Select,
	Chip,
	Box,
	OutlinedInput,
} from "@mui/material";
import useFormValidation from "../../Hooks/useFormValidation";
import { toast, ToastContainer } from "react-toastify";

const AdminLoginForm = styled("form")({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	border: "1px solid #ccc",
	padding: "20px",
	borderRadius: "5px",
});

const initialFormState = {
	bannerImageFiles: [],
	bannerUrls: [null, null, null, null],
};

const AddBannerImage = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const nav = useNavigate();
	const [cookies] = useCookies(["adminToken", "adminRoles"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	const {
		formData,
		setFormData,
		setErrors,
		errors,
		handleFilesChange,
		handleChange,
		validateForm,
	} = useFormValidation(initialFormState);

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		const validationErrors = validateForm(formData);
		console.log(formData);

		if (Object.keys(validationErrors).length === 0) {
			const data = new FormData();
			formData.bannerImageFiles.forEach((file) => {
				data.append("bannerImageFiles", file);
			});
			formData.bannerUrls.forEach((url) => {
				data.append("bannerUrls", url);
			});
			setLoading(true);
			try {
				const response = await fetch(`${BASE_URL}/api/v1/banner/addImages`, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${adminToken}`,
					},
					body: data,
				});

				if (response.ok) {
					const userData = await response.json();
					toast.success(userData.message);
					setFormData(initialFormState);
					setError("");
					setLoading(false);
				} else {
					const error = await response.json();
					setError(error.message || "Registration failed");
					setLoading(false);
				}
			} catch (error) {
				console.log(error.message);
			}
		} else {
			setErrors(validationErrors);
		}
	};

	return (
		<>
			<ToastContainer position="top-center" autoClose="2000" />
			<Container maxWidth="xs">
				<AdminLoginForm onSubmit={handleSubmit}>
					<Typography variant="h2" gutterBottom>
						Add New Banner Image
					</Typography>
					{error && (
						<Typography variant="subtitle2" color="error" gutterBottom>
							{error}!!
						</Typography>
					)}
					<Box width="100%" marginBottom="20px">
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								border: "1px solid #ccc",
								padding: "10px",
								borderRadius: "5px",
							}}
						>
							<Typography variant="body1" sx={{ flexGrow: 1, color: "gray" }}>
								Select Images:
							</Typography>
							<input
								id="file-upload"
								type="file"
								onChange={handleFilesChange}
								accept="image/*"
								multiple
								style={{ display: "none" }} // Hides the default file input UI
								name="bannerImageFiles"
							/>
							<label htmlFor="file-upload">
								<Button variant="outlined" component="span">
									Select
								</Button>
							</label>
						</Box>
						{formData.bannerImageFiles.length > 0 && (
							<Box mt={2}>
								{formData.bannerImageFiles.map((image, index) => (
									<Typography
										key={index}
										variant="body2"
										sx={{ marginTop: "10px", color: "gray", margin: "2px" }}
									>
										{image.name}
									</Typography>
								))}
							</Box>
						)}
						{errors.bannerImageFiles && (
							<div style={{ color: "red" }}>{errors.bannerImageFiles}</div>
						)}
					</Box>
					{formData.bannerImageFiles &&
						formData.bannerImageFiles.map((file, index) => (
							<Box key={index} width="100%" marginBottom="20px">
								<TextField
									label={`Banner Url (for ${file.name})`}
									data={index}
									variant="outlined"
									name={`bannerUrls-${index}`}
									fullWidth
									value={formData.bannerUrls[index] || ""}
									onChange={(e) => {
										const updatedUrls = [...formData.bannerUrls];
										updatedUrls[index] = e.target.value;
										setFormData({ ...formData, bannerUrls: updatedUrls });
									}}
								/>
							</Box>
						))}
					<Button type="submit" variant="contained" color="secondary">
						{loading ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "10px",
								}}
							>
								<CircularProgress />
							</Box>
						) : (
							<Box>Add Banner Image</Box>
						)}
					</Button>
				</AdminLoginForm>
			</Container>
		</>
	);
};

export default AddBannerImage;
