import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Path from "./Path";
import 'react-toastify/dist/ReactToastify.css';
import { Context } from './subcomponents/Context';
import MessageIcon from './pages/components/MessageIcon';

const App = () => {
  const [message, setMessage] = useState("");
  const location = useLocation();

  return (
    <div className='App'>
      <Context.Provider
        value={{
          message,
          setMessage,
        }}
      >
        {location.pathname !== '/contact' && !location.pathname.startsWith('/admin') && (
          <MessageIcon />
        )}

        <Path />
      </Context.Provider>
    </div>
  );
};

export default App;

