import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Chip,
	CircularProgress,
	Select,
	MenuItem,
	Button,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";

export default function ExamineesList() {
	const { id } = useParams();
	const [users, setUsers] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(8);
	const [totalPages, setTotalPages] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const nav = useNavigate();
	const [cookies] = useCookies(["adminToken"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	const [examName, setExamName] = useState("");
	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	useEffect(() => {
		getData();
	}, [currentPage]);

	function handleSubmittedAnswers(userId) {
		nav(
			`/admin/EXAM_DEPARTMENT/exam/${id}/examinees/${userId}/submittedAnswers`
		);
	}
	function Back() {
		nav(-1);
	}

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/exam/${id}/userList`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${adminToken}`,
				},
			});
			if (response.ok) {
				const apiData = await response.json();
				setUsers(apiData.userList);
				setExamName(apiData.examName);
				setTotalPages(Math.ceil(apiData.userList.length / itemsPerPage));
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentData = users.slice(indexOfFirstItem, indexOfLastItem);

	function handlePageClick(pageNumber) {
		setCurrentPage(pageNumber);
	}

	const pageNumbers = [];
	for (let i = 1; i <= totalPages; i++) {
		pageNumbers.push(i);
	}

	return (
		<Box>
			<ToastContainer position="top-center" autoClose="2000" />
			<Button
				onClick={() => Back()}
				variant="outlined"
				color="secondary"
				style={{ marginBottom: "0.2rem", marginLeft: "1rem" }}
			>
				Back
			</Button>
			<Card variant="outlined">
				<CardContent>
					<Typography variant="h3">
						{examName && <span>{examName} </span>}Examinees List
					</Typography>
					<Box
						sx={{
							overflow: {
								xs: "auto",
								sm: "unset",
							},
						}}
					>
						<Table
							aria-label="simple table"
							sx={{
								mt: 3,
							}}
						>
							<TableHead>
								<TableRow>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Name
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Email
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Obtained Marks
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography color="textSecondary" variant="h5">
											Submitted Answers
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{currentData &&
									currentData.map((user) => (
										<TableRow key={user.userId}>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
													}}
												>
													{user.firstName} {user.lastName}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
													}}
												>
													{user.email}
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
													}}
												>
													{user.obtainedMarks}
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Chip
													sx={{
														pl: "4px",
														pr: "4px",
														backgroundColor: "success.main",
														color: "#fff",
													}}
													size="small"
													label="Submitted Answers"
													onClick={() => handleSubmittedAnswers(user.userId)}
												/>
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
						{loading ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								<CircularProgress />
							</Box>
						) : null}
						{error ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								{error}
							</Box>
						) : null}
						<Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
							<div className="pagination">
								<div className="pagination-prev">
									<button
										onClick={() => setCurrentPage(currentPage - 1)}
										disabled={currentPage === 1}
									>
										Previous
									</button>
								</div>
								{pageNumbers &&
									pageNumbers.map((pageNumber) => (
										<button
											disabled={currentPage === pageNumber}
											key={pageNumber}
											onClick={() => handlePageClick(pageNumber)}
										>
											{pageNumber}
										</button>
									))}
								<div className="pagination-prev">
									<button
										onClick={() => setCurrentPage(currentPage + 1)}
										disabled={indexOfLastItem >= users.length}
									>
										Next
									</button>
								</div>
							</div>
						</Box>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
}
