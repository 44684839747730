import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Link, NavLink, useParams } from "react-router-dom";
import {
	Box,
	Drawer,
	useMediaQuery,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Badge,
} from "@mui/material";
import { SidebarWidth } from "../../assets/global/Theme-variable";
import getMenuItems from "./data";
import LogoText from "../Logo/LogoText";
import BASE_URL from "../../../subcomponents/Config";
import { useCookies } from "react-cookie";

const Sidebar = (props) => {
	const [open, setOpen] = useState(true);
	const { pathname } = useLocation();
	const pathDirect = pathname;
	const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
	const { role } = useParams();
	const Menuitems = getMenuItems(role);
	const [loading, setLoading] = useState(false);
	const [count, setCount] = useState(null);
	const [messageCount, setMessageCount] = useState(null);
	const [cookies] = useCookies(["adminToken"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");

	const handleClick = (index) => {
		if (open === index) {
			setOpen((prevopen) => !prevopen);
		} else {
			setOpen(index);
		}
	};
	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	const getRequestCount = async () => {
		setLoading(true);
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/enrollmentRequest/unseenCount`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${adminToken}`,
					},
				}
			);
			if (response.ok) {
				const apiData = await response.json();
				setCount(apiData.count);
				setLoading(false);
			} else {
				const error = await response.json();
				console.log(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			setLoading(false);
		}
	};

	const getMessageCount = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/contact/unseenCount`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${adminToken}`,
				},
			});
			if (response.ok) {
				const apiData = await response.json();
				setMessageCount(apiData.count);
				setLoading(false);
			} else {
				const error = await response.json();
				console.log(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (pathname.includes("/admin/ADMISSION_DEPARTMENT")) {
			getRequestCount();
			if (pathname === "/admin/ADMISSION_DEPARTMENT/user/enrollRequest/list") {
				setCount(0);
			}
		} else if (pathname.includes("/admin/SUPER_ADMIN")) {
			getMessageCount();
			if (pathname === "/admin/SUPER_ADMIN/viewMessages") {
				setMessageCount(0);
			}
		}
	}, [pathname]);

	const SidebarContent = (
		<Box sx={{ p: 3, height: "calc(100vh - 40px)" }}>
			<Link to="/admin" style={{ textDecoration: "none" }}>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<LogoText />
				</Box>
			</Link>

			<Box>
				<List sx={{ mt: 4 }}>
					{Menuitems.map((item, index) => (
						<List component="li" disablePadding key={item.title}>
							<ListItem
								onClick={() => handleClick(index)}
								button
								component={NavLink}
								to={item.href}
								selected={pathDirect === item.href}
								sx={{
									marginTop: "5px",
									"&:hover": {
										backgroundColor: "rgba(0, 0, 0, 0.08)",
										color: "black",
									},
									"&.active": {
										backgroundColor: "#fd6a02",
										color: "white",
									},
								}}
							>
								<ListItemIcon>
									<item.icon width="20" height="20" />
								</ListItemIcon>

								<ListItemText>
									{item.title === "Enroll Request List" &&
									count > 0 &&
									pathDirect !== item.href ? (
										<Badge badgeContent={count} color="primary">
											{item.title}
										</Badge>
									) : item.title === "View User Messages" &&
									  messageCount > 0 &&
									  pathDirect !== item.href ? (
										<Badge badgeContent={messageCount} color="primary">
											{item.title}
										</Badge>
									) : (
										item.title
									)}
								</ListItemText>
							</ListItem>
						</List>
					))}
				</List>
			</Box>
		</Box>
	);

	if (lgUp) {
		return (
			<Drawer
				anchor="left"
				open={props.isSidebarOpen}
				variant="persistent"
				PaperProps={{
					sx: {
						width: SidebarWidth,
					},
				}}
			>
				{SidebarContent}
			</Drawer>
		);
	}
	return (
		<Drawer
			anchor="left"
			open={props.isMobileSidebarOpen}
			onClose={props.onSidebarClose}
			PaperProps={{
				sx: {
					width: SidebarWidth,
				},
			}}
			variant="temporary"
		>
			{SidebarContent}
		</Drawer>
	);
};

export default Sidebar;
