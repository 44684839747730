import React from "react";
import {
	FacebookShareButton,
	// EmailShareButton,
	LinkedinShareButton,
	TwitterShareButton,
} from "react-share";
import WEBSITE_URL from "../../subcomponents/Website";
import {
	FacebookIcon,
	// EmailIcon,
	LinkedinIcon,
	TwitterIcon,
} from "react-share";

export default function SocialMediaShare({ detailsId }) {
	return (
		<div>
			<FacebookShareButton url={`${WEBSITE_URL}/articlesDetails/${detailsId}`}>
				<FacebookIcon size={35} />
			</FacebookShareButton>
			<TwitterShareButton url={`${WEBSITE_URL}/articlesDetails/${detailsId}`}>
				<TwitterIcon size={35} />
			</TwitterShareButton>
			<LinkedinShareButton url={`${WEBSITE_URL}/articlesDetails/${detailsId}`}>
				<LinkedinIcon size={35} />
			</LinkedinShareButton>
			{/* <EmailShareButton
				url={`${WEBSITE_URL}/articlesDetails/${localStorage.getItem(
					"detailsid"
				)}`}
			>
				<EmailIcon size={24} />
			</EmailShareButton> */}
		</div>
	);
}
