import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';

const MessageIcon = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contact');
  };

  return (
    <div style={styles.floatingButton}>
      <IconButton color="primary" onClick={handleClick}
       sx={{
          backgroundColor: '#1976d2',
          '&:hover': {
            backgroundColor: '#115293',
          },
          color: '#fff',
          padding: '10px',
          borderRadius: '50%',
        }}
      >
        <ChatIcon />
      </IconButton>
    </div>
  );
};

const styles = {
  floatingButton: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    zIndex: 1000,
  },
};

export default MessageIcon;
