import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import "../adminComponents/admin.scss";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
  Chip,
  TableHead,
} from "@mui/material";
import BASE_URL from "../../subcomponents/Config.jsx";
import { toast, ToastContainer } from "react-toastify";
import ConfirmDialog from "../adminComponents/DialogBox/Confirm.js";

export default function TestimonialList() {
  const [testimoniallist, setTestimonialList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cookies] = useCookies(["adminToken"]);
  const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const nav = useNavigate();

  const handleOpenDialog = (id) => {
    setDeleteId(id);
    console.log(id);
    setDialogOpen(true);
  };
  const handleEdit = (id) => {
    nav(`/admin/:role/testimonial-list/edit/${id}`);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    setAdminToken(cookies.adminToken || "");
  }, [cookies]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/api/v1/testimonial/list`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      });
      if (response.ok) {
        const apiData = await response.json();
        setTestimonialList(apiData.testimonials);
        setLoading(false);
      } else {
        const error = await response.json();
        setError(error.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${BASE_URL}/api/v1/testimonial/delete/${deleteId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        }
      );
      if (response.ok) {
        const apiData = await response.json();
        setLoading(false);
        toast.success(apiData.message);
        handleCloseDialog();
        getData();
        setDeleteId(null);
      } else {
        const error = await response.json();
        toast.error(error.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Box>
      <ToastContainer position="top-center" autoClose="2000" />
      <Card variant="outlined">
        <CardContent>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h3">Teacher List</Typography>
          </Box>
          <Box
            sx={{
              overflow: {
                xs: "auto",
                sm: "unset",
              },
            }}
          >
            <Table
              aria-label="simple table"
              sx={{
                mt: 3,
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography color="textSecondary" variant="h5">
                      Image
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="h5">
                      Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="h5">
                      Testimonial
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography color="textSecondary" variant="h5">
                      Edit
                    </Typography>
                  </TableCell>

                  <TableCell align="center">
                    <Typography color="textSecondary" variant="h5">
                      Delete
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              {!loading && testimoniallist ? (
                <>
                  <TableBody>
                    {testimoniallist &&
                      testimoniallist.map((testimonial) => (
                        <TableRow key={testimonial.id}>
                          <TableCell>
                            <Typography
                              sx={{
                                fontSize: "15px",
                                fontWeight: "500",
                              }}
                            >
                              <img
                                src={testimonial.imageUrl}
                                alt=""
                                style={{ height: "10rem", width: "auto" }}
                              />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                fontSize: "15px",
                                fontWeight: "500",
                                paddingLeft: "0",
                              }}
                            >
                              {testimonial.name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                fontSize: "15px",
                                fontWeight: "500",
                                paddingLeft: "0",
                              }}
                            >
                              {testimonial.testimonial}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Chip
                              sx={{
                                backgroundColor: "secondary.main",
                                color: "#fff",
                              }}
                              size="medium"
                              label="Edit"
                              onClick={() => handleEdit(testimonial.id)}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <Chip
                              sx={{
                                backgroundColor: "error.main",
                                color: "#fff",
                              }}
                              size="medium"
                              label="Delete"
                              onClick={() => handleOpenDialog(testimonial.id)}
                            />
                          </TableCell>
                          <ConfirmDialog
                            open={dialogOpen}
                            title="Confirm Action"
                            content="Are you sure you want to delete this banner image?"
                            onClose={() => handleCloseDialog()}
                            onConfirm={() => handleDelete()}
                          />
                        </TableRow>
                      ))}
                  </TableBody>
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              {error && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  {error}
                </Box>
              )}
            </Table>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
