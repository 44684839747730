import React, { useEffect, useState } from "react";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import { Box, CircularProgress } from "@mui/material";
import Loading from "../components/Loading";
import useLoading from "../../Hooks/useLoading";
import "../../Styles/Components/Testimonial.scss";


const Testimonial = () => {
	const { loading, showLoading, hideLoading } = useLoading();

	const [testimonials, setTestimonials] = useState([]);

	const [cookies] = useCookies(["userToken", "userId", "userRole"]);
	const [token, setToken] = useState(cookies.userToken || "");


	useEffect(() => {
		setToken(cookies.userToken || "");
	}, [cookies]);

	useEffect(() => {
		Testimonial();
	}, []);

	const Testimonial = async () => {
		showLoading();
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/testimonial/list`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
						mode: "cors",
					},
				}
			);
			if (response.ok) {
				const getApi = await response.json();
				setTestimonials(getApi.testimonials);
				hideLoading();
			} else {
				const error = await response.json();
				console.log(error);
				hideLoading();
			}
		} catch (error) {
			console.error("Error fetching data.", error);
		}
	};

	return (
		<div className='main_testimonial'>
			<div className="heading">Our Testimonials</div>
			<div className="container">
				{loading && <Loading />}
				{!loading && testimonials && testimonials.length > 0 && (
					testimonials.map((data, index) => (
						<div className="card" key={data.id}>
							<div className="useImage">
								<img src={data.imageUrl} alt={data.name} />
							</div>
							<div className="userName">{data.name}</div>
							<div className="userDetails">{data.testimonial}</div>
						</div>
					))
				)}
			</div>
		</div>
	)
}

export default Testimonial
