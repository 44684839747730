import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useCookies } from "react-cookie";
import BASE_URL from "../../subcomponents/Config";
import {
	TextField,
	Button,
	Typography,
	Container,
	Box,
	CircularProgress,
} from "@mui/material";
import useFormValidation from "../../Hooks/useFormValidation";
import { toast, ToastContainer } from "react-toastify";

const AdminLoginForm = styled("form")({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	border: "1px solid #ccc",
	padding: "20px",
	borderRadius: "5px",
});

const initialFormState = {
	title: "",
	file: null,
};

const AddCourseCategory = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [cookies] = useCookies(["adminToken", "adminRoles"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	const {
		formData,
		setFormData,
		handleFileChange,
		setErrors,
		errors,
		handleChange,
		validateForm,
	} = useFormValidation(initialFormState);

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		const validationErrors = validateForm(formData);

		if (Object.keys(validationErrors).length === 0) {
			const data = new FormData();
			data.append("title", formData.title);
			data.append("file", formData.file);
			setLoading(true);
			try {
				const response = await fetch(
					`${BASE_URL}/api/v1/courseCategory/addCategory`,
					{
						method: "POST",
						headers: {
							Authorization: `Bearer ${adminToken}`,
						},
						body: data,
					}
				);

				if (response.ok) {
					const courseData = await response.json();
					setLoading(false);
					toast.success(courseData.message);
					setFormData(initialFormState);
				} else {
					const error = await response.json();
					setError(error.message);
					setLoading(false);
				}
			} catch (error) {
				console.log(error.message);
			}
		} else {
			setErrors(validationErrors);
		}
	};

	return (
		<>
			<ToastContainer position="top-center" autoClose="2000" />
			<Container maxWidth="xs">
				<AdminLoginForm onSubmit={handleSubmit}>
					<Typography variant="h2" gutterBottom>
						Add New Course Category
					</Typography>
					{error && (
						<Typography variant="subtitle2" color="error" gutterBottom>
							{error}!!
						</Typography>
					)}
					<Box width="100%" marginBottom="20px">
						<TextField
							label="Category Title"
							variant="outlined"
							name="title"
							fullWidth
							value={formData.title}
							onChange={handleChange}
						/>
						{errors.title && (
							<span style={{ color: "red" }}>{errors.title}</span>
						)}
					</Box>
					<Box width="100%" marginBottom="20px">
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								border: "1px solid #ccc",
								padding: "10px",
								borderRadius: "5px",
							}}
						>
							<Typography variant="body1" sx={{ flexGrow: 1, color: "gray" }}>
								Select Image:
							</Typography>
							<input
								id="file-upload"
								type="file"
								onChange={handleFileChange}
								accept="image/*"
								style={{ display: "none" }} // Hides the default file input UI
								name="file"
							/>
							{formData.file && (
								<Typography
									variant="body2"
									sx={{ marginTop: "10px", color: "gray", margin: "2px" }}
								>
									{formData.file.name}
								</Typography>
							)}
							<label htmlFor="file-upload">
								<Button variant="outlined" component="span">
									Select
								</Button>
							</label>
						</Box>

						{errors.file && (
							<span
								style={{
									color: "red",
								}}
							>
								{errors.file}
							</span>
						)}
					</Box>
					<Button type="submit" variant="contained" color="secondary">
						{loading ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "10px",
								}}
							>
								<CircularProgress />
							</Box>
						) : (
							<Box >Add Course Category</Box>
						)}
					</Button>
				</AdminLoginForm>
			</Container>
		</>
	);
};

export default AddCourseCategory;
