import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useCookies } from "react-cookie";
import BASE_URL from "../../subcomponents/Config";
import {
	TextField,
	Button,
	Typography,
	Container,
	Box,
	OutlinedInput,
	Select,
	CircularProgress,
	MenuItem,
} from "@mui/material";
import useFormValidation from "../../Hooks/useFormValidation";
import { toast, ToastContainer } from "react-toastify";
import TeacherSelect from "../adminComponents/Api/TeacherSelect";

const AdminLoginForm = styled("form")({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	border: "1px solid #ccc",
	padding: "20px",
	borderRadius: "5px",
});

const initialFormState = {
	title: "",
	details: "",
	meetingLink: "",
	information: "",
	categoryTitle: "",
	file: null,
	demoVideo: null,
	syllabus: null,
	courseFee: 0,
	weeklyExamDate: null,
	teacherIds: [],
};

const UploadCourse = () => {
	const [loading, setLoading] = useState(false);
	const [categories, setCategories] = useState([]);
	const [error, setError] = useState("");
	const [cookies] = useCookies(["adminToken", "adminRoles"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	const {
		formData,
		setFormData,
		handleFileChange,
		setErrors,
		errors,
		handleChange,
		validateForm,
	} = useFormValidation(initialFormState);

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/courseCategory/categories`,
				{
					method: "GET",
				}
			);
			if (response.ok) {
				const data = await response.json();
				setCategories(data.Categories);
				setLoading(false);
			} else {
				throw new Error("Failed to fetch roles");
			}
		} catch (error) {
			console.error("Error fetching roles:", error);
			throw error;
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const validationErrors = validateForm(formData);

		if (Object.keys(validationErrors).length === 0) {
			const data = new FormData();
			data.append("title", formData.title);
			data.append("details", formData.details);
			data.append("information", formData.information);
			data.append("categoryTitle", formData.categoryTitle);
			data.append("meetingLink", formData.meetingLink);
			data.append("file", formData.file);
			data.append("demoVideo", formData.demoVideo);
			data.append("syllabus", formData.syllabus);
			data.append("courseFee", formData.courseFee);
			data.append("weeklyExamDate", formData.weeklyExamDate);
			formData.teacherIds.forEach((teacher) => {
				data.append("teacherIds", teacher);
			});
			setLoading(true);
			try {
				const response = await fetch(`${BASE_URL}/api/v1/course/uploadCourse`, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${adminToken}`,
					},
					body: data,
				});

				if (response.ok) {
					const userData = await response.json();
					setLoading(false);
					toast.success(userData.message);
					setFormData(initialFormState);
				} else {
					const error = await response.json();
					setError(error.message);
					setLoading(false);
				}
			} catch (error) {
				console.log(error.message);
			}
		} else {
			setErrors(validationErrors);
		}
	};

	return (
		<>
			<ToastContainer position="top-center" autoClose="2000" />
			<Container maxWidth="xs">
				<AdminLoginForm onSubmit={handleSubmit}>
					<Typography variant="h2" gutterBottom>
						Add New Course
					</Typography>
					{error && (
						<Typography variant="subtitle2" color="error" gutterBottom>
							{error}!!
						</Typography>
					)}
					<Box width="100%" marginBottom="20px">
						<TextField
							label="Course Title"
							variant="outlined"
							name="title"
							fullWidth
							value={formData.title}
							onChange={handleChange}
						/>
						{errors.title && (
							<span style={{ color: "red" }}>{errors.title}</span>
						)}
					</Box>
					<Box width="100%" marginBottom="20px">
						<TextField
							label="Course Description"
							variant="outlined"
							name="details"
							fullWidth
							multiline
							value={formData.details}
							onChange={handleChange}
						/>
						{errors.details && (
							<span style={{ color: "red" }}>{errors.details}</span>
						)}
					</Box>
					<Box width="100%" marginBottom="20px">
						<TextField
							label="Information"
							variant="outlined"
							name="information"
							fullWidth
							multiline
							value={formData.information}
							onChange={handleChange}
						/>
						{errors.information && (
							<span style={{ color: "red" }}>{errors.information}</span>
						)}
					</Box>
					<div style={{ display: "flex" }}>
						<Box width="100%" marginBottom="20px">
							<TextField
								label="Meeting Link"
								name="meetingLink"
								variant="outlined"
								fullWidth
								value={formData.meetingLink}
								onChange={handleChange}
							/>
							{errors.meetingLink && (
								<span style={{ color: "red" }}>{errors.meetingLink}</span>
							)}
						</Box>
						<Box width="100%" margin="0 0 20px 20px">
							<Select
								labelId="multiple-select-label"
								name="categoryTitle"
								value={formData.categoryTitle}
								onChange={handleChange}
								displayEmpty
								input={
									<OutlinedInput
										id="select-multiple-chip"
										label="categoryTitle"
									/>
								}
								renderValue={(selected) => {
									if (selected.length === 0) {
										return <em>--Select Category--</em>;
									}
									return selected;
								}}
							>
								{categories &&
									categories.map((category) => (
										<MenuItem key={category.categoryId} value={category.title}>
											{category.title}
										</MenuItem>
									))}
							</Select>
							{errors.categoryTitle && (
								<span style={{ color: "red" }}>{errors.categoryTitle}</span>
							)}
						</Box>
					</div>
					{/* chages for teahcer selection */}
					<Box width="100%" marginBottom="20px">
						<TeacherSelect
							selectedTeacherId={formData.teacherIds}
							onSelectTeacher={handleChange}
						/>
					</Box>
					<Box width="100%" marginBottom="20px">
						<TextField
							label="Weekly Exam Date"
							variant="outlined"
							name="weeklyExamDate"
							fullWidth
							type="date"
							onChange={handleChange}
							InputLabelProps={{
								shrink: true,
							}}
						/>
						{errors.weeklyExamDate && (
							<span style={{ color: "red" }}>{errors.weeklyExamDate}</span>
						)}
					</Box>
					<Box width="100%" marginBottom="20px">
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								border: "1px solid #ccc",
								padding: "10px",
								borderRadius: "5px",
							}}
						>
							<Typography variant="body1" sx={{ flexGrow: 1, color: "gray" }}>
								Select Image:
							</Typography>
							<input
								id="file-upload"
								type="file"
								onChange={handleFileChange}
								accept="image/*"
								style={{ display: "none" }} // Hides the default file input UI
								name="file"
							/>
							{formData.file && (
								<Typography
									variant="body2"
									sx={{ marginTop: "10px", color: "gray", margin: "2px" }}
								>
									{formData.file.name}
								</Typography>
							)}
							<label htmlFor="file-upload">
								<Button variant="outlined" component="span">
									Select
								</Button>
							</label>
						</Box>

						{errors.file && <span style={{ color: "red" }}>{errors.file}</span>}
					</Box>
					<Box width="100%" marginBottom="20px">
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								border: "1px solid #ccc",
								padding: "10px",
								borderRadius: "5px",
							}}
						>
							<Typography variant="body1" sx={{ flexGrow: 1, color: "gray" }}>
								Select Demo Video:
							</Typography>
							<input
								id="demoVideo-upload"
								type="file"
								onChange={handleFileChange}
								accept="video/*"
								style={{ display: "none" }} // Hides the default file input UI
								name="demoVideo"
							/>
							{formData.demoVideo && (
								<Typography
									variant="body2"
									sx={{ marginTop: "10px", color: "gray", margin: "2px" }}
								>
									{formData.demoVideo.name}
								</Typography>
							)}
							<label htmlFor="demoVideo-upload">
								<Button variant="outlined" component="span">
									Select
								</Button>
							</label>
						</Box>

						{errors.demoVideo && (
							<span style={{ color: "red" }}>{errors.demoVideo}</span>
						)}
					</Box>

					<Box width="100%" marginBottom="20px">
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								border: "1px solid #ccc",
								padding: "10px",
								borderRadius: "5px",
							}}
						>
							<Typography variant="body1" sx={{ flexGrow: 1, color: "gray" }}>
								Select Syllabus PDF:
							</Typography>
							<input
								id="syllabus-upload"
								type="file"
								onChange={handleFileChange}
								accept="application/pdf"
								style={{ display: "none" }} // Hides the default file input UI
								name="syllabus"
							/>
							{formData.syllabus && (
								<Typography
									variant="body2"
									sx={{ marginTop: "10px", color: "gray", margin: "2px" }}
								>
									{formData.syllabus.name}
								</Typography>
							)}
							<label htmlFor="syllabus-upload">
								<Button variant="outlined" component="span">
									Select
								</Button>
							</label>
						</Box>

						{errors.syllabus && (
							<span style={{ color: "red" }}>{errors.syllabus}</span>
						)}
					</Box>
					<Box width="100%" marginBottom="20px">
						<TextField
							label="Course Fee"
							name="courseFee"
							type="number"
							variant="outlined"
							fullWidth
							value={formData.courseFee}
							onChange={handleChange}
						/>
						{errors.courseFee && (
							<span style={{ color: "red" }}>{errors.courseFee}</span>
						)}
					</Box>
					<Button type="submit" variant="contained" color="secondary">
						{loading ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "10px",
								}}
							>
								<CircularProgress />
							</Box>
						) : (
							<Box>Add Course</Box>
						)}
					</Button>
				</AdminLoginForm>
			</Container>
		</>
	);
};

export default UploadCourse;
