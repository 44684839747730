import React, { useEffect, useState } from "react";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import { Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../../Styles/User/ViewProfile.scss";

const ViewProfile = () => {
	const nav = useNavigate();
	const [loading, setLoading] = useState(false);
	const [user, setUser] = useState([]);

	const [cookies] = useCookies(["userToken", "userId"]);
	const [token, setToken] = useState(cookies.userToken || "");
	const [id, setId] = useState(cookies.userId || "");
	useEffect(() => {
		setToken(cookies.userToken || "");
		setId(cookies.userId || "");
	}, [cookies]);

	useEffect(() => {
		MyCourses();
	}, []);

	const MyCourses = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/user/${id}`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
					mode: "cors",
				},
			});
			if (response.ok) {
				const getApi = await response.json();
				setUser(getApi.user);
				setLoading(false);
			} else {
				const error = await response.json();
				console.log(error);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data.", error);
		}
	};

	const ChangePassword = () => {
		nav("/changePassword");
	};
	return (
		<>
		<Navbar />
		<main>
			<div className="view_container">
				<div className="outer_div">
					<div className="inner_div">
						{user && (
							<div>
								<div className="chaild_element">
									<h6>Full Name</h6>
									<div className="view_containent">
										{user.firstName} {user.lastName}
									</div>
								</div>
								<div className="chaild_element">
									<h6>Your Phone/Mobile</h6>
									<div className="view_containent">{user.mobileNumber}</div>
								</div>
								<div className="chaild_element">
									<h6>Your email address</h6>
									<div className="view_containent">{user.email}</div>
								</div>
								<div className="chaild_element">
									<button onClick={ChangePassword}>Change Your Password</button>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			{loading ? (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "20px",
					}}
				>
					<CircularProgress />
				</Box>
			) : null}
		</main>
		<Footer />
		</>
	);
};

export default ViewProfile;
