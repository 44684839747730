import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Chip,
	CircularProgress,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import TruncateText from "../adminComponents/Truncate/TruncateText";

export default function VacancyList() {
	const [vacancies, setVacancies] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(8);
	const [totalPages, setTotalPages] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const nav = useNavigate();
	const [cookies] = useCookies(["adminToken"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	useEffect(() => {
		getData();
	}, []);

	// function handleDetails(id) {
	// 	nav(`/admin/MARKETING_DEPARTMENT/vacancy/details/${id}`);
	// }

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/vacancy/vacancyList`, {
				method: "GET",
			});
			if (response.ok) {
				const apiData = await response.json();
				setVacancies(apiData.vacancies);
				setTotalPages(Math.ceil(apiData.vacancies.length / itemsPerPage));
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentData = vacancies.slice(indexOfFirstItem, indexOfLastItem);

	function handlePageClick(pageNumber) {
		setCurrentPage(pageNumber);
	}

	const pageNumbers = [];
	for (let i = 1; i <= totalPages; i++) {
		pageNumbers.push(i);
	}
	const handleDelete = async (id) => {
		setLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/vacancy/delete/${id}`, {
				method: "DELETE",
				headers: {
					Authorization: `Bearer ${adminToken}`,
				},
			});
			if (response.ok) {
				const apiData = await response.json();
				toast.success(apiData.message);
				getData();
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	return (
		<Box>
			<ToastContainer position="top-center" autoClose={2000} />
			<Card variant="outlined">
				<CardContent>
					<Typography variant="h3">Vacancy List</Typography>
					<Box
						sx={{
							overflow: {
								xs: "auto",
								sm: "unset",
							},
						}}
					>
						<Table aria-label="simple table" sx={{ mt: 3, overflowX: "auto" }}>
							<TableHead>
								<TableRow>
									<TableCell align="left">
										<Typography color="textSecondary" variant="h5">
											Job Title
										</Typography>
									</TableCell>

									<TableCell align="left">
										<Typography color="textSecondary" variant="h5">
											Vacancy Added Date
										</Typography>
									</TableCell>
									<TableCell align="left">
										<Typography color="textSecondary" variant="h5">
											Expiration Date
										</Typography>
									</TableCell>

									<TableCell align="left">
										<Typography color="textSecondary" variant="h5">
											File
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography color="textSecondary" variant="h5">
											Delete
										</Typography>
									</TableCell>
									<TableCell align="left">
										<Typography color="textSecondary" variant="h5">
											Vacancy Url
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{currentData.map((vacancy) => (
									<TableRow key={vacancy.vacancyId}>
										<TableCell align="left">
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{vacancy.title}
											</Typography>
										</TableCell>

										<TableCell align="left">
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{vacancy.addedDate}
											</Typography>
										</TableCell>
										<TableCell align="left">
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{vacancy.expirationDate}
											</Typography>
										</TableCell>

										<TableCell align="left">
											<NavLink
												to={vacancy.vacancyPictureUrl}
												target="_blank"
												style={{ textDecoration: "none", color: "black" }}
											>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
														paddingLeft: "0",
													}}
												>
													<TruncateText text={vacancy.picture} maxLength={25} />
												</Typography>
											</NavLink>
										</TableCell>
										<TableCell align="center" sx={{ minWidth: "100px" }}>
											<Chip
												sx={{
													pl: "4px",
													pr: "4px",
													backgroundColor: "error.main",
													color: "#fff",
												}}
												size="small"
												label="Delete"
												onClick={() => handleDelete(vacancy.vacancyId)}
											/>
										</TableCell>
										<TableCell align="left">
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{vacancy.vacancyUrl && (
													<TruncateText
														text={vacancy.vacancyUrl}
														maxLength={30}
													/>
												)}
											</Typography>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>

						{loading ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								<CircularProgress />
							</Box>
						) : null}
						{error ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								{error}
							</Box>
						) : null}
						<Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
							<div className="pagination">
								<div className="pagination-prev">
									<button
										onClick={() => setCurrentPage(currentPage - 1)}
										disabled={currentPage === 1}
									>
										Previous
									</button>
								</div>
								{pageNumbers &&
									pageNumbers.map((pageNumber) => (
										<button
											disabled={currentPage === pageNumber}
											key={pageNumber}
											onClick={() => handlePageClick(pageNumber)}
										>
											{pageNumber}
										</button>
									))}
								<div className="pagination-prev">
									<button
										onClick={() => setCurrentPage(currentPage + 1)}
										disabled={indexOfLastItem >= vacancies.length}
									>
										Next
									</button>
								</div>
							</div>
						</Box>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
}
