import React, { useEffect, useState } from "react";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { SlArrowLeft } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import "../../Styles/Articles/articles.scss";
import TimeAgo from "../components/TimeAgo";
import SearchBar from "../components/Search";
import Loading from "../components/Loading";
import useLoading from "../../Hooks/useLoading";
import ErrorImage from "../components/Images/errorImage.png";

const Article = () => {
	const nav = useNavigate();
	const { loading, showLoading, hideLoading } = useLoading();
	const [searchError, setSearchError] = useState("");
	const [articles, setArticles] = useState([]);
	const [featuredArticles, setFeaturedArticles] = useState();
	const [featuredDetails, setFeaturedDetails] = useState(false);
	const [error, setError] = useState("");
	const [featuredError, setFeaturedError] = useState("");
	const [cookies] = useCookies(["userToken"]);
	const [token, setToken] = useState(cookies.userToken || "");
	useEffect(() => {
		setToken(cookies.userToken || "");
	}, [cookies]);

	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [itemsPerPage] = useState(5);

	useEffect(() => {
		Articles();
		window.scrollTo(0, 0);
	}, [currentPage]);

	const Articles = async () => {
		showLoading();
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/news/articles?page=${currentPage}&size=${itemsPerPage}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
						mode: "cors",
					},
				}
			);
			if (response.ok) {
				const getApi = await response.json();
				setArticles(getApi.articles);
				setTotalPages(getApi.totalPages);
				hideLoading();
			} else {
				const error = await response.json();
				setError(error.message);
				hideLoading();
			}
		} catch (error) {
			console.error("Error fetching data.", error);
		}
	};

	function handlePageClick(pageNumber) {
		setCurrentPage(pageNumber);
		window.scrollTo(0, 0);
	}

	const pageNumbers = [];
	for (let i = 1; i <= totalPages; i++) {
		pageNumbers.push(i);
	}

	const ShowDetailsArticles = (id) => {
		nav(`/article/details/${id}`);
		window.scroll(0, 0);
	};

	useEffect(() => {
		featuredArticle();
	}, []);

	const featuredArticle = async () => {
		showLoading();
		try {
			const response = await fetch(`${BASE_URL}/api/v1/news/featured`, {
				method: "GET",
			});
			if (response.ok) {
				const apiData = await response.json();
				setFeaturedArticles(apiData.newsArticle);
				hideLoading();
			} else {
				const error = await response.json();
				setFeaturedError(error.message);
			}
		} catch (error) {
			console.log("error fetching data:", error.message);
		}
	};
	// const handleSearch = async (searchTerm) => {
	// 	if (searchTerm === "") {
	// 		Articles();
	// 	} else {
	// 		try {
	// 			const res = await fetch(
	// 				`${BASE_URL}/api/v1/news/search?keyword=${searchTerm}`,
	// 				{
	// 					method: "GET",
	// 					headers: {
	// 						"Content-Type": "application/json",
	// 					},
	// 				}
	// 			);
	// 			if (!res.ok) {
	// 				const error = await res.json();
	// 				setSearchError(error.message);
	// 				console.log(error);
	// 			} else {
	// 				const data = await res.json();
	// 				setArticles(data);
	// 			}
	// 		} catch (error) {
	// 			console.log(error.message);
	// 		}
	// 	}
	// };

	return (
		<>
			<Navbar />
			{loading ? <Loading /> : null}
			{!loading && (
				<main>
					<div className="articles">
						{/* <div className="search_header">
						<div>
							<SearchBar onSearch={handleSearch} />
							{searchError && (
								<div style={{ color: "red", fontSize: "0.8rem" }}>
									{searchError}
								</div>
							)}
						</div>
						<h3>Articles</h3>
					</div> */}
						<h3>Articles</h3>
						<hr />
						<div className="article_main">
							{featuredArticles ? (
								<div
									className="article_right"
									onClick={() => ShowDetailsArticles(featuredArticles.id)}
									onMouseEnter={() => setFeaturedDetails(true)}
									onMouseLeave={() => setFeaturedDetails(false)}
								>
									<img src={featuredArticles.imageUrls[0]} alt="" />

									<div
										className="article_right_details"
										style={
											featuredDetails ? { backgroundColor: "white" } : null
										}
									>
										<div className="article_right_title">
											{featuredArticles.title}
										</div>
										{featuredDetails && (
											<div className="article_right_inner">
												{" "}
												{featuredArticles.details.length > 100
													? `${featuredArticles.details.slice(0, 100)}...`
													: featuredArticles.details}
											</div>
										)}
									</div>
								</div>
							) : (
								<div className="article_right">
									<img src={ErrorImage} alt="" />
									<span
										style={{
											position: "absolute",
											backgroundColor: "white",

											padding: "2%",
										}}
									>
										{featuredError}
									</span>
								</div>
							)}

							<div className="article_left">
								<h4 className="article_heading">Other Articles</h4>
								{articles &&
									articles.map((data, index) => (
										<div
											onClick={() => ShowDetailsArticles(data.id)}
											key={index}
										>
											<div className="for_element" key={index}>
												<div className="image">
													<img src={data.imageUrls[0]} alt="img" />
												</div>
												<div className="article_left_list">
													<div className="article_left_top">
														<div className="article_title">{data.title}</div>
														<div className="article_time">
															{TimeAgo(data.createdDate)}
														</div>
													</div>
													<div className="article_details">{data.details}</div>
												</div>
											</div>
											<hr />
										</div>
									))}
								{error && <div>{error}</div>}
							</div>
						</div>

						<div className="pagination">
							<div className="pagination_prev">
								<button
									onClick={() => setCurrentPage(currentPage - 1)}
									// disabled={currentPage === 0}
									disabled={currentPage === 1}
								>
									<span>
										<SlArrowLeft />
									</span>
								</button>
							</div>

							<div className="numbers">
								{pageNumbers &&
									pageNumbers.map((pageNumber) => (
										<button
											disabled={currentPage === pageNumber}
											key={pageNumber}
											onClick={() => handlePageClick(pageNumber)}
										>
											{pageNumber}
										</button>
									))}
							</div>

							<div className="pagination_prev">
								<button
									onClick={() => setCurrentPage(currentPage + 1)}
									// disabled={currentPage >= totalPages - 1}
									disabled={currentPage >= totalPages}
								>
									<span>
										<SlArrowRight />
									</span>
								</button>
							</div>
						</div>
					</div>
				</main>
			)}
			<Footer />
		</>
	);
};

export default Article;
