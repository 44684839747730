import React, { useEffect, useState } from "react";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Box, CircularProgress } from "@mui/material";
import "../../Styles/Vacancy/VacancyDetails.scss";
import useLoading from "../../Hooks/useLoading";
import Loading from "../components/Loading";

const VacancyDetails = () => {
	const { id } = useParams();
	const { loading, showLoading, hideLoading } = useLoading();
	const [vacancy, setVacancy] = useState({});
	const [error, setError] = useState("");
	const [cookies] = useCookies(["userToken"]);
	const [token, setToken] = useState(cookies.userToken || "");

	useEffect(() => {
		setToken(cookies.userToken || "");
	}, [cookies]);

	useEffect(() => {
		VacancyDetails();
	}, []);

	const VacancyDetails = async () => {
		showLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/vacancy/${id}`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
					mode: "cors",
				},
			});
			if (response.ok) {
				const getApi = await response.json();
				setVacancy(getApi.vacancy);
				hideLoading();
			} else {
				const error = await response.json();
				setError(error.message);
				hideLoading();
			}
		} catch (error) {
			showLoading();
		}
	};

	return (
		<>
			<Navbar />
			{loading && <Loading />}
			{error && <div>{error}</div>}
			{!loading && (
				<main>
					<div className="vacancy_details">
						{loading ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								<CircularProgress />
							</Box>
						) : (
							<div>
								{vacancy && (
									<div>
										<div className="image">
											<img src={vacancy.vacancyPictureUrl} alt="img" />
										</div>
										<div className="title_details">
											<h5>{vacancy.title}</h5>
										</div>
										<div className="vacancy_description">
											<div className="vacancy_details">
												{vacancy.jobDescription}
											</div>
										</div>
									</div>
								)}
							</div>
						)}
					</div>
				</main>
			)}
			<Footer />
		</>
	);
};

export default VacancyDetails;
