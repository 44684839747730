import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function AdminHome() {
	return (
		<Box bgcolor="#e3e3e3" p={1} borderRadius={4}>
			<Box bgcolor="white" p={2} borderRadius={4}>
				<Typography variant="h4" component="div" color="#fd6a02" align="center">
					Welcome Admin!!
				</Typography>
			</Box>
		</Box>
	);
}
