// components/SelectQuestionBank.js

import React, { useState, useEffect, useRef } from "react";
import { Select, MenuItem, CircularProgress } from "@mui/material";
import BASE_URL from "../../../subcomponents/Config";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";

const SelectQuestionBank = ({ courseId, selectedBankId, onSelectBank }) => {
	const [questionBanks, setQuestionBanks] = useState([]);
	const [loading, setLoading] = useState(false);
	const selectRef = useRef(null);

	const [cookies] = useCookies(["adminToken", "adminRoles"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");

	// Fetch courses function
	useEffect(() => {
		fetchQuestionBank();
	}, [courseId]);

	const fetchQuestionBank = async () => {
		setLoading(true);
		if (courseId) {
			try {
				const response = await fetch(
					`${BASE_URL}/api/v1/questionBank/course/${courseId}`,
					{
						method: "GET",
						headers: {
							Authorization: `Bearer ${adminToken}`,
						},
					}
				);

				if (response.ok) {
					const apiData = await response.json();
					setQuestionBanks(apiData.questionBanks);
					setLoading(false);
				} else {
					const error = await response.json();
					console.error("Error fetching courses:", error.message);
					setLoading(false);
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		}
	};

	return (
		<Select
			value={selectedBankId}
			displayEmpty
			renderValue={(selected) => {
				if (selected.length === 0) {
					return <em>--Question Bank--</em>;
				}
				return selected;
			}}
			name="questionBankId"
			onChange={(e) => onSelectBank(e.target.value)}
			ref={selectRef}
		>
			{questionBanks.map((questionBank) => (
				<MenuItem key={questionBank.id} value={questionBank.id}>
					{questionBank.name}
				</MenuItem>
			))}
			{loading && (
				<MenuItem disabled>
					<CircularProgress size={24} />
				</MenuItem>
			)}
		</Select>
	);
};

export default SelectQuestionBank;
