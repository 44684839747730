// AddExam.js  Add Weekly Exam
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useCookies } from "react-cookie";
import BASE_URL from "../../subcomponents/Config";
import CloseIcon from "@mui/icons-material/Close";
import {
	TextField,
	Button,
	Typography,
	Container,
	Box,
	IconButton,
	CircularProgress,
	MenuItem,
	Select,
	FormControl,
	InputLabel,
	FormControlLabel,
	Checkbox,
	Slider,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import useFormValidation from "../../Hooks/useFormValidation";
import { toast, ToastContainer } from "react-toastify";
import CourseSelect from "../adminComponents/Api/CourseSelect";
import useQuestionFormValidation from "../../Hooks/useQuestionFormValidation";

const AdminLoginForm = styled("form")({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	border: "1px solid #ccc",
	padding: "20px",
	borderRadius: "5px",
});

const initialFormState = {
	details: "",
	examName: "",
	courseId: "",
	// examType: "",
	totalTime: "",
	questions: [
		{
			question: "",
			option1: "",
			option2: "",
			option3: "",
			option4: "",
			correctAnswer: "",
			questionImageFile: null,
		},
	],
	file: null,
	negativeMarking: false,
	negativeMarkingPercent: 0,
	free: false,
};

const AddExam = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [cookies] = useCookies(["adminToken", "adminRoles"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	// const [formState, setFormState] = useState(initialFormState);
	const [isFreeExam, setIsFreeExam] = useState(false);

	const {
		formData,
		setFormData,
		setErrors,
		errors,
		handleChange,
		validateForm,
		handleQuestionChange,
		handleFileChange,
		currentQuestionIndex,
		setCurrentQuestionIndex,
	} = useQuestionFormValidation(initialFormState);

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	const handleSelectCourse = (courseId) => {
		setFormData({ ...formData, courseId });

		setErrors((prevErrors) => ({
			...prevErrors,
			courseId: "",
		}));
	};
	const handleRemoveQuestion = (index) => {
		if (formData.questions.length > 1) {
			const updatedQuestions = [...formData.questions];

			updatedQuestions.splice(index, 1);

			setFormData({ ...formData, questions: updatedQuestions });

			setCurrentQuestionIndex(
				Math.min(currentQuestionIndex, updatedQuestions.length - 1)
			);
		} else {
			setFormData({
				...formData,
				questions: [
					{
						questionId: null,
						question: "",
						option1: "",
						option2: "",
						option3: "",
						option4: "",
						correctAnswer: "",
						questionImageFile: null,
					},
				],
			});
		}
		setErrors({});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const validationErrors = validateForm(formData);
		console.log(formData);

		if (Object.keys(validationErrors).length === 0) {
			setLoading(true);

			const formDataToSubmit = new FormData();
			formDataToSubmit.append("details", formData.details);
			formDataToSubmit.append("examName", formData.examName);
			formDataToSubmit.append("courseId", formData.courseId);
			formDataToSubmit.append("totalTime", formData.totalTime);
			formDataToSubmit.append("negativeMarking", formData.negativeMarking);
			formDataToSubmit.append("free", formData.free);
			formDataToSubmit.append(
				"negativeMarkingPercent",
				formData.negativeMarkingPercent
			);

			formData.questions.forEach((question, index) => {
				formDataToSubmit.append(
					`questions[${index}].question`,
					question.question
				);
				formDataToSubmit.append(
					`questions[${index}].option1`,
					question.option1
				);
				formDataToSubmit.append(
					`questions[${index}].option2`,
					question.option2
				);
				formDataToSubmit.append(
					`questions[${index}].option3`,
					question.option3
				);
				formDataToSubmit.append(
					`questions[${index}].option4`,
					question.option4
				);
				formDataToSubmit.append(
					`questions[${index}].correctAnswer`,
					question.correctAnswer
				);

				// Only append the image if it exists
				if (question.questionImageFile) {
					formDataToSubmit.append(
						`questions[${index}].questionImageFile`,
						question.questionImageFile
					);
				}
			});

			formDataToSubmit.append("file", formData.file);

			// Log the formData to inspect what is being sent
			//   for (let pair of formDataToSubmit.entries()) {
			//     console.log(pair[0] + ": " + pair[1]);
			//   }
			console.log(formDataToSubmit);

			try {
				const response = await fetch(`${BASE_URL}/api/v1/exam/createExam`, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${adminToken}`,
					},
					body: formDataToSubmit,
				});

				if (response.ok) {
					const responseData = await response.json();
					setLoading(false);
					setError("");
					toast.success(responseData.message);

					// Reset the form and question index
					setCurrentQuestionIndex(0);
					setFormData(initialFormState);
				} else {
					const errorData = await response.json();
					setError(errorData.message || "Failed to create exam");
					setLoading(false);
				}
			} catch (error) {
				console.error(error.message);
				setError("An unexpected error occurred. Please try again.");
				setLoading(false);
			}
		} else {
			setErrors(validationErrors);
		}
	};

	const addNewQuestion = () => {
		setFormData({
			...formData,
			questions: [
				...formData.questions,
				{
					question: "",
					option1: "",
					option2: "",
					option3: "",
					option4: "",
					correctAnswer: "",
					questionImageFile: null,
				},
			],
		});
		setCurrentQuestionIndex(formData.questions.length);
	};

	const handlePreviousQuestion = () => {
		if (currentQuestionIndex > 0) {
			setCurrentQuestionIndex(currentQuestionIndex - 1);
			setErrors({});
		}
	};

	const handleNextQuestion = () => {
		if (currentQuestionIndex < formData.questions.length - 1) {
			setCurrentQuestionIndex(currentQuestionIndex + 1);
			setErrors({});
		}
	};

	const handleNegativeMarkingChange = (event) => {
		const { value } = event.target;
		const isNegativeMarking = value === "true"; // Convert string to boolean

		setFormData((prevState) => ({
			...prevState,
			negativeMarking: isNegativeMarking,
			negativeMarkingPercent: isNegativeMarking
				? prevState.negativeMarkingPercent
				: 0, // Set to 0 when false
		}));
	};

	const handlePercentChange = (event) => {
		const { value } = event.target;
		setFormData((prevState) => ({
			...prevState,
			negativeMarkingPercent: Number(value), // Ensure it's a number
		}));
	};

	// Handle the change for the "free" field
	const handleFreeExamChange = (event) => {
		const { value } = event.target;
		setFormData((prevState) => ({
			...prevState,
			free: value === "true", // Convert string to boolean
		}));
	};

	return (
		<>
			<ToastContainer position="top-center" autoClose="2000" />
			<Container maxWidth="xs">
				<AdminLoginForm onSubmit={handleSubmit}>
					<Typography variant="h2" gutterBottom>
						Add Weekly Exam
					</Typography>
					{error && (
						<Typography variant="subtitle2" color="error" gutterBottom>
							{error}!!
						</Typography>
					)}
					<Box width="100%" marginBottom="20px">
						<TextField
							label="Exam Name"
							variant="outlined"
							name="examName"
							fullWidth
							value={formData.examName}
							onChange={handleChange}
						/>
						{errors.examName && (
							<span style={{ color: "red" }}>{errors.examName}</span>
						)}
					</Box>
					<Box width="100%" marginBottom="20px">
						<TextField
							label="Exam Details"
							variant="outlined"
							name="details"
							fullWidth
							value={formData.details}
							onChange={handleChange}
						/>
						{errors.details && (
							<span style={{ color: "red" }}>{errors.details}</span>
						)}
					</Box>
					<Box width="100%" marginBottom="20px">
						<CourseSelect
							selectedCourseId={formData.courseId}
							onSelectCourse={handleSelectCourse}
						/>
						{errors.courseId && (
							<div style={{ color: "red" }}>{errors.courseId}</div>
						)}
					</Box>

					{/* Make Free Exam */}
					<Box width="100%" marginBottom="20px">
						<Typography variant="subtitle1">Make Free Exam</Typography>
						<Select
							value={formData.free ? "true" : "false"}
							onChange={handleFreeExamChange}
							name="free"
							fullWidth
						>
							<MenuItem value="true">True</MenuItem>
							<MenuItem value="false">False</MenuItem>
						</Select>
					</Box>

					{/* Negative Marking Dropdown */}
					<Box width="100%" marginBottom="20px">
						<Typography variant="subtitle1">Negative Marking</Typography>
						<Select
							value={formData.negativeMarking ? "true" : "false"}
							onChange={handleNegativeMarkingChange}
							name="negativeMarking"
							fullWidth
						>
							<MenuItem value="true">True</MenuItem>
							<MenuItem value="false">False</MenuItem>
						</Select>
					</Box>
					{formData.negativeMarking && (
						<Box width="100%" marginBottom="20px">
							<Typography variant="subtitle1">
								Negative Marking Percent
							</Typography>
							<Slider
								value={formData.negativeMarkingPercent}
								onChange={handlePercentChange}
								name="negativeMarkingPercent"
								step={5}
								min={0}
								max={100}
								valueLabelDisplay="auto"
								marks
							/>
							{errors.negativeMarkingPercent && (
								<span style={{ color: "red" }}>
									{errors.negativeMarkingPercent}
								</span>
							)}
						</Box>
					)}

					<Box width="100%" marginBottom="20px">
						<TextField
							label="Total Time (in hours)"
							variant="outlined"
							name="totalTime"
							fullWidth
							value={formData.totalTime}
							type="number"
							onChange={handleChange}
						/>
						{errors.totalTime && (
							<span style={{ color: "red" }}>{errors.totalTime}</span>
						)}
					</Box>
					<Box width="100%" marginBottom="10px">
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								border: "1px solid #ccc",
								padding: "0.4rem",
								borderRadius: "5px",
							}}
						>
							<Typography
								variant="body1"
								sx={{ flexGrow: 1, color: "gray", padding: 0 }}
							>
								Select Image:
							</Typography>
							<input
								id="file-upload"
								type="file"
								onChange={handleFileChange}
								accept="image/*"
								style={{ display: "none" }}
								name="file"
							/>
							{formData.file && (
								<Typography
									variant="body2"
									sx={{
										marginTop: "10px",
										color: "gray",
										margin: "1px",
										// padding: 0,
									}}
								>
									{formData.file.name}
								</Typography>
							)}
							<label htmlFor="file-upload">
								<Button variant="outlined" component="span">
									Select
								</Button>
							</label>
						</Box>
						{errors.file && <span style={{ color: "red" }}>{errors.file}</span>}
					</Box>

					{/* This is for question and question image */}
					<Box width="100%" marginBottom="20px">
						<Box style={{ display: "flex", justifyContent: "space-between" }}>
							<Typography variant="h6" gutterBottom>
								Question {currentQuestionIndex + 1} of{" "}
								{formData.questions.length}
							</Typography>
							<IconButton
								aria-label="delete"
								onClick={() => handleRemoveQuestion(currentQuestionIndex)}
							>
								<CloseIcon />
							</IconButton>
						</Box>
						<Box width="100%" marginBottom="20px">
							<TextField
								label="Question"
								variant="outlined"
								name={`question`}
								fullWidth
								value={formData.questions[currentQuestionIndex].question}
								onChange={(e) =>
									handleQuestionChange("question", e.target.value)
								}
							/>
							{errors[`question`] && (
								<span style={{ color: "red" }}>{errors[`question`]}</span>
							)}
						</Box>
						{/* Optional Question Image Input */}
						<Box width="100%" marginBottom="10px">
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									border: "1px solid #ccc",
									padding: "0.4rem",
									borderRadius: "5px",
								}}
							>
								<Typography
									variant="body1"
									sx={{ flexGrow: 1, color: "gray", padding: 0 }}
								>
									Question Image(optional)
								</Typography>
								<input
									id="question-upload"
									type="file"
									onChange={(e) => {
										const file = e.target.files[0];
										if (file) {
											handleQuestionChange("questionImageFile", file);
										}
									}}
									accept="image/*"
									style={{ display: "none" }}
								/>
								{formData.questions[currentQuestionIndex].questionImageFile && (
									<Typography
										variant="body2"
										sx={{
											marginTop: "10px",
											color: "gray",
											margin: "1px",
											// padding: 0,
										}}
									>
										{
											formData.questions[currentQuestionIndex].questionImageFile
												.name
										}
									</Typography>
								)}
								<label htmlFor="question-upload">
									<Button variant="outlined" component="span">
										Select
									</Button>
								</label>
							</Box>
						</Box>
					</Box>
					<Box style={{ display: "flex" }}>
						<Box width="100%" marginBottom="20px" marginRight="10px">
							<TextField
								label="Option 1"
								variant="outlined"
								name={`option1`}
								fullWidth
								value={formData.questions[currentQuestionIndex].option1}
								onChange={(e) =>
									handleQuestionChange("option1", e.target.value)
								}
							/>
							{errors[`option1`] && (
								<span style={{ color: "red" }}>{errors[`option1`]}</span>
							)}
						</Box>
						<Box width="100%" marginBottom="20px">
							<TextField
								label="Option 2"
								variant="outlined"
								name={`option2`}
								fullWidth
								value={formData.questions[currentQuestionIndex].option2}
								onChange={(e) =>
									handleQuestionChange("option2", e.target.value)
								}
							/>
							{errors[`option2`] && (
								<span style={{ color: "red" }}>{errors[`option2`]}</span>
							)}
						</Box>
					</Box>
					<Box style={{ display: "flex" }}>
						<Box width="100%" marginBottom="20px" marginRight="10px">
							<TextField
								label="Option 3"
								variant="outlined"
								name={`option3`}
								fullWidth
								value={formData.questions[currentQuestionIndex].option3}
								onChange={(e) =>
									handleQuestionChange("option3", e.target.value)
								}
							/>
							{errors[`option3`] && (
								<span style={{ color: "red" }}>{errors[`option3`]}</span>
							)}
						</Box>
						<Box width="100%" marginBottom="20px">
							<TextField
								label="Option 4"
								variant="outlined"
								name={`option4`}
								fullWidth
								value={formData.questions[currentQuestionIndex].option4}
								onChange={(e) =>
									handleQuestionChange("option4", e.target.value)
								}
							/>
							{errors[`option4`] && (
								<span style={{ color: "red" }}>{errors[`option4`]}</span>
							)}
						</Box>
					</Box>
					<Box width="100%" marginBottom="20px">
						<TextField
							label="Correct Answer"
							variant="outlined"
							name={`correctAnswer`}
							fullWidth
							value={formData.questions[currentQuestionIndex].correctAnswer}
							onChange={(e) =>
								handleQuestionChange("correctAnswer", e.target.value)
							}
						/>
						{errors[`correctAnswer`] && (
							<span style={{ color: "red" }}>{errors[`correctAnswer`]}</span>
						)}
					</Box>

					{/* Navigation Buttons */}
					<Box
						width="100%"
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						marginBottom="20px"
					>
						<IconButton
							color="primary"
							onClick={handlePreviousQuestion}
							disabled={currentQuestionIndex === 0}
						>
							<ArrowBack />
						</IconButton>
						<Button
							variant="outlined"
							color="secondary"
							disabled={loading}
							onClick={addNewQuestion}
							style={{ marginBottom: "20px" }}
						>
							Add More Questions
						</Button>
						<IconButton
							color="primary"
							onClick={handleNextQuestion}
							disabled={currentQuestionIndex === formData.questions.length - 1}
						>
							<ArrowForward />
						</IconButton>
					</Box>
					<Button type="submit" variant="contained" color="secondary">
						{loading ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "6px",
								}}
							>
								<CircularProgress />
							</Box>
						) : (
							<Box>Add Exam</Box>
						)}
					</Button>
				</AdminLoginForm>
			</Container>
		</>
	);
};

export default AddExam;
