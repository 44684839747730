import React, { useRef, useEffect } from "react";
import { Box, IconButton, Modal, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { Player } from "video-react";
import "video-react/dist/video-react.css";

const VideoModal = ({ open, handleClose, videoUrl }) => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
	const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

	const playerRef = useRef(null);

	useEffect(() => {
		const player = playerRef.current;

		if (player) {
			// Log current time when seeking
			player.subscribeToStateChange((state, prevState) => {
				if (state.currentTime !== prevState.currentTime) {
					console.log("Current Time: ", state.currentTime);
				}
			});
		}
	}, []);

	const modalStyle = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "80%",
		bgcolor: "background.paper",
		boxShadow: 24,
		p: 4,
	};

	let playerWidth, playerHeight;

	if (isSmallScreen) {
		playerWidth = "100%";
		playerHeight = "auto";
	} else if (isMediumScreen) {
		playerWidth = "50rem";
		playerHeight = "28rem";
	} else if (isLargeScreen) {
		playerWidth = "60rem";
		playerHeight = "30rem";
	}

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-title"
			aria-describedby="modal-description"
		>
			<Box sx={modalStyle}>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
				{videoUrl && (
					<div onContextMenu={(e) => e.preventDefault()}>
						<Player
							ref={playerRef} // Attach ref to the player
							playsInline
							src={videoUrl}
							controls={true} // Enable controls
							width={playerWidth}
							height={playerHeight}
						/>
					</div>
				)}
			</Box>
		</Modal>
	);
};

export default VideoModal;
