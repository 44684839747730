import { Box, Button } from "@mui/material";
import React from "react";
import * as XLSX from "xlsx";

const ExportToExcel = ({ data }) => {
	console.log(data);
	const exportToExcel = () => {
		const worksheet = XLSX.utils.json_to_sheet(data);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

		// Generate Excel file
		const excelFile = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

		// Create a blob and a download link
		const blob = new Blob([excelFile], { type: "application/octet-stream" });
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = "data.xlsx";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<Button variant="contained" color="success" onClick={exportToExcel}>
			Download Excel
		</Button>
	);
};

export default ExportToExcel;
