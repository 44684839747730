import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import "../adminComponents/admin.scss";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableRow,
	CircularProgress,
	Button,
	Chip,
	Modal,
	IconButton,
} from "@mui/material";
import BASE_URL from "../../subcomponents/Config.jsx";
import ReactPlayer from "react-player";
import CloseIcon from "@mui/icons-material/Close";
import { toast, ToastContainer } from "react-toastify";

const modalStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "80%",
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

const tablevalue = [
	{
		title: "News Article Title",
		value: "title",
	},
	{
		title: "News Article Details",
		value: "details",
	},
	{
		title: "Created Date",
		value: "createdDate",
	},
];

export default function ArticlesDetail() {
	const { id } = useParams();
	const [data, setData] = useState([]);
	const [images, setImages] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [cookies] = useCookies(["adminToken"]);
	const nav = useNavigate();
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);
	function Back() {
		nav(-1);
	}
	useEffect(() => {
		getData();
	}, []);

	const handleDelete = async (id) => {
		setLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/news/delete/${id}`, {
				method: "DELETE",
				headers: {
					Authorization: `Bearer ${adminToken}`,
				},
			});
			if (response.ok) {
				const apiData = await response.json();
				toast.success(apiData.message);
				nav(-1);
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/news/${id}`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${adminToken}`,
				},
			});
			if (response.ok) {
				const apiData = await response.json();
				setData(apiData.newsArticle);
				setImages(apiData.newsArticle.imageUrls);
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};
	const featuredArticles = async () => {
		setLoading(true);
		const setfeatured = data.featuredArticls;
		try {
			const response = await fetch(`${BASE_URL}/api/v1/news/${id}/featured`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${adminToken}`,
				},
				body: JSON.stringify(!setfeatured),
			});
			if (response.ok) {
				const apiData = await response.json();
				toast.success(apiData.message);
				getData();
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.log("error fetching data:", error.message);
		}
	};
	return (
		<Box>
			<ToastContainer position="top-center" autoClose="2000" />
			<Button
				onClick={() => Back()}
				variant="outlined"
				color="secondary"
				style={{ marginBottom: "0.2rem" }}
			>
				Back
			</Button>
			<Card variant="outlined">
				<CardContent>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<Typography variant="h3">Article Details</Typography>
						</div>
						<Chip
							sx={{
								backgroundColor: "error.main",
								color: "#fff",
							}}
							size="medium"
							label="Delete"
							onClick={() => handleDelete(id)}
						/>
					</div>
					<Box
						sx={{
							overflow: {
								xs: "auto",
								sm: "unset",
							},
						}}
					>
						<Table
							aria-label="simple table"
							sx={{
								mt: 3,
							}}
						>
							{!loading && data ? (
								<>
									<TableBody>
										{tablevalue &&
											tablevalue.map((table, index) => (
												<TableRow key={index}>
													<TableCell>
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "500",
															}}
														>
															{table.title}
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "500",
															}}
														>
															{data[table.value]}
														</Typography>
													</TableCell>
												</TableRow>
											))}
										<TableRow>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
													}}
												>
													Images
												</Typography>
											</TableCell>
											<div
												style={{
													display: "grid",
													gridTemplateColumns: "1fr 1fr",
												}}
											>
												{images &&
													images.map((image, index) => (
														<TableCell key={index}>
															<Typography
																sx={{
																	fontSize: "15px",
																	fontWeight: "500",
																}}
															>
																<img
																	src={image}
																	alt=""
																	style={{
																		width: "10rem",
																		height: "auto",
																	}}
																/>
															</Typography>
														</TableCell>
													))}
											</div>
										</TableRow>
										<TableRow>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
													}}
												>
													Featured Article
												</Typography>
											</TableCell>
											<TableCell>
												<Chip
													sx={{
														fontSize: "15px",
														fontWeight: "500",
														ml: "45px",
														backgroundColor: data.featuredArticle
															? "error.main"
															: "primary.main",
														color: "#fff",
													}}
													size="small"
													label={
														data.featuredArticle
															? "remove featured"
															: "set featured"
													}
													onClick={featuredArticles}
												/>
											</TableCell>
										</TableRow>
									</TableBody>
								</>
							) : (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "200px",
									}}
								>
									<CircularProgress />
								</Box>
							)}
							{error ? (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "200px",
									}}
								>
									{error}
								</Box>
							) : null}
						</Table>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
}
