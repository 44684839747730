import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Chip,
	CircularProgress,
} from "@mui/material";
import Pagination from "../adminComponents/Pagination/Pagination";
import TruncateText from "../adminComponents/Truncate/TruncateText";
import { toast, ToastContainer } from "react-toastify";

export default function WeeklyExamList() {
	const [course, setCourse] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(8);
	const [totalPages, setTotalPages] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const nav = useNavigate();
	const [cookies] = useCookies(["adminToken"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	useEffect(() => {
		getData();
	}, [currentPage]);

	function handleEdit(id) {
		nav(`/admin/EXAM_DEPARTMENT/course/weeklyExam/edit/${id}`);
	}
	function handleDetails(id) {
		nav(`/admin/EXAM_DEPARTMENT/Course/details/${id}`);
	}

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/course/courseList?page=${currentPage}&size=${itemsPerPage}`,
				{
					method: "GET",
				}
			);
			if (response.ok) {
				const apiData = await response.json();
				setCourse(apiData.courses);
				setTotalPages(apiData.totalPages);
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	return (
		<Box>
			<Card variant="outlined">
				<CardContent>
					<Typography variant="h3">Weekly Exam List</Typography>
					<Box
						sx={{
							overflow: {
								xs: "auto",
								sm: "unset",
							},
						}}
					>
						<Table aria-label="simple table" sx={{ mt: 3, overflowX: "auto" }}>
							<TableHead>
								<TableRow>
									<TableCell align="left">
										<Typography color="textSecondary" variant="h5">
											Course Title
										</Typography>
									</TableCell>
									<TableCell align="left">
										<Typography color="textSecondary" variant="h5">
											Weekly Exam Date
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography color="textSecondary" variant="h5">
											Edit Weekly Date
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography color="textSecondary" variant="h5">
											Course Details
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{course &&
									course.map((course) => (
										<TableRow key={course.id}>
											<TableCell
												align="left"
												sx={{ fontSize: "15px", fontWeight: "500" }}
											>
												{course.title}
											</TableCell>
											<TableCell
												align="left"
												sx={{ fontSize: "15px", fontWeight: "500" }}
											>
												{course.weeklyExamDate}
											</TableCell>
											<TableCell align="center">
												<Chip
													sx={{
														pl: "4px",
														pr: "4px",
														backgroundColor: "primary.main",
														color: "#fff",
													}}
													size="small"
													label="Edit"
													onClick={() => handleEdit(course.id)}
												/>
											</TableCell>
											<TableCell align="center">
												<Chip
													sx={{
														pl: "4px",
														pr: "4px",
														backgroundColor: "success.main",
														color: "#fff",
													}}
													size="small"
													label="Details"
													onClick={() => handleDetails(course.id)}
												/>
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>

						{loading ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								<CircularProgress />
							</Box>
						) : null}
						{error ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								{error}
							</Box>
						) : null}
						<Pagination
							currentPage={currentPage}
							totalPages={totalPages}
							onPageChange={handlePageChange}
						/>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
}
