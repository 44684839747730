import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableRow,
	CircularProgress,
	Button,
	Chip,
} from "@mui/material";
import BASE_URL from "../subcomponents/Config.jsx";

const tablevalue = [
	{
		title: "Name",
		value: "name",
	},
	{
		title: "Email",
		value: "email",
	},
];

export default function AdminProfile() {
	const [data, setData] = useState();
	const [roles, setRoles] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [cookies] = useCookies(["adminToken", "adminId"]);
	const nav = useNavigate();
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	const [adminId, setAdminId] = useState(cookies.adminId || "");
	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
		setAdminId(cookies.adminId || "");
	}, [cookies]);
	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/admin/${adminId}`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${adminToken}`,
				},
			});
			if (response.ok) {
				const apiData = await response.json();
				setData(apiData.admin);
				setRoles(apiData.admin.roles);
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const changePassword =()=>{
		nav(`/admin/changePassword`);
	}
	return (
		<Box>
			<Card variant="outlined">
				<CardContent>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<Typography variant="h3">Admin Details</Typography>
						</div>
					</div>
					<Box
						sx={{
							overflow: {
								xs: "auto",
								sm: "unset",
							},
						}}
					>
						<Table
							aria-label="simple table"
							sx={{
								mt: 3,
							}}
						>
							{!loading && data ? (
								<>
									<TableBody>
										{tablevalue &&
											tablevalue.map((table, index) => (
												<TableRow key={index}>
													<TableCell>
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "500",
															}}
														>
															{table.title}
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "500",
															}}
														>
															{data[table.value]}
														</Typography>
													</TableCell>
												</TableRow>
											))}

										<TableRow>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
													}}
												>
													Roles
												</Typography>
											</TableCell>
											
											{roles &&
												roles.map((role, index) => (
													<TableCell
														key={index}
														style={{
															display: "grid",
															gridTemplateColumns: "1fr",
														}}
													>
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "500",
															}}
														>
															{role
																.toLowerCase()
																.replace(/_/g, " ")
																.replace(/\b\w/g, (char) => char.toUpperCase())}
														</Typography>
													</TableCell>
												))}
										</TableRow>
										<TableRow>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
													}}
												>
													Change Your Password
												</Typography>
											</TableCell>
											<TableCell>
												<Chip
													sx={{
														// backgroundColor: "error.main",
														backgroundColor: "secondary.main",
														color: "#fff",
														marginTop: "1.25rem",
														marginLeft: "3rem",
														textAlign: "center",
													}}
													size="medium"
													label="Change password"
													onClick={changePassword}
												/>
											</TableCell>
										</TableRow>
									</TableBody>
								</>
							) : (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "200px",
									}}
								>
									<CircularProgress />
								</Box>
							)}
							{error ? (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "200px",
									}}
								>
									{error}
								</Box>
							) : null}
						</Table>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
}
