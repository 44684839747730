import React, { useEffect, useState } from "react";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import { Box, CircularProgress } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../Styles/Home/carouselStyles.scss";
import useLoading from "../../Hooks/useLoading";
import ErrorImage from "../components/Images/errorImage.png";

const BannerImage = () => {
	const { showLoading, hideLoading } = useLoading();
	const [error, setError] = useState("");
	const [bannerImages, setBannerImages] = useState([]);
	const [cookies] = useCookies(["userToken"]);
	const [token, setToken] = useState(cookies.userToken || "");

	useEffect(() => {
		setToken(cookies.userToken || "");
	}, [cookies]);

	useEffect(() => {
		GetBannerimg();
	}, []);

	const GetBannerimg = async () => {
		showLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/banner/images`, {
				method: "GET",
				headers: {
					mode: "cors",
				},
			});
			if (response.ok) {
				const getApi = await response.json();
				setBannerImages(getApi.bannerImages);
				hideLoading();
			} else {
				const error = await response.json();
				setError(error.message);
				hideLoading();
			}
		} catch (error) {
			showLoading();
		}
	};

	// Create chunks of images to display multiple per slide
	const chunkSize = 1; // Number of images per slide
	const chunks = [];
	for (let i = 0; i < bannerImages.length; i += chunkSize) {
		chunks.push(bannerImages.slice(i, i + chunkSize));
	}

	return (
		<div className="banner">
			{error && (
				<div
					style={{
						position: "relative",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<img src={ErrorImage} alt="error" />
					<div
						style={{
							position: "absolute",
							backgroundColor: "white",
							padding: "2%",
						}}
					>
						{error}
					</div>
				</div>
			)}
			{bannerImages.length > 0 ? (  // Wait until images are loaded
				<Carousel
					autoPlay
					interval={5000}
					infiniteLoop
					stopOnHover={false}
					showThumbs={false}
					showArrows={false}
				>
					{chunks.map((chunk, index) => (
						<div key={index}>
							{chunk.map((image, idx) => (
								<Box
									key={idx}
									onClick={() => {
										if (image.url !== "null") {
											window.open(image.url, "_blank");
										}
									}}
									sx={{ cursor: "pointer" }}
								>
									<img src={image.imageUrl} alt={image.imageName} />
								</Box>
							))}
						</div>
					))}
				</Carousel>
			) : (
				<CircularProgress /> 
			)}
		</div>
	);
	
};

export default BannerImage;
