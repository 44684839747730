import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const useNavigationBlocker = (shouldBlock, message) => {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const handleBeforeUnload = (e) => {
			if (shouldBlock) {
				e.preventDefault();
				e.returnValue = message; // This is required for older browsers
			}
		};

		const handleNavigation = (e) => {
			if (shouldBlock && !window.confirm(message)) {
				e.preventDefault();
				navigate(location.pathname, { replace: true });
			}
		};

		window.addEventListener("beforeunload", handleBeforeUnload);
		window.addEventListener("popstate", handleNavigation);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
			window.removeEventListener("popstate", handleNavigation);
		};
	}, [shouldBlock, message, navigate, location]);
};

export default useNavigationBlocker;
