import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const LogoText = () => {
	return (
		<Box bgcolor="#fd6a02" p={2} borderRadius={4}>
			<Typography variant="h4" component="div" color="white">
				LokKosis Admin Panel
			</Typography>
		</Box>
	);
};

export default LogoText;
