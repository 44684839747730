// ViewMessages.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	CircularProgress,
	Button,
	Chip,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";

const ViewMessages = () => {
	const [messages, setMessages] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(8);
	const [totalPages, setTotalPages] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const nav = useNavigate();
	const [cookies] = useCookies(["adminToken"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	useEffect(() => {
		getData();
	}, [currentPage]);

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/contactMeList`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${adminToken}`,
				},
			});
			if (response.ok) {
				const apiData = await response.json();
				setMessages(apiData.contactMe);
				setTotalPages(Math.ceil(apiData.contactMe.length / itemsPerPage));
			} else {
				const error = await response.json();
				setError(error.message);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			setError("An error occurred while fetching data.");
		}
		setLoading(false);
	};

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentData = messages.slice(indexOfFirstItem, indexOfLastItem);

	const handlePageClick = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const pageNumbers = [];
	for (let i = 1; i <= totalPages; i++) {
		pageNumbers.push(i);
	}
	const handleDelete = async (id) => {
		setLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/contact/delete/${id}`, {
				method: "DELETE",
				headers: {
					Authorization: `Bearer ${adminToken}`,
				},
			});
			if (response.ok) {
				const apiData = await response.json();
				toast.success(apiData.message);
				setLoading(false);
				getData();
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	return (
		<Box>
			<ToastContainer position="top-center" autoClose={1500} />
			<Card variant="outlined">
				<CardContent>
					<Typography variant="h3">User Messages List</Typography>
					<Box
						sx={{
							overflow: {
								xs: "auto",
								sm: "unset",
							},
						}}
					>
						<Table
							aria-label="simple table"
							sx={{
								mt: 3,
							}}
						>
							<TableHead>
								<TableRow>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											User Name
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Email
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Mobile Number
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Messages
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Delete
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{currentData.map((message) => (
									<TableRow key={message.id}>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{message.name}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{message.email}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{message.mobileNumber}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{message.message}
											</Typography>
										</TableCell>
										<TableCell align="center">
											<Chip
												sx={{
													pl: "4px",
													pr: "4px",
													backgroundColor: "error.main",
													color: "#fff",
												}}
												size="small"
												label="Delete"
												onClick={() => handleDelete(message.id)}
											/>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						{loading && (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								<CircularProgress />
							</Box>
						)}
						{error && (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								{error}
							</Box>
						)}
						<Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
							<div className="pagination">
								<div className="pagination-prev">
									<button
										onClick={() => setCurrentPage(currentPage - 1)}
										disabled={currentPage === 1}
									>
										Previous
									</button>
								</div>
								{pageNumbers &&
									pageNumbers.map((pageNumber) => (
										<button
											disabled={currentPage === pageNumber}
											key={pageNumber}
											onClick={() => handlePageClick(pageNumber)}
										>
											{pageNumber}
										</button>
									))}
								<div className="pagination-prev">
									<button
										onClick={() => setCurrentPage(currentPage + 1)}
										disabled={indexOfLastItem >= messages.length}
									>
										Next
									</button>
								</div>
							</div>
						</Box>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
};

export default ViewMessages;
