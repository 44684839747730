import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import "../adminComponents/admin.scss";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableRow,
	CircularProgress,
	Button,
	Chip,
} from "@mui/material";
import BASE_URL from "../../subcomponents/Config.jsx";
import { toast, ToastContainer } from "react-toastify";

const tablevalue = [
	{
		title: "Job Title",
		value: "title",
	},
	{
		title: "Job Description",
		value: "jobDescription",
	},
	{
		title: "Apply Email",
		value: "applyEmail",
	},
	{
		title: "Added Date",
		value: "addedDate",
	},
];

export default function VacancyDetail() {
	const { id } = useParams();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [cookies] = useCookies(["adminToken"]);
	const nav = useNavigate();
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);
	function Back() {
		nav(-1);
	}
	useEffect(() => {
		getData();
	}, []);

	const handleDelete = async (id) => {
		setLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/vacancy/delete/${id}`, {
				method: "DELETE",
				headers: {
					Authorization: `Bearer ${adminToken}`,
				},
			});
			if (response.ok) {
				const apiData = await response.json();
				toast.success(apiData.message);
				nav(-1);
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/vacancy/${id}`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${adminToken}`,
				},
			});
			if (response.ok) {
				const apiData = await response.json();
				setData(apiData.vacancy);
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};
	return (
		<Box>
			<ToastContainer position="top-center" autoClose={2000} />
			<Button
				onClick={() => Back()}
				variant="outlined"
				color="secondary"
				style={{ marginBottom: "0.2rem" }}
			>
				Back
			</Button>
			<Card variant="outlined">
				<CardContent>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<Typography variant="h3">Vacancy Details</Typography>
						</div>
						<Chip
							sx={{
								backgroundColor: "error.main",
								color: "#fff",
							}}
							size="medium"
							label="Delete"
							onClick={() => handleDelete(id)}
						/>
					</div>
					<Box
						sx={{
							overflow: {
								xs: "auto",
								sm: "unset",
							},
						}}
					>
						<Table
							aria-label="simple table"
							sx={{
								mt: 3,
							}}
						>
							{!loading && data ? (
								<>
									<TableBody>
										{tablevalue &&
											tablevalue.map((table, index) => (
												<TableRow key={index}>
													<TableCell>
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "500",
															}}
														>
															{table.title}
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "500",
															}}
														>
															{data[table.value]}
														</Typography>
													</TableCell>
												</TableRow>
											))}
										<TableRow>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
													}}
												>
													Images
												</Typography>
											</TableCell>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
													}}
												>
													<img
														src={data.vacancyPictureUrl}
														alt=""
														style={{
															width: "20rem",
															height: "auto",
														}}
													/>
												</Typography>
											</TableCell>
										</TableRow>
									</TableBody>
								</>
							) : (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "200px",
									}}
								>
									<CircularProgress />
								</Box>
							)}
							{error ? (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "200px",
									}}
								>
									{error}
								</Box>
							) : null}
						</Table>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
}
