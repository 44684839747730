import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useCookies } from "react-cookie";
import BASE_URL from "../../subcomponents/Config";
import {
	TextField,
	Button,
	Typography,
	Container,
	Box,
	Stack,
} from "@mui/material";
import useFormValidation from "../../Hooks/useFormValidation";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const AdminLoginForm = styled("form")({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	border: "1px solid #ccc",
	padding: "20px",
	borderRadius: "5px",
});

const initialFormState = {
	title: "",
	file: null,
};

const EditCourseCategory = () => {
	const [loading, setLoading] = useState(false);
	const { id } = useParams();
	const [error, setError] = useState("");
	const [cookies] = useCookies(["adminToken", "adminRoles"]);
	const nav = useNavigate();
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	const {
		formData,
		setFormData,
		setErrors,
		errors,
		handleChange,
		handleFileChange,
		validateForm,
	} = useFormValidation(initialFormState);

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/courseCategory/${id}`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${adminToken}`,
					mode: "cors",
				},
			});
			if (response.ok) {
				const apiData = await response.json();
				setFormData(apiData.category);
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const validationErrors = validateForm(formData);

		if (Object.keys(validationErrors).length === 0) {
			const data = new FormData();
			data.append("title", formData.title);
			data.append("file", formData.file);
			setLoading(true);
			try {
				const response = await fetch(
					`${BASE_URL}/api/v1/courseCategory/updateCategory/${id}`,
					{
						method: "PUT",
						headers: {
							Authorization: `Bearer ${adminToken}`,
						},
						body: data,
					}
				);

				if (response.ok) {
					const userData = await response.json();
					toast.success(userData.message);
					setLoading(false);
					setFormData(initialFormState);
				} else {
					const error = await response.json();
					setError(error.message);
					setLoading(false);
				}
			} catch (error) {
				console.log(error.message);
			}
		} else {
			setErrors(validationErrors);
		}
	};
	function Back() {
		nav(-1);
	}

	return (
		<>
			<ToastContainer position="top-center" autoClose="2000" />
			<Container maxWidth="xs">
				<Button
					onClick={() => Back()}
					variant="outlined"
					color="secondary"
					style={{ marginBottom: "0.2rem" }}
				>
					Back
				</Button>
				<AdminLoginForm onSubmit={handleSubmit}>
					<Typography variant="h2" gutterBottom>
						Edit Course Category
					</Typography>
					{error && (
						<Typography variant="subtitle2" color="error" gutterBottom>
							{error}!!
						</Typography>
					)}
					<Box width="100%" marginBottom="20px">
						<TextField
							label="Course Title"
							variant="outlined"
							name="title"
							fullWidth
							value={formData.title}
							onChange={handleChange}
						/>
						{errors.title && (
							<span style={{ color: "red" }}>{errors.title}</span>
						)}
					</Box>
					<Box width="100%" marginBottom="20px">
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								border: "1px solid #ccc",
								padding: "10px",
								borderRadius: "5px",
							}}
						>
							<Typography variant="body1" sx={{ flexGrow: 1, color: "gray" }}>
								Select Image:
							</Typography>
							<input
								id="file-upload"
								type="file"
								onChange={handleFileChange}
								accept="image/*"
								style={{ display: "none" }} // Hides the default file input UI
								name="file"
							/>
							{formData.file && (
								<Typography
									variant="body2"
									sx={{ marginTop: "10px", color: "gray", margin: "2px" }}
								>
									{formData.file.name}
								</Typography>
							)}
							<label htmlFor="file-upload">
								<Button variant="outlined" component="span">
									Select
								</Button>
							</label>
						</Box>

						{errors.file && <span style={{ color: "red" }}>{errors.file}</span>}
					</Box>
					<Button
						type="submit"
						variant="contained"
						color="secondary"
						disabled={loading}
					>
						Edit Category
					</Button>
				</AdminLoginForm>
			</Container>
		</>
	);
};

export default EditCourseCategory;
