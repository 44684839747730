import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined";
import AspectRatioOutlinedIcon from "@mui/icons-material/AspectRatioOutlined";

const ExamMenuitems = [
  {
    title: "Home",
    icon: AddToPhotosOutlinedIcon,
    href: "/admin/EXAM_DEPARTMENT/home",
  },
  {
    title: "Add Weekly Exam",
    icon: AddToPhotosOutlinedIcon,
    href: "/admin/EXAM_DEPARTMENT/exam/add",
  },
  {
    title: "Exams List",
    icon: AspectRatioOutlinedIcon,
    href: "/admin/EXAM_DEPARTMENT/exam/list",
  },
  {
    title: "Weekly Exam List",
    icon: AspectRatioOutlinedIcon,
    href: "/admin/EXAM_DEPARTMENT/course/weeklyExam/list",
  },
  {
    title: "Add Question Bank",
    icon: AddToPhotosOutlinedIcon,
    href: "/admin/EXAM_DEPARTMENT/questionBank/add",
  },
  {
    title: "Question Bank List",
    icon: AspectRatioOutlinedIcon,
    href: "/admin/EXAM_DEPARTMENT/questionBank/list",
  },
  {
    title: "Add Daily Exam ",
    icon: AddToPhotosOutlinedIcon,
    href: "/admin/EXAM_DEPARTMENT/questionBank/exam/create",
  },
  {
    title: "Free Exam List ",
    icon: AspectRatioOutlinedIcon,
    href: "/admin/EXAM_DEPARTMENT/free/exam/list",
  },
];
const NoteMenuitems = [
  {
    title: "Home",
    icon: AddToPhotosOutlinedIcon,
    href: "/admin/NOTE_DEPARTMENT/home",
  },
  {
    title: "Add Course",
    icon: AddToPhotosOutlinedIcon,
    href: "/admin/NOTE_DEPARTMENT/course/add",
  },
  {
    title: "Course List",
    icon: AspectRatioOutlinedIcon,
    href: "/admin/NOTE_DEPARTMENT/course/list",
  },
  {
    title: "Add Course Category",
    icon: AspectRatioOutlinedIcon,
    href: "/admin/NOTE_DEPARTMENT/courseCategory/add",
  },
  {
    title: "Course Category List",
    icon: AspectRatioOutlinedIcon,
    href: "/admin/NOTE_DEPARTMENT/courseCategory/list",
  },
  {
    title: "Add Teacher",
    icon: AspectRatioOutlinedIcon,
    href: "/admin/NOTE_DEPARTMENT/teacher/add",
  },
  {
    title: "Teacher List",
    icon: AspectRatioOutlinedIcon,
    href: "/admin/NOTE_DEPARTMENT/teacher/list",
  },
];
const AccountMenuitems = [
  {
    title: "Home",
    icon: AddToPhotosOutlinedIcon,
    href: "/admin/ACCOUNT_DEPARTMENT/home",
  },
  {
    title: "Create Payment Report",
    icon: AddToPhotosOutlinedIcon,
    href: "/admin/ACCOUNT_DEPARTMENT/report/add",
  },
  {
    title: "Reports List",
    icon: AspectRatioOutlinedIcon,
    href: "/admin/ACCOUNT_DEPARTMENT/report/list",
  },
  {
    title: "Course Report List",
    icon: AspectRatioOutlinedIcon,
    href: "/admin/ACCOUNT_DEPARTMENT/courseReport/list",
  },
];
const AdmissionMenuitems = [
  {
    title: "Home",
    icon: AddToPhotosOutlinedIcon,
    href: "/admin/ADMISSION_DEPARTMENT/home",
  },
  {
    title: "User List",
    icon: AddToPhotosOutlinedIcon,
    href: "/admin/ADMISSION_DEPARTMENT/user/list",
  },
  {
    title: "Register New User",
    icon: AddToPhotosOutlinedIcon,
    href: "/admin/ADMISSION_DEPARTMENT/user/register",
  },
  {
    title: "Enroll Request List",
    icon: AddToPhotosOutlinedIcon,
    href: "/admin/ADMISSION_DEPARTMENT/user/enrollRequest/list",
  },
];
const MarketingMenuitems = [
  {
    title: "Home",
    icon: AddToPhotosOutlinedIcon,
    href: "/admin/MARKETING_DEPARTMENT/home",
  },
  {
    title: "Post Article",
    icon: AddToPhotosOutlinedIcon,
    href: "/admin/MARKETING_DEPARTMENT/articles/post",
  },
  {
    title: "Enabled Articles List",
    icon: AspectRatioOutlinedIcon,
    href: "/admin/MARKETING_DEPARTMENT/articles/enabledList",
  },
  {
    title: "Disabled Articles List",
    icon: AspectRatioOutlinedIcon,
    href: "/admin/MARKETING_DEPARTMENT/articles/disabledList",
  },
  {
    title: "Create Vacancy",
    icon: AddToPhotosOutlinedIcon,
    href: "/admin/MARKETING_DEPARTMENT/vacancy/create",
  },
  {
    title: "Vacancy List",
    icon: AspectRatioOutlinedIcon,
    href: "/admin/MARKETING_DEPARTMENT/vacancy/list",
  },
  {
    title: "Add PopUp Images",
    icon: AddToPhotosOutlinedIcon,
    href: "/admin/MARKETING_DEPARTMENT/popUpImage/add",
  },
  {
    title: "View PopUp Images",
    icon: AspectRatioOutlinedIcon,
    href: "/admin/MARKETING_DEPARTMENT/popUpImage/list",
  },
  {
    title: "Add Testimonial",
    icon: AddToPhotosOutlinedIcon,
    href: "/admin/MARKETING_DEPARTMENT/testimonial",
  },
  {
    title: "Testimonial List",
    icon: AspectRatioOutlinedIcon,
    href: "/admin/MARKETING_DEPARTMENT/testimonial-list",
  },
];
const SuperAdminMenuitems = [
  {
    title: "Home",
    icon: AddToPhotosOutlinedIcon,
    href: "/admin/SUPER_ADMIN/home",
  },
  {
    title: "Register Admin",
    icon: AddToPhotosOutlinedIcon,
    href: "/admin/SUPER_ADMIN/register",
  },
  {
    title: "View User Messages",
    icon: AddToPhotosOutlinedIcon,
    href: "/admin/SUPER_ADMIN/viewMessages",
  },
  {
    title: "Add Banner Image",
    icon: AddToPhotosOutlinedIcon,
    href: "/admin/SUPER_ADMIN/bannerImage/add",
  },
  {
    title: "View Banner Images",
    icon: AddToPhotosOutlinedIcon,
    href: "/admin/SUPER_ADMIN/bannerImage/list",
  },
  {
    title: "Admin List",
    icon: AspectRatioOutlinedIcon,
    href: "/admin/SUPER_ADMIN/admin/list",
  },
];
const DefaultMenuitems = [
  {
    title: "Home",
    icon: DashboardOutlinedIcon,
    href: "/admin/home",
  },
];

const getMenuItems = (role) => {
  switch (role) {
    case "SUPER_ADMIN":
      return SuperAdminMenuitems;
    case "EXAM_DEPARTMENT":
      return ExamMenuitems;
    case "NOTE_DEPARTMENT":
      return NoteMenuitems;
    case "ACCOUNT_DEPARTMENT":
      return AccountMenuitems;
    case "ADMISSION_DEPARTMENT":
      return AdmissionMenuitems;
    case "MARKETING_DEPARTMENT":
      return MarketingMenuitems;
    default:
      return DefaultMenuitems;
  }
};

export default getMenuItems;
