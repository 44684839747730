import React, { useEffect, useState } from "react";
import BASE_URL from "../../subcomponents/Config";
import useFormValidation from "../../Hooks/useFormValidation";
import {
	TextField,
	Button,
	CircularProgress,
	Box,
	Grid,
	Typography,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { useCookies } from "react-cookie";

const initialFormState = {
	firstName: "",
	lastName: "",
	mobileNumber: "",
	image: null,
};

const AddTeacher = () => {
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const [cookies] = useCookies(["adminToken", "adminRoles"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");

	const {
		formData,
		setErrors,
		errors,
		handleChange,
		validateForm,
		setFormData,
		handleFileChange,
	} = useFormValidation(initialFormState);

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const validationErrors = validateForm(formData);
		if (Object.keys(validationErrors).length === 0) {
			const data = new FormData();
			data.append("firstName", formData.firstName);
			data.append("lastName", formData.lastName);
			data.append("mobileNumber", formData.mobileNumber);
			data.append("image", formData.image);
			setLoading(true);
			try {
				const response = await fetch(`${BASE_URL}/api/v1/teacher/addTeacher`, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${adminToken}`,
					},
					body: data,
				});

				if (response.ok) {
					const userData = await response.json();
					toast.success(userData.message);
					setFormData(initialFormState);
					setLoading(false);
				} else {
					const error = await response.json();
					setError(error.message);
					setLoading(false);
				}
			} catch (error) {
				console.log(error.message);
			}
		} else {
			setErrors(validationErrors);
		}
	};

	return (
		<Box
			className="form"
			sx={{ maxWidth: 500, mx: "auto", p: 3, boxShadow: 3, borderRadius: 2 }}
		>
			<ToastContainer position="top-center" autoClose={1500} />
			<Typography
				variant="h3"
				component="h3"
				textAlign="center"
				marginBottom={2}
			>
				Add New Teacher
			</Typography>
			<form onSubmit={handleSubmit}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							label="First Name"
							name="firstName"
							value={formData.firstName}
							onChange={handleChange}
							error={!!errors.firstName}
							helperText={errors.firstName}
							variant="outlined"
							placeholder="Enter your first name"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							label="Last Name"
							name="lastName"
							value={formData.lastName}
							onChange={handleChange}
							error={!!errors.lastName}
							helperText={errors.lastName}
							variant="outlined"
							placeholder="Enter your last name"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							label="Phone/Mobile"
							name="mobileNumber"
							value={formData.mobileNumber}
							onChange={handleChange}
							error={!!errors.mobileNumber}
							helperText={errors.mobileNumber}
							variant="outlined"
							placeholder="Enter your phone number"
						/>
					</Grid>
					<Grid item xs={12}>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								border: "1px solid #ccc",
								padding: "0.4rem",
								borderRadius: "5px",
							}}
						>
							<Typography
								variant="body1"
								sx={{ flexGrow: 1, color: "gray", padding: 0 }}
							>
								Select Image:
							</Typography>
							<input
								id="file-upload"
								type="file"
								onChange={handleFileChange}
								accept="image/*"
								style={{ display: "none" }} // Hides the default file input UI
								name="image"
							/>
							{formData.image && (
								<Typography
									variant="body2"
									sx={{
										marginTop: "10px",
										color: "gray",
										margin: "1px",
										// padding: 0,
									}}
								>
									{formData.image.name}
								</Typography>
							)}
							<label htmlFor="file-upload">
								<Button variant="outlined" component="span">
									Select
								</Button>
							</label>
						</Box>

						{errors.image && (
							<span style={{ color: "red" }}>{errors.image}</span>
						)}
					</Grid>
					{error && (
						<Grid item xs={12}>
							<Typography color="error" textAlign="center">
								{error}
							</Typography>
						</Grid>
					)}
					<Grid item xs={12}>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="secondary"
							sx={{ mt: 2 }}
							disabled={loading}
						>
							{loading ? (
								<Box sx={{ display: "flex", justifyContent: "center" }}>
									<CircularProgress size={24} />
								</Box>
							) : (
								"Submit"
							)}
						</Button>
					</Grid>
				</Grid>
			</form>
		</Box>
	);
};

export default AddTeacher;
