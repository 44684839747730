// CourseCategory.js
import React, { useState, useEffect } from "react";
import BASE_URL from "../../subcomponents/Config";
import "../../Styles/Components/CourseCategory.scss";
import useLoading from "../../Hooks/useLoading";
import Loading from "./Loading";

const CourseCategory = ({
	title,
	selectedOption,
	setSelectedOption,
	setTitle,
	type,
}) => {
	const { loading, showLoading, hideLoading } = useLoading();
	const [error, setError] = useState("");
	const [Categories, setCategories] = useState([]);

	const GetallData = async () => {
		showLoading();
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/course/courseList/exam?examType=${type}`,
				{
					method: "GET",
				}
			);
			if (response.ok) {
				const allData = await response.json();
				setCategories(allData.courses);
				hideLoading();
			} else {
				const error = await response.json();
				setError(error.message);
				hideLoading();
			}
		} catch (error) {
			console.error("Error fetching data.", error);
		}
	};

	useEffect(() => {
		GetallData();
	}, [type]);

	const handleAllData = () => {
		setTitle("");
	};
	const handleCategoryClick = (title) => {
		setTitle(title);
	};

	return (
		<div>
			{loading ? <Loading /> : null}
			{error ? (
				<div style={{ color: "red" }}>{error}</div>
			) : (
				<>
					<div className="courseList-category">
						<h4>Categories</h4>

						<div className="radio-category">
							<div onClick={handleAllData} className="data-title-wrapper">
								<input
									type="radio"
									name="course"
									value="1"
									checked={selectedOption === "1"}
									onChange={() => setSelectedOption("1")}
								/>
								<span className="data-title">All</span>
							</div>
							{Categories &&
								Categories.map((data, index) => (
									<div
										key={index}
										onClick={() => handleCategoryClick(data.title)}
										className="data-title-wrapper"
									>
										<input
											type="radio"
											name="course"
											value={data.title}
											checked={title === data.title}
											onChange={() => handleCategoryClick(data.title)}
										/>
										<span className="data-title">{data.title}</span>
									</div>
								))}
						</div>
					</div>
					{/* Category select for tablet/mobile */}
					<div className="select-category">
						<select value={title} onChange={(e) => setTitle(e.target.value)}>
							<option value="" disabled selected>
								Select Course Category
							</option>
							<option value="">All</option>
							{Categories &&
								Categories.map((option, index) => (
									<option key={index} value={option.title}>
										{option.title}
									</option>
								))}
						</select>
					</div>
				</>
			)}
		</div>
	);
};

export default CourseCategory;
