import React, { useEffect, useState } from "react";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Box, CircularProgress } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "../../Styles/Courses/FullDetailsCourse.scss";
import ReactPlayer from "react-player";
import Instructor from "../components/Instructor";
import Overview from "../components/Overview";
import Loading from "../components/Loading";
import useLoading from "../../Hooks/useLoading";

const FullDetailsCourse = ({ courseId }) => {
	const { id } = useParams();
	const nav = useNavigate();
	const { loading, showLoading, hideLoading } = useLoading();
	const [data, setData] = useState({});
	const [activeTab, setActiveTab] = useState("overview");

	const [cookies, setCookie] = useCookies(["userToken", "userRole", "userId"]);
	const [token, setToken] = useState(cookies.userToken || "");
	const [role, setRole] = useState(cookies.userRole || "");
	const [userId, setUserId] = useState(cookies.userId || "");

	useEffect(() => {
		setToken(cookies.userToken || "");
		setRole(cookies.userRole || "");
		setUserId(cookies.userId || "");
	}, [cookies]);

	useEffect(() => {
		DetailsCourse();
		window.scrollTo(0, 0);
	}, []);

	const handleViewDetails = (applied) => {
		if (token === "") {
			nav("/login");
		} else if (applied === true) {
			nav(`/course/myCourseDetails/${id}`);
			window.scrollTo(0, 0);
		}
	};

	const handleEnrollNow = async () => {
		if (token === "") {
			nav("/login");
		} else {
			showLoading();
			try {
				const response = await fetch(
					`${BASE_URL}/api/v1/enrollmentRequest/send/user/${userId}/course/${id}`,
					{
						method: "POST",
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);
				if (response.ok) {
					const apiData = await response.json();
					toast.success(apiData.message);
					hideLoading();
				} else {
					const error = await response.json();
					toast.error(error.message);
					hideLoading();
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		}
	};

	const DetailsCourse = async () => {
		showLoading();
		try {
			let url = "";
			let options = {
				method: "GET",
			};

			if (token === "") {
				url = `${BASE_URL}/api/v1/course/${id}`;
			} else {
				url = `${BASE_URL}/api/v1/course/${id}/loggedInUser`;

				options.headers = {
					Authorization: `Bearer ${token}`,
				};
			}
			const response = await fetch(url, options);
			if (response.ok) {
				const getApi = await response.json();
				setData(getApi.course);
				hideLoading();
			} else {
				const error = await response.json();
				console.log(error);
				hideLoading();
			}
		} catch (error) {
			console.error("Error fetching data.", error);
		}
	};

	const handleOverview = (tab) => {
		setActiveTab(tab);
	};

	return (
		<>
			<ToastContainer position="top-center" autoClose="2000" />
			<Navbar />
			{loading ? <Loading /> : null}
			{!loading && (
				<main>
					<div className="ourse_details">
						<h3>Course Description</h3>
						<hr />
						{data && (
							<div className="main_course">
								<div className="description">
									<div className="title_fee">
										<h5>{data.title}</h5>
										<h6>Course Fee {data.courseFee}</h6>
									</div>
									<div className="course_image">
										<img src={data.imageUrl} alt="image" />
									</div>

									<div className="overButton">
										<button
											onClick={() => handleOverview("overview")}
											className={activeTab === "overview" ? "active" : ""}
										>
											Overview
										</button>
										<button
											onClick={() => handleOverview("instructor")}
											className={activeTab === "instructor" ? "active" : ""}
										>
											View Instructor
										</button>
									</div>
									<div className="selectedDetails">
										{activeTab === "overview" ? (
											<Overview />
										) : (
											<Instructor courseId={courseId} />
										)}
									</div>

									<div className="upcommingExam">
										Upcoming weekly Exam on: {data.weeklyExamDate}
									</div>
								</div>
								<div>
									<div className="videos">
										<ReactPlayer
											url={data.demoVideoUrl}
											controls={true}
											playing={true}
											config={{
												file: {
													attributes: {
														controlsList: "nodownload",
													},
												},
											}}
											width="21rem"
											height="auto"
										/>

										<div className="lectures_no">
											<div className="lecture">Lectures</div>

											<div>{data.notesCount}</div>
										</div>
										<hr />
										<div className="videos_no">
											<div>
												<div className="avilable_videos">Availables Videos</div>
											</div>
											<div>{data.videosCount}</div>
										</div>
										<div>
											{data.applied ? (
												<button onClick={() => handleViewDetails(data.applied)}>
													View Materials
												</button>
											) : (
												<button onClick={() => handleEnrollNow()}>
													Enroll Now
												</button>
											)}
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</main>
			)}
			<Footer />
		</>
	);
};

export default FullDetailsCourse;
