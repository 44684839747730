// CreateQuestionBankExam.js  Add Daily Exam

import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useCookies } from "react-cookie";
import BASE_URL from "../../subcomponents/Config";
import {
	TextField,
	Button,
	Typography,
	Container,
	Box,
	CircularProgress,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Slider,
} from "@mui/material";
import useFormValidation from "../../Hooks/useFormValidation";
import { toast, ToastContainer } from "react-toastify";
import CourseSelect from "../adminComponents/Api/CourseSelect";
import SelectQuestionBank from "../adminComponents/Api/SelectQuestionBank";
import QuestionSelect from "../adminComponents/Api/QuestionSelect";
import useQuestionFormValidation from "../../Hooks/useQuestionFormValidation";

const AdminLoginForm = styled("form")({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	border: "1px solid #ccc",
	padding: "20px",
	borderRadius: "5px",
});

const initialFormState = {
	details: "",
	examName: "",
	courseId: "",
	totalTime: "",
	questionIds: [],
	questionBankId: "",
	// examType: "",
	file: null,
	negativeMarking: false,
	negativeMarkingPercent: 0,
	free: false,
};

const CreateQuestionBankExam = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [cookies] = useCookies(["adminToken", "adminRoles"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	const [isFreeExam, setIsFreeExam] = useState(false);

	const {
		formData,
		setFormData,
		setErrors,
		errors,
		handleChange,
		validateForm,
		handleFileChange,
		setCurrentQuestionIndex,
	} = useQuestionFormValidation(initialFormState);

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	const handleSelectCourse = (courseId) => {
		setFormData({ ...formData, courseId });
		setErrors((prevErrors) => ({
			...prevErrors,
			courseId: "",
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const validationErrors = validateForm(formData);

		if (Object.keys(validationErrors).length === 0) {
			setLoading(true);

			const formDataToSubmit = new FormData();
			formDataToSubmit.append("details", formData.details);
			formDataToSubmit.append("examName", formData.examName);
			formDataToSubmit.append("courseId", formData.courseId);
			formDataToSubmit.append("totalTime", formData.totalTime);
			// formDataToSubmit.append("examType", formData.examType);
			formDataToSubmit.append("file", formData.file);
			formData.questionIds.forEach((question) => {
				formDataToSubmit.append("questionIds", question);
			});
			formDataToSubmit.append("questionBankId", formData.questionBankId);

			formDataToSubmit.append("negativeMarking", formData.negativeMarking);
			formDataToSubmit.append("free", formData.free);
			formDataToSubmit.append(
				"negativeMarkingPercent",
				formData.negativeMarkingPercent
			);

			try {
				const response = await fetch(`${BASE_URL}/api/v1/exam/questionBank`, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${adminToken}`,
					},
					body: formDataToSubmit,
				});

				if (response.ok) {
					const courseData = await response.json();
					setLoading(false);
					setError("");
					toast.success(courseData.message);
					setCurrentQuestionIndex(0);
					setFormData(initialFormState);
				} else {
					const error = await response.json();
					setError(error.message);
					setLoading(false);
				}
			} catch (error) {
				console.log(error.message);
			}
		} else {
			setErrors(validationErrors);
		}
	};

	const handleQuestionBank = (questionBankId) => {
		setFormData({ ...formData, questionBankId });
		setErrors((prevErrors) => ({
			...prevErrors,
			questionBankId: "",
		}));
	};
	const handleQuestionSelect = (questionIds) => {
		setFormData({ ...formData, questionIds });
		setErrors((prevErrors) => ({
			...prevErrors,
			questions: "",
		}));
	};

	const handleNegativeMarkingChange = (event) => {
		const { value } = event.target;
		const isNegativeMarking = value === "true"; // Convert string to boolean

		setFormData((prevState) => ({
			...prevState,
			negativeMarking: isNegativeMarking,
			negativeMarkingPercent: isNegativeMarking
				? prevState.negativeMarkingPercent
				: 0, // Set to 0 when false
		}));
	};

	const handlePercentChange = (event) => {
		const { value } = event.target;
		setFormData((prevState) => ({
			...prevState,
			negativeMarkingPercent: Number(value), // Ensure it's a number
		}));
	};

	// Handle the change for the "free" field
	const handleFreeExamChange = (event) => {
		const { value } = event.target;
		setFormData((prevState) => ({
			...prevState,
			free: value === "true", // Convert string to boolean
		}));
	};

	return (
		<>
			<ToastContainer position="top-center" autoClose="2000" />
			<Container maxWidth="xs">
				<AdminLoginForm onSubmit={handleSubmit}>
					<Typography variant="h2" gutterBottom>
						Create Daily Exam
					</Typography>
					{error && (
						<Typography variant="subtitle2" color="error" gutterBottom>
							{error}!!
						</Typography>
					)}
					<Box width="100%" marginBottom="20px">
						<TextField
							label="Exam Name"
							variant="outlined"
							name="examName"
							fullWidth
							value={formData.examName}
							onChange={handleChange}
						/>
						{errors.examName && (
							<span style={{ color: "red" }}>{errors.examName}</span>
						)}
					</Box>

					<Box width="100%" marginBottom="20px">
						<TextField
							label="Exam Details"
							variant="outlined"
							name="details"
							fullWidth
							value={formData.details}
							onChange={handleChange}
						/>
						{errors.details && (
							<span style={{ color: "red" }}>{errors.details}</span>
						)}
					</Box>
					<Box width="100%" marginBottom="20px">
						<CourseSelect
							selectedCourseId={formData.courseId}
							onSelectCourse={handleSelectCourse}
						/>
						<Box>
							{errors.courseId && (
								<span style={{ color: "red" }}>{errors.courseId}</span>
							)}
						</Box>
					</Box>

					{formData.courseId ? (
						<Box width="100%" marginBottom="20px">
							<SelectQuestionBank
								courseId={formData.courseId}
								selectedBankId={formData.questionBankId}
								onSelectBank={handleQuestionBank}
							/>
						</Box>
					) : (
						<Box width="100%" marginBottom="20px">
							<TextField
								select
								label="Select Question Bank"
								variant="outlined"
								fullWidth
								disabled
								value=""
							>
								<option value="">Select a Course First</option>
							</TextField>
						</Box>
					)}
					{formData.questionBankId ? (
						<Box width="100%" marginBottom="20px">
							<QuestionSelect
								selectedBankId={formData.questionBankId}
								selectedQuestions={formData.questions}
								onSelectBank={handleQuestionSelect}
							/>
						</Box>
					) : (
						<Box width="100%" marginBottom="20px">
							<Button color="primary" variant="contained" disabled={true}>
								Select Questions
							</Button>
						</Box>
					)}
					<Box>
						{errors.questionIds && (
							<span style={{ color: "red" }}>{errors.questionIds}</span>
						)}
					</Box>

					{/* Make Free Exam */}
					<Box width="100%" marginBottom="20px">
						<Typography variant="subtitle1">Make Free Exam</Typography>
						<Select
							value={formData.free ? "true" : "false"}
							onChange={handleFreeExamChange}
							name="free"
							fullWidth
						>
							<MenuItem value="true">True</MenuItem>
							<MenuItem value="false">False</MenuItem>
						</Select>
					</Box>
					{/* Negative Marking Dropdown */}
					<Box width="100%" marginBottom="20px">
						<Typography variant="subtitle1">Negative Marking</Typography>
						<Select
							value={formData.negativeMarking ? "true" : "false"}
							onChange={handleNegativeMarkingChange}
							name="negativeMarking"
							fullWidth
						>
							<MenuItem value="true">True</MenuItem>
							<MenuItem value="false">False</MenuItem>
						</Select>
					</Box>
					{formData.negativeMarking && (
						<Box width="100%" marginBottom="20px">
							<Typography variant="subtitle1">
								Negative Marking Percent
							</Typography>
							<Slider
								value={formData.negativeMarkingPercent}
								onChange={handlePercentChange}
								name="negativeMarkingPercent"
								step={5}
								min={0}
								max={100}
								valueLabelDisplay="auto"
								marks
							/>
							{errors.negativeMarkingPercent && (
								<span style={{ color: "red" }}>
									{errors.negativeMarkingPercent}
								</span>
							)}
						</Box>
					)}

					<Box width="100%" marginBottom="20px">
						<TextField
							label="Total Time (in hours)"
							variant="outlined"
							name="totalTime"
							fullWidth
							type="number"
							value={formData.totalTime}
							onChange={handleChange}
						/>
						{errors.totalTime && (
							<span style={{ color: "red" }}>{errors.totalTime}</span>
						)}
					</Box>
					<Box width="100%" marginBottom="20px">
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								border: "1px solid #ccc",
								padding: "10px",
								borderRadius: "5px",
							}}
						>
							<Typography variant="body1" sx={{ flexGrow: 1, color: "gray" }}>
								Select Image:
							</Typography>
							<input
								id="file-upload"
								type="file"
								onChange={handleFileChange}
								accept="image/*"
								style={{ display: "none" }} // Hides the default file input UI
								name="file"
							/>
							{formData.file && (
								<Typography
									variant="body2"
									sx={{ marginTop: "10px", color: "gray", margin: "2px" }}
								>
									{formData.file.name}
								</Typography>
							)}
							<label htmlFor="file-upload">
								<Button variant="outlined" component="span">
									Select
								</Button>
							</label>
						</Box>
						{errors.file && <span style={{ color: "red" }}>{errors.file}</span>}
					</Box>
					<Button type="submit" variant="contained" color="secondary">
						{loading ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "6px",
								}}
							>
								<CircularProgress />
							</Box>
						) : (
							<Box>Add Exam</Box>
						)}
					</Button>
				</AdminLoginForm>
			</Container>
		</>
	);
};

export default CreateQuestionBankExam;
