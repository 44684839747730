import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useCookies } from "react-cookie";
import BASE_URL from "../../subcomponents/Config";
import { useParams } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Container,
  CircularProgress,
  Box,
  Stack,
} from "@mui/material";
import useFormValidation from "../../Hooks/useFormValidation";
import { toast, ToastContainer } from "react-toastify";

const AdminLoginForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: "1px solid #ccc",
  padding: "20px",
  borderRadius: "5px",
});

const initialFormState = {
  name: "",
  testimonial: "",
  image: null,
};

export default function EditTestimonial() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [cookies] = useCookies(["adminToken"]);
  const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
  const { id } = useParams();
  const {
    formData,
    setFormData,
    handleFileChange,
    setErrors,
    errors,
    handleChange,
    validateForm,
    handleFileChangePDF,
  } = useFormValidation(initialFormState);

  useEffect(() => {
    setAdminToken(cookies.adminToken || "");
  }, [cookies]);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/api/v1/testimonial/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${adminToken}`,
          mode: "cors",
        },
      });
      if (response.ok) {
        const apiData = await response.json();
        setFormData(apiData.testimonial);
        setLoading(false);
      } else {
        const error = await response.json();
        setLoading(false);
        setError(error.message);
      }
    } catch (error) {
      console.log("Error fetching data:", error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm(formData);

    if (Object.keys(validationErrors).length === 0) {
      const data = new FormData();
      data.append("name", formData.name);
      data.append("testimonial", formData.testimonial);
      data.append("image", formData.image);

      setLoading(true);
      try {
        const response = await fetch(
          `${BASE_URL}/api/v1/testimonial/updateTestimonial/${id}`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${adminToken}`,
            },
            body: data,
          }
        );

        if (response.ok) {
          const userData = await response.json();
          toast.success(userData.message);
          setLoading(false);
          setFormData(initialFormState);
        } else {
          const error = await response.json();
          setError(error.message);
          setLoading(false);
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <Container maxWidth="xs">
      <ToastContainer position="top-center" autoClose={1500} />
      <AdminLoginForm onSubmit={handleSubmit}>
        <Typography variant="h2" gutterBottom>
          Edit Testimonial
        </Typography>
        {error && (
          <Typography variant="subtitle2" color="error" gutterBottom>
            {error}!!
          </Typography>
        )}
        <Box width="100%" marginBottom="20px">
          <TextField
            label="Name"
            variant="outlined"
            name="name"
            fullWidth
            value={formData.name}
            onChange={handleChange}
          />
          {errors.name && <span style={{ color: "red" }}>{errors.name}</span>}
        </Box>
        <Box width="100%" marginBottom="20px">
          <TextField
            label="Testimonial"
            variant="outlined"
            name="testimonial"
            fullWidth
            multiline
            value={formData.testimonial}
            onChange={handleChange}
          />
          {errors.testimonial && (
            <span style={{ color: "red" }}>{errors.testimonial}</span>
          )}
        </Box>

        {/* this is for images */}
        <Box width="100%" marginBottom="20px">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <Typography variant="body1" sx={{ flexGrow: 1, color: "gray" }}>
              Select Testimonial Image:
            </Typography>
            <input
              id="image-upload"
              type="file"
              onChange={handleFileChange}
              accept="image/*"
              style={{ display: "none" }}
              name="image"
            />
            {formData.image && (
              <Typography
                variant="body2"
                sx={{ marginTop: "10px", color: "gray", margin: "2px" }}
              >
                {formData.image.name}
              </Typography>
            )}
            <label htmlFor="image-upload">
              <Button variant="outlined" component="span">
                Select
              </Button>
            </label>
          </Box>

          {errors.imageUrl && (
            <span style={{ color: "red" }}>{errors.imageUrl}</span>
          )}
        </Box>

        <Button type="submit" variant="contained" color="secondary">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "10px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box>Update Testimonial</Box>
          )}
        </Button>
      </AdminLoginForm>
    </Container>
  );
}
