import React, { useEffect, useState, useContext } from "react";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import { Context } from "../../subcomponents/Context";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import { SlArrowLeft } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { ToastContainer, toast } from "react-toastify";
import ArticlesForHomepage from "./ArticlesForHomepage";
import { Box, CircularProgress } from "@mui/material";
import BannerImage from "./BannerImage";
import "../../Styles/Home/Homepage.scss";
import useLoading from "../../Hooks/useLoading";
import Loading from "../components/Loading";
import PopUpImage from "../components/PopUpImage";

const Home = () => {
	const nav = useNavigate();
	const { message, setMessage } = useContext(Context);
	const { loading, showLoading, hideLoading } = useLoading();
	const [courses, setcourses] = useState([]);
	const [courseId, setCourseId] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [itemsPerPage] = useState(3);

	const [cookies, setCookie] = useCookies(["userToken", "userRole"]);
	const [token, setToken] = useState(cookies.userToken || "");
	const [role, setRole] = useState(cookies.userRole || "");

	useEffect(() => {
		setToken(cookies.userToken || "");
		setRole(cookies.userRole || "");
	}, [cookies]);

	useEffect(() => {
		GetTrending();
	}, [currentPage]);

	const GetTrending = async () => {
		showLoading();
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/course/courseList/trending?page=${currentPage}&size=${itemsPerPage}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
						mode: "cors",
					},
				}
			);
			if (response.ok) {
				const courseApi = await response.json();
				setcourses(courseApi.courses);
				setTotalPages(courseApi.totalPages);
				hideLoading();
			} else {
				const error = await response.json();
				console.log(error);
				hideLoading();
			}
		} catch (error) {
			showLoading();
		}
	};

	const handleViewDetails = () => {
		nav(`/course/detailscourse/${courseId}`);
	};

	function handlePageClick(pageNumber) {
		setCurrentPage(pageNumber);
	}

	const pageNumbers = [];
	for (let i = 1; i <= totalPages; i++) {
		pageNumbers.push(i);
	}

	{
		pageNumbers &&
			pageNumbers.map((pageNumber) => (
				<button
					disabled={currentPage === pageNumber}
					key={pageNumber}
					onClick={() => handlePageClick(pageNumber)}
				></button>
			));
	}

	useEffect(() => {
		if (message) {
			toast.success(message);
			setMessage("");
		}
	}, [message]);

	return (
		<>
			<ToastContainer position="top-center" autoClose="1000" />
			<Navbar />
			{loading ? <Loading /> : null}
			{!loading && (
				<div>
					<BannerImage />
					<PopUpImage />

					<div>
						<div className="home_container">
							<div className="heading">
								<div className="prev_next">
									<button
										onClick={() => setCurrentPage(currentPage - 1)}
										disabled={currentPage === 1}
									>
										<span>
											<SlArrowLeft />
										</span>
									</button>
								</div>

								<div>
									<h3>Trending Course</h3>
								</div>
								<div className="prev_next">
									<button
										onClick={() => setCurrentPage(currentPage + 1)}
										disabled={currentPage >= totalPages}
									>
										<span>
											<SlArrowRight />
										</span>
									</button>
								</div>
							</div>

							<div className="tranding_course">
								{courses &&
									courses.map((data, index) => (
										<div
											className="course_image"
											key={index}
											onMouseEnter={() => setCourseId(data.id)}
											onMouseLeave={() => setCourseId(null)}
										>
											<div className="display_center">
												<img src={data.imageUrl} alt="images" />
											</div>
											<h6>{data.title}</h6>
											<div className="hide_show">
												{courseId === data.id ? (
													<div className="para_btn">
														<div className="title">
															<div className="trending_title">{data.title}</div>
														</div>
														<div className="trending_details">
															{data.details}
														</div>
														<div className="buttum">
															<div className="fee">
																<div className="fee_amonunt">
																	<span>Rs</span> {data.courseFee}
																</div>
															</div>
															<button
																onClick={() => handleViewDetails(courseId)}
															>
																View Details
															</button>
														</div>
													</div>
												) : null}
											</div>
										</div>
									))}
								{loading ? (
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											height: "200px",
										}}
									>
										<CircularProgress />
									</Box>
								) : null}
							</div>
						</div>
					</div>

					<div>
						<ArticlesForHomepage />
					</div>
				</div>
			)}
			<Footer />
		</>
	);
};

export default Home;
