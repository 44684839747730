import React, { useEffect, useState } from "react";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import { SlArrowLeft } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { Box, CircularProgress } from "@mui/material";
import SearchBar from "../components/Search";
import { toast, ToastContainer } from "react-toastify";
import "../../Styles/Courses/course.scss";
import { FaRupeeSign } from "react-icons/fa6";
import Loading from "../components/Loading";
import useLoading from "../../Hooks/useLoading";

const Courses = () => {
	const nav = useNavigate();
	const { loading, showLoading, hideLoading } = useLoading();
	const [courses, setcourses] = useState([]);
	const [courseId, setCourseId] = useState(false);
	const [searchError, setSearchError] = useState("");
	const [Categories, setCategories] = useState([]);

	const [title, setTitle] = useState("");
	const [error, setError] = useState("");
	const [selectedOption, setSelectedOption] = useState("1");

	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [itemsPerPage] = useState(12);

	const [cookies, setCookie] = useCookies(["userToken", "userRole"]);
	const [token, setToken] = useState(cookies.userToken || "");
	const [role, setRole] = useState(cookies.userRole || "");

	useEffect(() => {
		setToken(cookies.userToken || "");
		setRole(cookies.userRole || "");
	}, [cookies]);

	const handleCategoryClick = (title) => {
		setTitle(title);
	};

	const AllData = () => {
		setTitle("");
	};

	useEffect(() => {
		GetallData();
		window.scrollTo(0, 0);
	}, []);

	const GetallData = async () => {
		showLoading();
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/courseCategory/web/categories`,
				{
					method: "GET",
				}
			);
			if (response.ok) {
				const allData = await response.json();
				// console.log(allData);
				setCategories(allData.Categories);
				hideLoading();
			} else {
				const error = await response.json();
				console.log(error);
				hideLoading();
			}
		} catch (error) {
			console.error("Error fetching data.", error);
		}
	};

	useEffect(() => {
		GetCourse();
		window.scrollTo(0, 0);
	}, [currentPage, title, role]);

	const GetCourse = async () => {
		showLoading();
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/course/courses?categoryTitle=${title}&page=${currentPage}&size=${itemsPerPage}`
			);

			if (response.ok) {
				const courseApi = await response.json();
				setcourses(courseApi.courses);
				if (token) {
					setCookie("userRole", `${courseApi.userRole}`, {
						path: "/",
					});
				}
				setError("");
				setTotalPages(courseApi.totalPages);
				hideLoading();
			} else {
				const error = await response.json();
				setError(error.message);
				setcourses([]);
				console.log(error);
				hideLoading();
			}
		} catch (error) {
			console.error("Error fetching data.", error);
		}
	};

	const handleViewDetails = () => {
		nav(`/course/detailscourse/${courseId}`);
	};

	const handleSearch = async (searchTerm) => {
		if (searchTerm === "") {
			GetCourse();
		} else {
			try {
				const res = await fetch(
					`${BASE_URL}/api/v1/course/search?keyword=${searchTerm}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					}
				);
				if (!res.ok) {
					const error = await res.json();
					setSearchError(error.message);
				} else {
					const data = await res.json();
					setcourses(data);
				}
			} catch (error) {
				console.log(error.message);
			}
		}
	};

	function handlePageClick(pageNumber) {
		setCurrentPage(pageNumber);
		window.scrollTo(0, 0);
	}

	const pageNumbers = [];
	for (let i = 1; i <= totalPages; i++) {
		pageNumbers.push(i);
	}

	return (
		<>
			<ToastContainer position="top-center" autoClose="2000" />
			<Navbar />
			{loading ? <Loading /> : null}
			{!loading && (
				<main>
					<div className="courses_page">
						<div className="search_rgid">
							<div className="search">
								<SearchBar onSearch={handleSearch} />

								{searchError && (
									<div
										style={{
											color: "red",
											fontSize: "1.5rem",
											textAlign: "center",
										}}
									>
										{searchError}
									</div>
								)}
							</div>
							<div>
								<h3>Courses we offer</h3>
							</div>
							<div></div>
						</div>
						<hr />

						<div className="filter_data">
							<div className="filter_container">
								<h4>Categories</h4>
								<div onClick={AllData} className="category">
									<input
										type="radio"
										name="course"
										value="1"
										checked={selectedOption === "1"}
										onChange={() => setSelectedOption("1")}
									></input>
									All
								</div>

								{Categories &&
									Categories.map((data, index) => (
										<div
											key={index}
											onClick={() => handleCategoryClick(data.title)}
											// className="data_title"
											className="data-title-wrapper"
										>
											<input
												type="radio"
												name="course"
												value={data.title}
												checked={title === data.title}
												onChange={() => handleCategoryClick(data.title)}
											></input>
											<div className="data-title">{data.title}</div>
										</div>
									))}
							</div>
							{/*Category select for tablet size device and less*/}
							<div className="select-category">
								<select
									value={title}
									onChange={(e) => setTitle(e.target.value)}
								>
									<option value="" disabled selected>
										Select Course Category
									</option>
									<option value="">All</option>
									{Categories &&
										Categories.map((option, index) => {
											return (
												<option key={index} value={option.title}>
													{option.title}
												</option>
											);
										})}
								</select>
							</div>

							{error && (
								<div
									style={{
										color: "red",
										fontSize: "1.2rem",
										textAlign: "center",
									}}
								>
									{error}
								</div>
							)}

							<div className="grid_image">
								{courses &&
									courses.map((data, index) => (
										<div
											className="course_image"
											key={index}
											onMouseEnter={() => setCourseId(data.id)}
											onMouseLeave={() => setCourseId(null)}
										>
											<div className="display_center">
												<img src={data.imageUrl} alt="images" />
											</div>
											<h6>{data.title}</h6>
											<div className="hide_show">
												{courseId === data.id ? (
													<div className="para_btn">
														<div className="title">
															<div className="course_title">{data.title}</div>
														</div>
														<div className="course_description">
															{data.details}
														</div>
														<div className="buttum">
															<div className="fee">
																<div className="fee_amonunt">
																	<span>Rs</span> {data.courseFee}
																</div>
															</div>
															<button onClick={handleViewDetails}>
																View Details
															</button>
														</div>
													</div>
												) : null}
											</div>
										</div>
									))}
							</div>
						</div>

						<div className="pagination">
							<div className="pagination_prev">
								<button
									onClick={() => setCurrentPage(currentPage - 1)}
									disabled={currentPage === 1}
								>
									<span>
										<SlArrowLeft />
									</span>
								</button>
							</div>

							<div className="numbers">
								{pageNumbers &&
									pageNumbers.map((pageNumber) => (
										<button
											disabled={currentPage === pageNumber}
											key={pageNumber}
											onClick={() => handlePageClick(pageNumber)}
										>
											{pageNumber}
										</button>
									))}
							</div>

							<div className="pagination_prev">
								<button
									onClick={() => setCurrentPage(currentPage + 1)}
									disabled={currentPage >= totalPages}
								>
									<span>
										<SlArrowRight />
									</span>
								</button>
							</div>
						</div>
					</div>
				</main>
			)}

			<Footer />
		</>
	);
};

export default Courses;
