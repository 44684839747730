import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useCookies } from "react-cookie";
import BASE_URL from "../../subcomponents/Config";
import {
	TextField,
	Button,
	Typography,
	Container,
	Box,
	CircularProgress,
	Stack,
} from "@mui/material";
import useFormValidation from "../../Hooks/useFormValidation";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const AdminLoginForm = styled("form")({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	border: "1px solid #ccc",
	padding: "20px",
	borderRadius: "5px",
});

const initialFormState = {
	files: [],
};

const AddCourseMaterial = () => {
	const nav = useNavigate();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [cookies] = useCookies(["adminToken", "adminRoles"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	const { id } = useParams();
	const {
		formData,
		setFormData,
		handleFilesChange,
		setErrors,
		errors,
		validateForm,
	} = useFormValidation(initialFormState);

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		const validationErrors = validateForm(formData);

		if (Object.keys(validationErrors).length === 0) {
			const data = new FormData();
			formData.files.forEach((file) => {
				data.append("files", file);
			});
			setLoading(true);
			try {
				const response = await fetch(
					`${BASE_URL}/api/v1/courses/${id}/uploadStudyMaterial`,
					{
						method: "POST",
						headers: {
							Authorization: `Bearer ${adminToken}`,
						},
						body: data,
					}
				);

				if (response.ok) {
					const userData = await response.json();
					toast.success(userData.message);
					setLoading(false);
					setFormData(initialFormState);
				} else {
					const error = await response.json();
					setError(error.message);
					setLoading(false);
				}
			} catch (error) {
				console.log(error.message);
			}
		} else {
			setErrors(validationErrors);
		}
	};
	function Back() {
		nav(-1);
	}

	return (
		<>
			<ToastContainer position="top-center" autoClose="2000" />
			<Container maxWidth="xs">
				<Button
					onClick={() => Back()}
					variant="outlined"
					color="secondary"
					style={{ marginBottom: "2rem" }}
				>
					Back
				</Button>
				<AdminLoginForm onSubmit={handleSubmit}>
					<Typography variant="h2" gutterBottom>
						Add New Course Material
					</Typography>
					{error && (
						<Typography variant="subtitle2" color="error" gutterBottom>
							{error}!!
						</Typography>
					)}
					<Box width="100%" marginBottom="20px">
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								border: "1px solid #ccc",
								padding: "10px",
								borderRadius: "5px",
							}}
						>
							<Typography variant="body1" sx={{ flexGrow: 1, color: "gray" }}>
								Select Files:
							</Typography>
							<input
								id="file-upload"
								type="file"
								multiple
								onChange={handleFilesChange}
								accept="application/pdf,video/*"
								style={{ display: "none" }} // Hides the default file input UI
								name="files"
							/>

							<label htmlFor="file-upload">
								<Button variant="outlined" component="span">
									Select
								</Button>
							</label>
						</Box>
						{formData.files &&
							formData.files.map((material) => (
								<Typography
									variant="body2"
									sx={{ marginTop: "10px", color: "gray", margin: "2px" }}
								>
									{material.name}
								</Typography>
							))}

						{errors.files && (
							<span style={{ color: "red" }}>{errors.files}</span>
						)}
					</Box>
					<Button type="submit" variant="contained" color="secondary">
						{loading ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "10px",
								}}
							>
								<CircularProgress />
							</Box>
						) : (
							<Box >Add Material</Box>
						)}
					</Button>
				</AdminLoginForm>
			</Container>
		</>
	);
};

export default AddCourseMaterial;
