import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  CircularProgress,
  Button,
} from "@mui/material";

export default function UserReportDetails() {
  const { email } = useParams();
  const [data, setData] = useState({});
  const [userReport, setUserReport] = useState([]);
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cookies] = useCookies(["adminToken"]);
  const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
  useEffect(() => {
    setAdminToken(cookies.adminToken || "");
  }, [cookies]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${BASE_URL}/api/v1/account/userReport?email=${email}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        }
      );
      if (response.ok) {
        const apiData = await response.json();
        setUserReport(apiData.userReport);
        setData(apiData);
        setLoading(false);
      } else {
        const error = await response.json();
        setError(error.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function Back() {
		nav(-1);
	}

  return (
    <Box>
      <Button
          onClick={() => Back()}
          variant="outlined"
          color="secondary"
          style={{ marginBottom: "0.2rem", marginLeft: "1.15rem" }}
        >
          Back
        </Button>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h3">User Account Reports</Typography>
          <Box
            sx={{
              overflow: {
                xs: "auto",
                sm: "unset",
              },
            }}
          >
            <Table>
              <TableRow>
              <TableCell>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "500",
                      paddingLeft: "0",
                      textAlign: "left",
                    }}
                  >
                    User Name: {data.userName}
                  </Typography>
                </TableCell>
              </TableRow>
            </Table>

            <Table
              aria-label="simple table"
              sx={{
                mt: 3,
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "10px" }}>
                    <Typography color="textSecondary" variant="h5">
                      S.N.
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="h5">
                      Course Title
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="h5">
                      Deposited Amount
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userReport.map((report, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "500",
                          paddingLeft: "0",
                        }}
                      >
                        {index + 1}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "500",
                          paddingLeft: "0",
                        }}
                      >
                        {report.courseTitle}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "500",
                          paddingLeft: "0",
                        }}
                      >
                        {report.depositedAmount}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Table>
              <TableRow>
                <TableCell>
                  <Typography variant="h5">
                    Total Amount
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "500",
                      marginRight: "4rem",
                      // textAlign: "center",
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {data.totalAmount}
                  </Typography>
                </TableCell>
              </TableRow>
            </Table>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : null}
            {error ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                {error}
              </Box>
            ) : null}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
