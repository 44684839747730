// EditReport.js

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useCookies } from "react-cookie";
import BASE_URL from "../../subcomponents/Config";
import { TextField, Button, Typography, Container, Box } from "@mui/material";

import useFormValidation from "../../Hooks/useFormValidation";
import { toast, ToastContainer } from "react-toastify";
import CourseSelect from "../adminComponents/Api/CourseSelect";

const AdminLoginForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: "1px solid #ccc",
  padding: "20px",
  borderRadius: "5px",
});

const initialFormState = {
  userName: "",
  courseId: "",
  depositedAmount: "",
  remarks: "",
};

const ReportEdit = () => {
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [cookies] = useCookies(["adminToken", "adminRoles"]);
  const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
  const { id } = useParams();

  const {
    formData,
    setFormData,
    // setErrors,
    // errors,
    handleChange,
    // validateForm,
  } = useFormValidation(initialFormState);

  useEffect(() => {
    setAdminToken(cookies.adminToken || "");
  }, [cookies]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/api/v1/account/report/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${adminToken}`,
          mode: "cors",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setFormData(data.report);
        setLoading(false);
      } else {
        const error = await response.json();
        setError(error.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("error fetching data:", error);
    }
  };

  const handleSelectCourse = (courseId) => {
    setFormData({ ...formData, courseId });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(
        `${BASE_URL}/api/v1/account/updateReport/${id}`,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${adminToken}`,
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const courseData = await response.json();
        setLoading(false);
        setError("");
        toast.success(courseData.message);
        setFormData(initialFormState);
      } else {
        const error = await response.json();
        setError(error.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  function Back() {
    nav(-1);
  }

  return (
    <>
      <ToastContainer position="top-center" autoClose="2000" />

      <Container maxWidth="xs">
        <Button
          onClick={() => Back()}
          variant="outlined"
          color="secondary"
          style={{ marginBottom: "0.4rem" }}
        >
          Back
        </Button>
        <AdminLoginForm onSubmit={handleSubmit}>
          <Typography variant="h2" gutterBottom>
            Edit Account Report
          </Typography>
          {error && (
            <Typography variant="subtitle2" color="error" gutterBottom>
              {error}!!
            </Typography>
          )}
          <Box width="100%" marginBottom="20px">
            <TextField
              label="User Email"
              variant="outlined"
              name="userName"
              fullWidth
              value={formData.userName}
              onChange={handleChange}
            />
            {/* {errors.examName && (
              <span style={{ color: "red" }}>{errors.userEmail}</span>
            )} */}
          </Box>
          <Box width="100%" marginBottom="20px">
            <CourseSelect
              selectedCourseId={formData.courseId}
              onSelectCourse={handleSelectCourse}
            />
            {/* {errors.courseId && (
              <span style={{ color: "red" }}>{errors.courseId}</span>
            )} */}
          </Box>
          <Box width="100%" marginBottom="20px">
            <TextField
              label="Deposited"
              variant="outlined"
              name="depositedAmount"
              fullWidth
              value={formData.depositedAmount}
              onChange={handleChange}
            />
            {/* {errors.details && (
              <span style={{ color: "red" }}>{errors.Deposited}</span>
            )} */}
          </Box>
          <Box width="100%" marginBottom="20px">
            <TextField
              label="Remarks"
              variant="outlined"
              name="remarks"
              fullWidth
              value={formData.remarks}
              onChange={handleChange}
            />
            {/* {errors.totalTime && (
              <span style={{ color: "red" }}>{errors.Remarks}</span>
            )} */}
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={loading}
          >
            Add Report
          </Button>
        </AdminLoginForm>
      </Container>
    </>
  );
};

export default ReportEdit;
