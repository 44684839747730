// components/TeachersSelect.js

import React, { useState, useEffect, useRef } from "react";
import { Select, MenuItem, CircularProgress, Box, Chip } from "@mui/material";
import BASE_URL from "../../../subcomponents/Config";
import { useCookies } from "react-cookie";

const TeacherSelect = ({ selectedTeacherId, onSelectTeacher }) => {
	const [teachers, setTeachers] = useState([]);
	const [selectedteacher, setSelectedTeacher] = useState([]);
	const [cookies] = useCookies(["adminToken"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	const [listLoading, setListLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const itemsPerPage = 8;
	const selectRef = useRef(null);

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	const handleChange = (event) => {
		setSelectedTeacher(event.target.value);
	};

	// Fetch courses function
	const fetchCourses = async () => {
		setListLoading(true);
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/teacher/teachers?page=${currentPage}&size=${itemsPerPage}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${adminToken}`,
					},
				}
			);

			if (response.ok) {
				const apiData = await response.json();
				setTeachers(apiData.teacher);
				setTotalPages(apiData.totalPages);
			} else {
				const error = await response.json();
				console.error("Error fetching courses:", error.message);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
		setListLoading(false);
	};

	useEffect(() => {
		if (currentPage <= totalPages) {
			fetchCourses();
		}
	}, [currentPage]);

	useEffect(() => {
		const handleScroll = (event) => {
			const bottom =
				event.target.scrollHeight - event.target.scrollTop ===
				event.target.clientHeight;
			if (bottom && !listLoading && currentPage < totalPages) {
				setCurrentPage((prevPage) => prevPage + 1);
			}
		};

		const node = selectRef.current?.children[2];
		if (node) {
			node.addEventListener("scroll", handleScroll);
		}

		return () => {
			if (node) {
				node.removeEventListener("scroll", handleScroll);
			}
		};
	}, [listLoading, currentPage, totalPages]);

	return (
		<Select
			value={selectedTeacherId || []}
			name="teacherIds"
			multiple
			displayEmpty
			renderValue={(selected) =>
				selected.length === 0 ? (
					<em>--Select Teachers--</em>
				) : (
					<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
						{selected &&
							selected.map((value, index) => (
								<Chip key={index} label={value} />
							))}
					</Box>
				)
			}
			onChange={onSelectTeacher}
			MenuProps={{
				PaperProps: {
					onScroll: (event) => {
						const bottom =
							event.target.scrollHeight - event.target.scrollTop ===
							event.target.clientHeight;
						if (bottom && !listLoading && currentPage < totalPages) {
							setCurrentPage((prevPage) => prevPage + 1);
						}
					},
					style: {
						maxHeight: 200,
						minWidth: 300, // adjust as needed
					},
				},
			}}
			ref={selectRef}
		>
			{teachers.map((teacher) => (
				<MenuItem key={teacher.id} value={teacher.id}>
					{teacher.firstName} {teacher.lastName}
				</MenuItem>
			))}
			{listLoading && (
				<MenuItem disabled>
					<CircularProgress size={24} />
				</MenuItem>
			)}
		</Select>
	);
};

export default TeacherSelect;
