import BASE_URL from "../../../subcomponents/Config";

export const fetchRoles = async () => {
	try {
		const response = await fetch(`${BASE_URL}/api/v1/auth/adminRoles`, {
			method: "GET",
		});
		if (response.ok) {
			const data = await response.json();
			return data.adminRoles;
		} else {
			throw new Error("Failed to fetch roles");
		}
	} catch (error) {
		console.error("Error fetching roles:", error);
		throw error;
	}
};
