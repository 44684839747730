import React, { useEffect, useState } from "react";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import "../../Styles/Components/Instructor.scss";
import { useParams } from "react-router-dom";

const Instructor = () => {
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [teacher, setTeacher] = useState([]);

	const [cookies] = useCookies(["userToken"]);
	const [token, setToken] = useState(cookies.userToken || "");

	useEffect(() => {
		setToken(cookies.userToken || "");
	}, [cookies]);

	useEffect(() => {
		Teacher();
	}, []);

	const Teacher = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/teacher/course/${id}`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
					mode: "cors",
				},
			});
			if (response.ok) {
				const getApi = await response.json();
				setTeacher(getApi.teacher);
				setLoading(false);
			} else {
				const error = await response.json();
				console.log(error);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data.", error);
		}
	};

	return (
		<div className="teacherContainer">
			{teacher &&
				teacher.map((data) => (
					<div className="teacher" key={data.id}>
						<div className="teacherImage">
							<img src={data.imageUrl} alt="" />
							<div className="teacherName">
								{data.firstName} {data.lastName}
							</div>
						</div>
					</div>
				))}
		</div>
	);
};

export default Instructor;
